import { CheckIcon } from 'Tokens/Icons/Status';
import { HStack, VStack, Box } from '@chakra-ui/react';
import { Typography } from 'Tokens';

export const StepComponent = ({
  title,
  children,
  titleColor = 'text.default',
  isEnd = false,
}: {
  title: string;
  children?: React.ReactNode;
  titleColor?: string;
  isEnd?: boolean;
}) => {
  return (
    <HStack minH={'108px'} width={'100%'} gap={'12px'}>
      <VStack height={'100%'} gap={0}>
        <Box
          border={'1px dashed'}
          width={'fit-content'}
          borderRadius={'50%'}
          borderColor={'border.hover'}
          px="5px"
        >
          <CheckIcon boxSize="18px" mt="5px" color={'border.hover'} />
        </Box>
        {!isEnd && <Box border={'1px dashed'} borderColor={'border.hover'} height={'100%'} />}
      </VStack>
      <VStack width={'100%'} alignSelf={'flex-start'} pos={'relative'} top={'4px'} pb={'45px'}>
        <Typography variant="h3" alignSelf={'flex-start'} color={titleColor}>
          {title}
        </Typography>
        {children}
      </VStack>
    </HStack>
  );
};
