import {
  InternalHyperlink,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
} from 'docx';
import { TextStyle, tableBorders } from '../reportThemes';
import { EsrsReportData, EsrsReportStandard } from '../../Report.types';
import { uniq } from 'lodash';

const header = [
  'Disclosure Requirement and related datapoint',
  'SFDR reference',
  'Pillar 3 reference',
  'Benchmark Regulation reference',
  'EU Climate Law reference',
  'Page and link to disclosure, if material',
];

export const SBM1Refs = [
  'undertakingisactiveinfossilfuelcoaloilandgassector',
  'undertakingisactiveinchemicalsproduction',
  'undertakingisactiveincontroversialweapons',
  'undertakingisactiveincultivationandproductionoftobacco',
];

export const appendixRows = [
  {
    'Disclosure Requirement and related datapoint':
      "ESRS 2 GOV-1 Board's gender diversity paragraph 21 (d) ",
    'SFDR reference': 'Indicator number 13 of Table #1 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': 'Commission Delegated Regulation (EU) 2020/1816 , Annex II',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'boardsgenderdiversityratio',
    standardRef: 'ESRS-2',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS 2 GOV-1 Percentage of board members who are independent paragraph 21 (e) ',
    'SFDR reference': '',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': 'Delegated Regulation (EU) 2020/1816, Annex II ',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'percentageofindependentboardmembers',
    standardRef: 'ESRS-2',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS 2 GOV-4 Statement on due diligence paragraph 30',
    'SFDR reference': 'Indicator number 10 Table #3 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference:
      'disclosureofmappingofinformationprovidedinsustainabilitystatementaboutduediligenceprocess',
    standardRef: 'ESRS-2',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS 2 SBM-1 Involvement in activities related to fossil fuel activities paragraph 40 (d) i ',
    'SFDR reference': 'Indicators number 4 Table #1 of Annex 1',
    'Pillar 3 reference':
      'Article 449a Regulation (EU) No 575/2013; Commission Implementing Regulation (EU) 2022/245313Ta ble 1: Qualitative information on Environmental risk and Table 2: Qualitative information on Social risk',
    'Benchmark Regulation reference': 'Delegated Regulation (EU) 2020/1816, Annex II ',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'undertakingisactiveinfossilfuelcoaloilandgassector',
    standardRef: 'ESRS-2',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS 2 SBM-1 Involvement in activities related to chemical production paragraph 40 (d) ii',
    'SFDR reference': 'Indicator number 9 Table #2 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': 'Delegated Regulation (EU) 2020/1816, Annex II ',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'undertakingisactiveinchemicalsproduction',
    standardRef: 'ESRS-2',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS 2 SBM-1 Involvement in activities related to controversial weapons paragraph 40 (d) iii',
    'SFDR reference': 'Indicator number 14 Table #1 of Annex 1 ',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference':
      'Delegated Regulation (EU) 2020/181814 , Article 12(1) Delegated Regulation (EU) 2020/1816, Annex II',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'undertakingisactiveincontroversialweapons',
    standardRef: 'ESRS-2',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS 2 SBM-1 Involvement in activities related to cultivation and production of tobacco paragraph 40 (d) iv ',
    'SFDR reference': '',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference':
      'Delegated Regulation (EU) 2020/1818, Article 12(1) Delegated Regulation (EU) 2020/1816, Annex II',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'undertakingisactiveincultivationandproductionoftobacco',
    standardRef: 'ESRS-2',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E1-1 Transition plan to reach climate neutrality by 2050 paragraph 14',
    'SFDR reference': '',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': 'Regulation (EU) 2021/1119, Article 2(1) ',
    'Page and link to disclosure, if material': '',
    reference: 'DisclosureOfTransitionPlanundefinedForClimateChangeMitigation-E1-1',
    standardRef: 'ESRS-E1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E1-1 Brand Units excluded from Paris-aligned Benchmarks paragraph 16 (g)',
    'SFDR reference': '',
    'Pillar 3 reference':
      'Article 449a Regulation (EU) No 575/2013; Commission Implementing Regulation (EU) 2022/2453 Template 1: Banking bookClimate Change transition risk: Credit quality of exposures by sector, emissions and residual maturity',
    'Benchmark Regulation reference':
      'Delegated Regulation (EU) 2020/1818, Article12.1 (d) to (g), and Article 12.2 ',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'UndertakingIsExcludedFromEUParis-alignedBenchmarks-E1-1',
    standardRef: 'ESRS-E1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E1-4 GHG emission reduction targets paragraph 34 ',
    'SFDR reference': 'Indicator number 4 Table #2 of Annex 1',
    'Pillar 3 reference':
      'Article 449a Regulation (EU) No 575/2013; Commission Implementing Regulation (EU) 2022/2453 Template 3: Banking book – Climate change transition risk: alignment metrics',
    'Benchmark Regulation reference': 'Delegated Regulation (EU) 2020/1818, Article 6 ',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    disclosureRequirementRef: 'E1-4',
    standardRef: 'ESRS-E1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E1-5 Energy consumption from fossil sources disaggregated by sources (only high climate impact sectors) paragraph 38',
    'SFDR reference': 'Indicator number 5 Table #1 and Indicator n. 5 Table #2 of Annex 1 ',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'energyConsumptionFossil',
    standardRef: 'ESRS-E1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E1-5 Energy consumption and mix paragraph 37',
    'SFDR reference': 'Indicator number 5 Table #1 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'totalEnergyConsumption',
    standardRef: 'ESRS-E1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E1-5 Energy intensity associated with activities in high climate impact sectors paragraphs 40 to 43',
    'SFDR reference': 'Indicator number 6 Table #1 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'energyIntensityHighImpact',
    standardRef: 'ESRS-E1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E1-6 Gross Scope 1, 2, 3 and Total GHG emissions paragraph 44',
    'SFDR reference': 'Indicators number 1 and 2 Table #1 of Annex 1 ',
    'Pillar 3 reference':
      'Article 449a; Regulation (EU) No 575/2013; Commission Implementing Regulation (EU) 2022/2453 Template 1: Banking book – Climate change transition risk: Credit quality of exposures by sector, emissions and residual maturity',
    'Benchmark Regulation reference':
      'Delegated Regulation (EU) 2020/1818, Article 5(1), 6 and 8(1) ',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'grossScopeGHG1',
    standardRef: 'ESRS-E1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E1-6 Gross GHG emissions intensity paragraphs 53 to 55 ',
    'SFDR reference': 'Indicators number 3 Table #1 of Annex 1',
    'Pillar 3 reference':
      'Article 449a Regulation (EU) No 575/2013; Commission Implementing Regulation (EU) 2022/2453 Template 3: Banking book – Climate change transition risk: alignment metrics',
    'Benchmark Regulation reference': 'Delegated Regulation (EU) 2020/1818, Article 8(1) ',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: ['LBGHGIntensityPerRevenue', 'MBGHGIntensityPerRevenue'],
    standardRef: 'ESRS-E1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E1-7 GHG removals and carbon credits paragraph 56 ',
    'SFDR reference': '',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': 'Regulation (EU) 2021/1119, Article 2(1)',
    'Page and link to disclosure, if material': '',
    disclosureRequirementRef: 'E1-7',
    standardRef: 'ESRS-E1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E1-9 Exposure of the benchmark portfolio to climate-related physical risks paragraph 66 ',
    'SFDR reference': '',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference':
      'Delegated Regulation (EU) 2020/1818, Annex II Delegated Regulation (EU) 2020/1816, Annex II ',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: [
      'esrs:AssetsAtMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions',
      'parentPercentagePhysicalRisk',
      'PercentageAssetsRiskAddressedActions',
      'DisclosureLocationSignificantAssets',
      'ParentNetRevenue',
      'parentNetRevenuePercentage',
    ],
    standardRef: 'ESRS-E1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E1-9 Disaggregation of monetary amounts by acute and chronic physical risk paragraph 66 (a) ESRS E1-9 Location of significant assets at material physical risk paragraph 66 (c).',
    'SFDR reference': '',
    'Pillar 3 reference':
      'Article 449a Regulation (EU) No 575/ 2013; Commission Implementing Regulation (EU) 2022/ 2453 paragraphs 46 and 47; Template 5: Banking book - Climate change physical risk: Exposures subject to physical risk. ',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: [
      'esrs:AssetsAtMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions',
      'DisclosureLocationSignificantAssets',
    ],
    standardRef: 'ESRS-E1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E1-9 Breakdown of the carrying value of its real estate assets by energyefficiency classes paragraph 67 (c).',
    'SFDR reference': '',
    'Pillar 3 reference':
      'Article 449a Regulation (EU) No 575/ 2013; Commission Implementing Regulation (EU) 2022/ 2453 paragraph 34; Template 2:Banking book -Climate change transition risk: Loans collateralised by immovable property - Energy efficiency of the collateral ',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'carryingAmountRealEstate',
    standardRef: 'ESRS-E1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E1-9 Degree of exposure of the portfolio to climate-related opportunities paragraph 69',
    'SFDR reference': '',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': 'Delegated Regulation (EU) 2020/1818, Annex II ',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: [
      'expectedSavingsMitigationActions',
      'expectedSavingsAdaptationActions',
      'potentialSizeLowCarbonProductAccess',
      'expectedChangesNetRevenueAdaptation',
    ],
    standardRef: 'ESRS-E1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E2-4 Amount of each pollutant listed in Annex II of the EPRTR Regulation (European Pollutant Release and Transfer Register) emitted to air, water and soil, paragraph 28 ',
    'SFDR reference':
      'Indicator number 8 Table #1 of Annex 1 Indicator number 2 Table #2 of Annex 1 Indicator number 1 Table #2 of Annex 1 Indicator number 3 Table #2 of Annex 1  ',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: ['emissionAirPollutants', 'emissionsToWater', 'emissionsToSoil'],
    standardRef: 'ESRS-E2',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E3-1 Water and marine resources paragraph 9 ',
    'SFDR reference': 'Indicator number 7 Table #2 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    disclosureRequirementRef: 'E3-1',
    standardRef: 'ESRS-E3',
  },
  {
    'Disclosure Requirement and related datapoint': 'ESRS E3-1 Dedicated policy paragraph 13 ',
    'SFDR reference': 'Indicator number 8 Table 2 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference:
      'esrs:AtLeastOneOfUndertakingSitesIsLocatedInAreaOfHighwaterStressAndIsNotCoveredByPolicy ',
    standardRef: 'ESRS-E3',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E3-1 Sustainable oceans and seas paragraph 14',
    'SFDR reference': 'Indicator number 12 Table #2 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'PoliciesOrPracticesRelatedToSustainableOceansAndSeasHaveBeenAdopted-E3-1',
    standardRef: 'ESRS-E3',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E3-4 Total water recycled and reused paragraph 28 (c) ',
    'SFDR reference': 'Indicator number 6.2 Table #2 of Annex 1 ',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'totalWaterRecycled',
    standardRef: 'ESRS-E3',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E3-4 Total water consumption in m3 per net revenue on own operations paragraph 29',
    'SFDR reference': 'Indicator number 6.1 Table #2 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'waterIntensity',
    standardRef: 'ESRS-E3',
  },
  {
    'Disclosure Requirement and related datapoint': 'ESRS 2- SBM-3 - E4 paragraph 16 (a) i',
    'SFDR reference': 'Indicator number 7 Table #1 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference:
      'ActivitiesRelatedToSitesLocatedInOrNearBiodiversity-sensitiveAreasNegativelyAffectTheseAreasWhereConclusionsOrNecessaryMitigationMeasuresHaveNotBeenImplementedOrAreOngoing-SBM-3',
    standardRef: 'ESRS-E4',
  },
  {
    'Disclosure Requirement and related datapoint': 'ESRS 2- SBM-3 - E4 paragraph 16 (b)',
    'SFDR reference': 'Indicator number 10 Table #2 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference:
      'MaterialNegativeImpactsWithRegardsToLandDegradation,DesertificationOrSoilSealingHaveBeenIdentified-SBM-3',
    standardRef: 'ESRS-E4',
  },
  {
    'Disclosure Requirement and related datapoint': 'ESRS 2- SBM-3 - E4 paragraph 16 (c)',
    'SFDR reference': 'Indicator number 14 Table #2 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'OwnOperationsAffectThreatenedSpecies-SBM-3',
    standardRef: 'ESRS-E4',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E4-2 Sustainable land / agriculture practices or policies paragraph 24 (b)',
    'SFDR reference': 'Indicator number 11 Table #2 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'SustainableLandOrAgriculturePracticesOrPoliciesHaveBeenAdopted-E4-2',
    standardRef: 'ESRS-E4',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E4-2 Sustainable oceans / seas practices or policies paragraph 24 (c) ',
    'SFDR reference': 'Indicator number 12 Table #2 of Annex 1 ',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'SustainableOceansOrSeasPracticesOrPoliciesHaveBeenAdopted-E4-2 ',
    standardRef: 'ESRS-E4',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E4-2 Policies to address deforestation paragraph 24 (d) ',
    'SFDR reference': 'Indicator number 15 Table #2 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'PoliciesToAddressDeforestationHaveBeenAdopted-E4-2',
    standardRef: 'ESRS-E4',
  },
  {
    'Disclosure Requirement and related datapoint': 'ESRS E5-5 Non-recycled waste paragraph 37 (d)',
    'SFDR reference': 'Indicator number 13 Table #2 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'AmountNonRecycledWaste',
    standardRef: 'ESRS-E5',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS E5-5 Hazardous waste and radioactive waste paragraph 39',
    'SFDR reference': 'Indicator number 9 Table #1 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: ['AmountRadioactiveWasteGenerated', 'AmountHazardousWaste'],
    standardRef: 'ESRS-E5',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS 2- SBM3 - S1 Risk of incidents of forced labour paragraph 14 (f)',
    'SFDR reference': 'Indicator number 13 Table #3 of Annex I',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: [
      'InformationAboutTypeOfOperationsAtSignificantRiskOfIncidentsOfForcedLabourOrCompulsoryLabour-SBM-3',
      'InformationAboutCountriesOrGeographicAreasWithOperationsConsideredAtSignificantRiskOfIncidentsOfForcedLabourOrCompulsoryLabour-SBM-3',
    ],
    standardRef: 'ESRS-S1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS 2- SBM3 - S1 Risk of incidents of child labour paragraph 14 (g)',
    'SFDR reference': 'Indicator number 12 Table #3 of Annex I',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: [
      'InformationAboutTypeOfOperationsAtSignificantRiskOfIncidentsOfChildLabour-SBM-3',
      'InformationAboutCountriesOrGeographicAreasWithOperationsConsideredAtSignificantRiskOfIncidentsOfChildLabour-SBM-3',
    ],
    standardRef: 'ESRS-S1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S1-1 Human rights policy commitments paragraph 20',
    'SFDR reference': 'Indicator number 9 Table #3 and Indicator number 11 Table #1 of Annex I',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': 'Delegated Regulation (EU) 2020/1816, Annex II',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'DescriptionOfRelevantHumanRightsPolicyCommitmentsRelevantToOwnWorkforce-S1-1',
    standardRef: 'ESRS-S1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S1-1 Due diligence policies on issues addressed by the fundamental International Labor Organisation Conventions 1 to 8, paragraph 21',
    'SFDR reference': '',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': 'Delegated Regulation (EU) 2020/1816, Annex II',
    'Page and link to disclosure, if material': '',
    reference:
      'DisclosureOfWhetherAndHowPoliciesAreAlignedWithRelevantInternationallyRecognisedInstruments-S1-1',
    standardRef: 'ESRS-S1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S1-1 processes and measures for preventing trafficking in human beings paragraph 22',
    'SFDR reference': 'Indicator number 11 Table #3 of Annex I',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'esrs:PoliciesExplicitlyAddressTraffickingInHumanBeings',
    standardRef: 'ESRS-S1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S1-1 workplace accident prevention policy or management system paragraph 23',
    'SFDR reference': 'Indicator number 1 Table #3 of Annex I',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'WorkplaceAccidentPreventionPolicyOrManagementSystemIsInPlace-S1-1',
    standardRef: 'ESRS-S1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S1-3 grievance/complaints handling mechanisms paragraph 32 (c)',
    'SFDR reference': 'Indicator number 5 Table #3 of Annex I',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'GrievanceOrComplaintsHandlingMechanismsRelatedToEmployeeMattersExist-S1-3',
    standardRef: 'ESRS-S1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S1-14 Number of fatalities and number and rate of work-related accidents paragraph 88 (b) and (c)',
    'SFDR reference': 'Indicator number 2 Table #3 of Annex I',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': 'Delegated Regulation (EU) 2020/1816, Annex II ',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: ['fatalitiesTotal', 'fatalitiesBreakdown', 'numberInjuries'],
    standardRef: 'ESRS-S1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S1-14 Number of days lost to injuries, accidents, fatalities or illness paragraph 88 (e)',
    'SFDR reference': 'Indicator number 3 Table #3 of Annex I',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'daysLost',
    standardRef: 'ESRS-S1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S1-16 Unadjusted gender pay gap paragraph 97 (a)',
    'SFDR reference': 'Indicator number 12 Table #1 of Annex I',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': 'Delegated Regulation (EU) 2020/1816, Annex II',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'genderPayGap',
    standardRef: 'ESRS-S1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S1-16 Excessive CEO pay ratio paragraph 97 (b)',
    'SFDR reference': 'Indicator number 8 Table #3 of Annex I',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'totalRemunerationRatio',
    standardRef: 'ESRS-S1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S1-17 Incidents of discrimination paragraph 103 (a)',
    'SFDR reference': 'Indicator number 7 Table #3 of Annex I',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'numberofincidentsofdiscrimination',
    standardRef: 'ESRS-S1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S1-17 Non-respect of UNGPs on Business and Human Rights and OECD Guidelines paragraph 104 (a)',
    'SFDR reference': 'Indicator number 10 Table #1 and Indicator n. 14 Table #3 of Annex I',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference':
      'Delegated Regulation (EU) 2020/1816, Annex II Delegated Regulation (EU) 2020/1818 Art 12 (1) ',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: ['noIncidents', 'severeHRIncidentsWorkforce'],
    standardRef: 'ESRS-S1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS 2- SBM3 – S2 Significant risk of child labour or forced labour in the value chain paragraph 11 (b)',
    'SFDR reference': 'Indicators number 12 and n. 13 Table #3 of Annex I',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference:
      'DisclosureOfGeographiesOrCommoditiesForWhichThereIsSignificantRiskOfChildLabour,OrOfForcedOrCompulsoryLabour,AmongWorkersInUndertaking’sValueChain-SBM-3',
    standardRef: 'ESRS-S2',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S2-1 Human rights policy commitments paragraph 17',
    'SFDR reference': 'Indicator number 9 Table #3 and Indicator n. 11 Table #1 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'DescriptionOfRelevantHumanRightsPolicyCommitmentsRelevantToValueChainWorkers-S2-1',
    standardRef: 'ESRS-S2',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S2-1 Policies related to value chain workers paragraph 18 ',
    'SFDR reference': 'Indicator number 11 and n. 4 Table #3 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: [
      'esrs:PoliciesExplicitlyAddressTraffickingInHumanBeingsS2',
      'suppliercodeofconduct',
    ],
    standardRef: 'ESRS-S2',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S2-1 Non-respect of UNGPs on Business and Human Rights principles and OECD guidelines paragraph 19',
    'SFDR reference': 'Indicator number 10 Table #1 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference':
      'Delegated Regulation (EU) 2020/1816, Annex II Delegated Regulation (EU) 2020/1818, Art 12 (1)',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: [
      'esrs:DisclosureOfExtentToWhichCasesOfNonrespectOfUnGuidingPrinciplesOnBusinessAndHumanRightsIloDeclarationOnFundamentalPrinciplesAndRightsAtWorkOrOecdGuidelinesForMultinationalEnterprisesThatInvolveValueChainWorkersHaveBeenReportedInUpstreamAndDownstreamValueChainExplanatoryS2',
      'casesofnonrespectofunguidingprinciples-s2-1',
    ],
    standardRef: 'ESRS-S2',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S2-1 Due diligence policies on issues addressed by the fundamental International Labor Organisation Conventions 1 to 8, paragraph 19',
    'SFDR reference': '',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': 'Delegated Regulation (EU) 2020/1816, Annex II',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'esrs:PoliciesAreAlignedWithRelevantInternationallyRecognisedInstrumentsS2 ',
    standardRef: 'ESRS-S2',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S2-4 Human rights issues and incidents connected to its upstream and downstream value chain paragraph 36',
    'SFDR reference': 'Indicator number 14 Table #3 of Annex 1 ',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference:
      'esrs:SevereHumanRightsIssuesAndIncidentsConnectedToUpstreamAndDownstreamValueChainNOAffectedCommunitiesNOConsumersAndEndusersHaveBeenReportedValueChain',
    standardRef: 'ESRS-S2',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S3-1 Human rights policy commitments paragraph 16',
    'SFDR reference':
      'Indicator number 9 Table #3 of Annex 1 and Indicator number 11 Table #1 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'descriptionofrelevanthumanrightspolicyaffectedcommunities-S3-1',
    standardRef: 'ESRS-S3',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S3-1 non-respect of UNGPs on Business and Human Rights, ILO principles or OECD guidelines paragraph 17',
    'SFDR reference': 'Indicator number 10 Table #1 Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference':
      'Delegated Regulation (EU) 2020/1816, Annex II Delegated Regulation (EU) 2020/1818, Art 12 (1)',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: [
      'esrs:DisclosureOfExtentToWhichCasesOfNonrespectOfUnGuidingPrinciplesOnBusinessAndHumanRightsIloDeclarationOnFundamentalPrinciplesAndRightsAtWorkOrOecdGuidelinesForMultinationalEnterprisesThatInvolveAffectedCommunitiesHaveBeenReportedInOwnOperationsOrInUpstreamAndDownstreamValueChainExplanatoryS3',
      'casesofnonrespectofunguidingprinciples-s2-1',
    ],
    standardRef: 'ESRS-S3',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S3-4 Human rights issues and incidents paragraph 36',
    'SFDR reference': 'Indicator number 14 Table #3 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference:
      'esrs:SevereHumanRightsIssuesAndIncidentsConnectedToUpstreamAndDownstreamValueChainNOAffectedCommunitiesNOConsumersAndEndusersHaveBeenReported',
    standardRef: 'ESRS-S3',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S4-1 Policies related to consumers and end-users paragraph 16',
    'SFDR reference': 'Indicator number 9 Table #3 and Indicator number 11 Table #1 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'esrs:DescriptionOfRelevantHumanRightsPolicyCommitmentsAbstract',
    standardRef: 'ESRS-S4',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S4-1 Non-respect of UNGPs on Business and Human Rights and OECD guidelines paragraph 17',
    'SFDR reference': 'Indicator number 10 Table #1 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference':
      'Delegated Regulation (EU) 2020/1816, Annex II Delegated Regulation (EU) 2020/1818, Art 12 (1)',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: [
      'DisclosureOfUNGuidingPrinciplesOnBusinessAndHumanRights',
      'esrs:DisclosureOfNatureOfCasesOfNonrespectOfUnGuidingPrinciplesOnBusinessAndHumanRightsIloDeclarationOnFundamentalPrinciplesAndRightsAtWorkOrOecdGuidelinesForMultinationalEnterprisesExplanatory',
    ],
    standardRef: 'ESRS-S4',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS S4-4 Human rights issues and incidents paragraph 35',
    'SFDR reference': 'ndicator number 14 Table #3 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference:
      'esrs:DisclosureOfSevereHumanRightsIssuesAndIncidentsConnectedToUpstreamAndDownstreamValueChainNOAffectedCommunitiesNOConsumersAndEndusersThatHaveBeenReportedExplanatoryConsumers',
    standardRef: 'ESRS-S4',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS G1-1 United Nations Convention against Corruption paragraph 10 (b) ',
    'SFDR reference': 'Indicator number 15 Table #3 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'parentNotAdoptedAnti-corruptionG1-1',
    standardRef: 'ESRS-G1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS G1-1 Protection of whistle- blowers paragraph 10 (d)',
    'SFDR reference': 'Indicator number 6 Table #3 of Annex 1',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'NoPoliciesOnProtectionOfWhistle-blowersAreInPlace-G1-1',
    standardRef: 'ESRS-G1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS G1-4 Fines for violation of anti-corruption and anti-bribery laws paragraph 24 (a)',
    'SFDR reference': 'Indicator number 17 Table #3 of Annex 1 ',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': 'Delegated Regulation (EU) 2020/1816, Annex II) ',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    references: ['numberConvictions', 'amountFines'],
    standardRef: 'ESRS-G1',
  },
  {
    'Disclosure Requirement and related datapoint':
      'ESRS G1-4 Standards of anticorruption and anti- bribery paragraph 24 (b)',
    'SFDR reference': 'Indicator number 16 Table #3 of Annex 1 ',
    'Pillar 3 reference': '',
    'Benchmark Regulation reference': '',
    'EU Climate Law reference': '',
    'Page and link to disclosure, if material': '',
    reference: 'actionsCorruptionsBribery',
    standardRef: 'ESRS-G1',
  },
];

export const appedixStandardRefs = uniq(appendixRows.map((row) => row.standardRef));
export const appedixMetricRefs = uniq(
  appendixRows.flatMap((row) => row.reference || row.references)
).filter((row): row is string => !!row);

export const getAppendixMaterialityStatus = (
  materialStandards:
    | {
        standardRef: string;
        isMaterial?: boolean | null | undefined;
        materialMetrics: {
          metricRef: string;
          isMaterial?: boolean | null | undefined;
          metric: {
            disclosureRequirementRef: string;
          };
        }[];
      }[]
    | undefined,
  row: {
    reference?: string;
    references?: string[];
    disclosureRequirementRef?: string;
    standardRef: string;
  },
  isOptedOutSMB1Metric: boolean
) => {
  const standard = materialStandards?.find((s) => s.standardRef === row.standardRef);
  const materialMetrics = standard?.materialMetrics.filter((m) =>
    row?.reference
      ? m.metricRef === row.reference
      : row?.references
        ? row.references?.includes(m.metricRef)
        : m.metric.disclosureRequirementRef === row.disclosureRequirementRef
  );
  const isMaterial = materialMetrics?.some((m) => m.isMaterial);

  if (standard?.isMaterial === null || standard?.isMaterial === undefined) return '';
  if (standard?.isMaterial === false || isOptedOutSMB1Metric) return 'NOT MATERIAL';
  if (!materialMetrics?.length) return '';
  if (isMaterial) return 'MATERIAL';
  return 'NOT MATERIAL';
};

const createRows = (
  materialStandards: EsrsReportData['materialStandards'],
  reportStandards: EsrsReportStandard[]
) => {
  return appendixRows.map((row) => {
    const reportMetrics = reportStandards
      .find((s) => s.reference === row.standardRef)
      ?.disclosureRequirements.flatMap((dr) => dr.metricsIncludingOptedOut);
    const hasOptedOut = reportMetrics?.find(
      (m) => m.metric.metricRef === row.reference
    )?.hasOptedOut;
    const isOptedOutSMB1Metric =
      !!row?.reference && SBM1Refs.includes(row?.reference ?? '') ? hasOptedOut === true : false;

    const materialityStatus = getAppendixMaterialityStatus(
      materialStandards,
      row,
      isOptedOutSMB1Metric
    );

    return new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [
            new Paragraph({
              text: row['Disclosure Requirement and related datapoint'],
              style: TextStyle.body,
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [
            new Paragraph({
              text: row['SFDR reference'],
              style: TextStyle.body,
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [
            new Paragraph({
              text: row['Pillar 3 reference'],
              style: TextStyle.body,
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [
            new Paragraph({
              text: row['Benchmark Regulation reference'],
              style: TextStyle.body,
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [
            new Paragraph({
              text: row['EU Climate Law reference'],
              style: TextStyle.body,
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [
            new Paragraph({
              children: [
                materialityStatus === 'MATERIAL'
                  ? []
                  : new TextRun({
                      text: materialityStatus,
                    }),
              ],
              style: materialityStatus === 'MATERIAL' ? TextStyle.bodyItalic : TextStyle.body,
            }),
          ],
        }),
      ],
    });
  });
};

const createHeaderRow = () =>
  new TableRow({
    tableHeader: true,
    children: header.map(
      (header) =>
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          width: { size: '16.6%', type: WidthType.PERCENTAGE },
          children: [new Paragraph({ text: header, style: TextStyle.tableTitle })],
        })
    ),
  });

export const createOtherEULegislationsTable = (
  materialStandards: EsrsReportData['materialStandards'],
  reportStandards: EsrsReportStandard[]
) => {
  const tableContentRows = createRows(materialStandards, reportStandards);
  const headerRow = createHeaderRow();

  return [
    new Paragraph({
      text: 'List of data points that derive from other EU legislations, with information on their location in the Sustainability Statement',
      style: TextStyle.h3,
      spacing: {
        after: 150,
      },
    }),
    new Table({
      columnWidths: [1666, 1666, 1666, 1666, 1666, 1666],
      margins: { top: 60, bottom: 60, right: 60, left: 60 },
      width: { size: '100%', type: WidthType.PERCENTAGE },
      borders: tableBorders,
      rows: [headerRow, ...tableContentRows],
    }),
  ];
};
