import { AutoResizeTextarea } from 'Atoms';
import { useRef } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import { MdrmAnswerType, MdrmType, OnMdrmAnswerChangeType } from '../../../MDRM';

export const MdrmTextInput = ({
  placeholder,
  mdrmId,
  answer,
  onMdrmAnswerChange,
  onFocus,
  isSelected,
  setIsHovered,
}: {
  placeholder?: string;
  mdrmId: MdrmType;
  answer?: MdrmAnswerType;
  onMdrmAnswerChange: OnMdrmAnswerChangeType;
  onFocus: () => void;
  isSelected: boolean;
  setIsHovered: (isHovered: boolean) => void;
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleFocus = () => {
    const textarea = textareaRef.current;
    textarea?.setSelectionRange(textarea.value.length, textarea.value.length);
    onFocus();
  };

  const debounceSave = useDebounceCallback((newValue) => {
    onMdrmAnswerChange(newValue ?? '', mdrmId, answer?.ownerId);
  }, 1000);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value === '' ? null : event.target.value;
    debounceSave(newValue);
  };

  return (
    <AutoResizeTextarea
      key={answer?.id}
      ref={textareaRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      variant="ghost"
      borderRadius="6px"
      padding="0px"
      minH="0px"
      lineHeight="20px"
      cursor={isSelected ? 'text' : 'pointer'}
      placeholder={placeholder}
      defaultValue={answer?.value ?? ''}
      opacity={answer?.hasOptedOut ? 0.4 : 1}
      onFocus={handleFocus}
      onChange={handleChange}
      onClick={(e) => e.stopPropagation()}
      _focus={{ bg: 'none' }}
      _hover={{ bg: 'none' }}
    />
  );
};
