import { Box, Collapse, HStack, VStack, Stack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { getQuestionId, QuestionLevel } from 'utils/scores/questions';
import { tnoop, useTranslation } from 'utils/translation';
import { AnswerUpdate, AttachmentBox, ResolvedQuestion, ShortUser } from '../../models/types';
import { CommentsButton } from './Comments';
import { LearnMoreButton } from './LearnMoreButton';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import { CurrentQuestion, CurrentQuestionContext } from 'context/CurrentQuestionContext';
import { useEffect, useRef } from 'react';
import { isMultipleCriteria } from 'utils/scores/multipleCriteriaQuestion';
import { CriteriaTable, SubquestionTabs } from './MultipleCriteriaQuestion';
import { useLatest } from 'react-use';
import QuestionInput from './QuestionInput';
import { useSaveAnswerMutation, useSaveAnswerNoteMutation } from './Screening.hooks';
import { useUserData } from '@nhost/react';
import { InputCard, InputCardHeaderProps } from 'Molecules/InputCard';
import { StateStatus } from 'Molecules/CardStatus';
import { InputCardWarningProps } from 'Molecules/InputCard/InputCardWarnings';
import { InputCardDocumentationProps } from 'Molecules/InputCard/InputCardDocumentation';
import { ActivityAssessmentAnswersDocument_, ActivityAssessmentDocument_ } from 'models';
import { useParams } from 'react-router-dom';
import { Alert, Button } from 'Atoms';
import { QuestionIsNewTag } from 'containers/Assessments/pieces/IsNewTag';

export const ObjectiveStatus = {
  SUBSTANTIAL_CONTRIBUTION: tnoop('assessment:objectiveStatus.SC'),
  DO_NO_SIGNIFICANT_HARM: tnoop('assessment:objectiveStatus.DNSH'),
  DOES_HARM: tnoop('assessment:objectiveStatus.DH'),
};

export function ContributionLabel({ level }: { level: QuestionLevel }) {
  const { t } = useTranslation(['assessment']);

  if (level === QuestionLevel.General_ || level === QuestionLevel.SocialSafeguards_) {
    return null;
  }

  return (
    <Stack pt="8px">
      <Typography variant="body">
        {t('assessment:contributesTo')}: {t(ObjectiveStatus[level])}
      </Typography>
    </Stack>
  );
}

export const TooltipContentOverride = ({ question }: { question: ResolvedQuestion }) => {
  const { t } = useTranslation('common');
  return (
    <VStack spacing={2} color="white" textAlign="left" alignItems="flex-start">
      <Typography color="white" textAlign="left" variant="bodyStrong">
        {t('common:questions.locked')}
      </Typography>
      <Typography color="white" textAlign="left" variant="bodyStrong" fontStyle="italic">
        {question?.dependencyDescription
          ? question.dependencyDescription
          : t('common:questions.lockedReason')}
      </Typography>
    </VStack>
  );
};

export function QuestionContextProvider({
  question,
  children,
  warning,
  documentation,
  actions = true,
  showNumber = true,
  showVersionAlert = true,
  learnMoreIconButton = false,
  openUpdatedQuestionModal = () => {},
  isLocked = false,
}: React.PropsWithChildren<{
  question: CurrentQuestion;
  actions?: boolean;
  warning?: InputCardWarningProps;
  documentation?: InputCardDocumentationProps;
  showNumber?: boolean;
  showVersionAlert?: boolean;
  learnMoreIconButton?: boolean;
  openUpdatedQuestionModal?: (q: ResolvedQuestion) => void;
  isLocked?: boolean;
}>) {
  const { isEditable } = question;

  const getQuestionStatus = () => {
    if (!question.isEditable) {
      return StateStatus.locked;
    }

    if (question.isAligned) {
      if (question?.level === QuestionLevel.SocialSafeguards_) return StateStatus.MSSCompliant;
      return StateStatus.compliant;
    }
    if (question.isAnswered) {
      if (question.isAligned === false) {
        if (question?.level === QuestionLevel.SocialSafeguards_) return StateStatus.MSSNonCompliant;
        return StateStatus.nonCompliant;
      }
    }
    return StateStatus.todo;
  };

  const headerActions: React.ReactNode | React.ReactNode[] | undefined = actions ? (
    <HStack>
      ,{showVersionAlert && <QuestionIsNewTag question={question} />}
      <CommentsButton width="100%" isLocked={isLocked} />,
      <LearnMoreButton
        question={question}
        width="100%"
        buttonType={learnMoreIconButton ? 'icon' : 'text'}
      />
    </HStack>
  ) : undefined;

  const header: InputCardHeaderProps = {
    title: question.number,
    status: getQuestionStatus(),
    extra: <ContributionLabel level={question.level} />,
    actions: headerActions,
  };

  const updatedQuestionAlert: React.ReactNode | null =
    !showVersionAlert ||
    !question.olderRevisions?.length ||
    !!question.answer?.data ||
    isLocked ? null : (
      <Alert status="warning" title="Question updated" closable={false}>
        <Button variant="ghost" size="sm" onClick={() => openUpdatedQuestionModal(question)}>
          Compare versions
        </Button>
      </Alert>
    );

  return (
    <CurrentQuestionContext.Provider value={question}>
      <InputCard
        variant={!isEditable ? 'disabled' : 'default'}
        header={showNumber ? header : undefined}
        warning={warning}
        documentation={documentation}
        tooltipContentOverride={
          question?.isEditable ? undefined : <TooltipContentOverride question={question} />
        }
        {...{ maxWidth: '720px' }}
      >
        <VStack spacing="24px" width="100%" alignItems="stretch">
          {updatedQuestionAlert}
          <Typography variant="bodyLarge">{question.title}</Typography>
          {children}
        </VStack>
      </InputCard>
    </CurrentQuestionContext.Provider>
  );
}

export function MultipleCriteriaQuestion({
  question,
  isLocked,
}: {
  question: CurrentQuestion;
  isLocked: boolean;
}) {
  return (
    <VStack width="100%" alignItems="stretch" id={getQuestionId(question)} mt="lg" spacing="lg">
      <QuestionContextProvider question={question} showNumber={false}>
        <CriteriaTable />
        <SubquestionTabs isLocked={isLocked} />
      </QuestionContextProvider>
    </VStack>
  );
}

export type QuestionProps = {
  question: ResolvedQuestion;
  index: string | number;
  totalQuestions?: number;
  activityAssessmentId: string;
  openAttachmentDrawer: (question: Pick<ResolvedQuestion, 'id' | 'answer'>) => void;
  openUpdatedQuestionModal?: (q: ResolvedQuestion) => void;
  showVersionAlert?: boolean;
  showNumber?: boolean;
  readOnly?: boolean;
  inModal?: boolean;
  learnMoreIconButton?: boolean;
  isReusable?: boolean;
  questionsToCopyFrom?: Array<ResolvedQuestion>;
  copyNotes?: (oldQuestion: ResolvedQuestion) => void;
  copyAttachments?: (oldQuestion: ResolvedQuestion) => void;
  isLocked: boolean;
};

export function QuestionRow({
  question,
  index,
  totalQuestions,
  activityAssessmentId,
  openAttachmentDrawer,
  openUpdatedQuestionModal,
  showVersionAlert = true,
  showNumber = true,
  readOnly = false,
  inModal = false,
  learnMoreIconButton = false,
  isReusable,
  questionsToCopyFrom = [],
  copyNotes,
  copyAttachments,
  isLocked,
}: QuestionProps) {
  const toast = useToast();
  const user: ShortUser | null = useUserData();
  const { companyId } = useCurrentCompanyId();
  const { t } = useTranslation(['question', 'common']);
  const questionRef = useLatest(question);
  const [saveAnswer] = useSaveAnswerMutation();
  const saveNote = useSaveAnswerNoteMutation();
  const ref = useRef<HTMLDivElement>(null);
  const { questionId } = useParams();
  useEffect(() => {
    if (questionId === questionRef.current.uniqueId) {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [questionId]);

  const isMulCriteria = isMultipleCriteria(question);

  const onNoteChanged = (note: string | undefined | null) => {
    if (readOnly) return Promise.resolve();

    return saveNote(
      note ?? '',
      activityAssessmentId,
      question.id,
      question.answer?.noteHistory?.id
    );
  };

  const onAnswerChange = (newAnswer: AnswerUpdate) => {
    if (readOnly) return Promise.resolve();
    const oldAnswer = question.answer;
    const dataChanged = newAnswer.data !== undefined ? newAnswer.data != oldAnswer?.data : false;
    const answeredById =
      dataChanged || !oldAnswer ? user?.id : (oldAnswer?.answeredBy?.id ?? user?.id);
    mixpanel.track(
      !question.answer?.data
        ? TRACKING_EVENTS.QUESTION.ANSWER.CREATE
        : TRACKING_EVENTS.QUESTION.ANSWER.UPDATE,
      { companyId, question: question.uniqueId, questionSetRef: question.questionSetRef }
    );
    return saveAnswer(
      {
        ...newAnswer,
        answeredById,
        reportId: activityAssessmentId,
        questionId: question.id,
      },
      questionRef.current.answer
    ).then((response) => {
      if (dataChanged)
        toast({
          text: t('question:saved'),
        });
      return response;
    });
  };

  const currentQuestion = {
    ...question,
    number:
      totalQuestions != null
        ? (t('question:number', { index, totalQuestions }) as string)
        : index.toString(),
    t,
    onAnswerChange: onAnswerChange,
    activityAssessmentId: activityAssessmentId,
  };

  if (isMulCriteria)
    return <MultipleCriteriaQuestion question={currentQuestion} isLocked={isLocked} />;

  const getWarning = (): InputCardWarningProps | undefined => {
    if (question.isEditable) {
      if (question.answer?.data == 'NA') {
        return {
          status: 'notApplicable',
        };
      }
      if (question.uniqueId === 'DNSH_A_2' && question.answer?.data?.[0] === 'none') {
        return {
          status: 'noClimateRisksIdentified',
        };
      }
      const hasRoomForImprovement =
        (question.isAligned === true &&
          question.level !== QuestionLevel.SubstantialContribution_ &&
          question.levelExpression?.includes('SC')) ||
        (question.isAligned === false && question.isSecondarySubstantialContributionObjective);
      if (question.isAligned === false || hasRoomForImprovement) {
        return {
          status: hasRoomForImprovement ? 'notAlignedWarning' : 'notAlignedCritical',
          alignmentErrorMessage: question.alignmentErrorMessage,
        };
      }
    }
  };

  const attachmentBox: AttachmentBox | undefined = question?.answer?.attachmentBox ?? undefined;

  const documentation: InputCardDocumentationProps | undefined = question.isEditable
    ? {
        isHidden: readOnly || isLocked,
        showSingleFile: inModal,
        currentAuthor: user ?? undefined,
        attachmentBox: attachmentBox,
        noteHistory: question.answer?.noteHistory,
        onNoteChanged: (note: string | undefined | null) => onNoteChanged(note),
        openAttachmentDrawer: () => openAttachmentDrawer(question),
        refetchQueries: [ActivityAssessmentAnswersDocument_, ActivityAssessmentDocument_],
        isReusable: isReusable,
        questionsToCopyFrom,
        copyNotes,
        copyAttachments,
      }
    : undefined;

  return (
    <QuestionContextProvider
      question={currentQuestion}
      warning={getWarning()}
      documentation={documentation}
      showVersionAlert={showVersionAlert}
      openUpdatedQuestionModal={openUpdatedQuestionModal}
      showNumber={showNumber}
      isLocked={isLocked}
      learnMoreIconButton={learnMoreIconButton}
    >
      <Box
        width="100%"
        ref={ref}
        onClick={() => {
          if (isLocked) {
            toast({
              text: t('common:assessment.isLocked.toast.locked'),
            });
          }
        }}
      >
        <Collapse in={question.isEditable === true} unmountOnExit>
          <QuestionInput isDisabled={readOnly || isLocked} />
        </Collapse>
      </Box>
    </QuestionContextProvider>
  );
}
