import { HStack, VStack } from '@chakra-ui/react';
import { FormField } from 'Atoms';
import { Loader, Modal, RadioCard } from 'Molecules';
import { BusinessUnitsIcon, CompanyIcon } from 'Tokens/Icons/Data';

import {
  AssessmentCompanyIntegrationRuDocument_,
  GetIntegrationApiKeyDocument_,
  useGetIntegrationApiKeyQuery,
  useUpsertIntegrationKeyMutation,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from 'utils/hooks';

export const MoreScopeIntegrationModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const [forBU, setForBU] = useState(true);
  const [upsertIntegrationKey] = useUpsertIntegrationKeyMutation();
  const toast = useToast();

  const { data, loading } = useGetIntegrationApiKeyQuery({
    variables: {
      companyId,
      integrationSystemName: 'moreScope',
    },
    skip: !companyId,
  });

  const integration = useMemo(() => data?.esrs_CompanyIntegration?.[0], [data]);

  useEffect(() => {
    setForBU(integration?.isDisaggregated ?? false);
  }, [integration]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="MoreScope integration"
      hasFooter={true}
      onConfirm={async () => {
        if (integration) {
          if (
            (integration?.isDisaggregated === false && !forBU) ||
            (integration?.isDisaggregated === true && forBU)
          ) {
            onClose();
          } else {
            if (integration?.isDisaggregated === false && forBU) {
              await upsertIntegrationKey({
                variables: {
                  object: {
                    id: integration?.id,
                    companyId,
                    isDisaggregated: forBU,
                    integrationSystemName: 'moreScope',
                    apiKey: integration?.apiKey,
                  },
                },
                refetchQueries: [
                  GetIntegrationApiKeyDocument_,
                  AssessmentCompanyIntegrationRuDocument_,
                ],
                onCompleted: () => {
                  navigate(`/${companyId}/integrations/MoreScope`);
                  onClose();
                },
                onError: (error) => {
                  console.error(error);
                  toast({
                    text: 'There was an error while setting up integration',
                    variant: 'danger',
                  });
                },
              });
            } else {
              await upsertIntegrationKey({
                variables: {
                  object: {
                    id: integration?.id,
                    isDisaggregated: forBU,
                    companyId,
                    integrationSystemName: 'moreScope',
                    apiKey: integration?.apiKey,
                  },
                },
                refetchQueries: [
                  GetIntegrationApiKeyDocument_,
                  AssessmentCompanyIntegrationRuDocument_,
                ],
                onCompleted: () => {
                  navigate(`/${companyId}/integrations/MoreScope`);
                  onClose();
                },
                onError: (error) => {
                  console.error(error);
                  toast({
                    text: 'There was an error while setting up integration',
                    variant: 'danger',
                  });
                },
              });
            }
          }
        } else {
          await upsertIntegrationKey({
            variables: {
              object: {
                isDisaggregated: forBU,
                companyId,
                integrationSystemName: 'moreScope',
              },
            },
            refetchQueries: [GetIntegrationApiKeyDocument_],
            onCompleted: () => {
              onClose();
              navigate(`/${companyId}/integrations/MoreScope`);
            },
            onError: (error) => {
              console.error(error);
              toast({
                text: 'There was an error while setting up integration',
                variant: 'danger',
              });
            },
          });
        }
      }}
      isCentered
    >
      <VStack alignItems="start" w="100%" spacing="16px">
        <FormField
          label="Select how you want to set up MoreScope integration"
          id="integrationSetup"
        >
          <HStack>
            <RadioCard
              title="For company"
              value="true"
              subtitle="Select this option if you have only one API key and your data is collected at the company level."
              isSelected={!forBU}
              onClick={() => {
                if (forBU) setForBU(!forBU);
              }}
              icon={CompanyIcon}
            />
            <RadioCard
              title="Per business unit"
              value="false"
              subtitle="Select this option if you have multiple API keys and your data is disaggregated by different business units."
              isSelected={forBU}
              onClick={() => {
                if (!forBU) setForBU(!forBU);
              }}
              icon={BusinessUnitsIcon}
            />
          </HStack>
        </FormField>
      </VStack>
    </Modal>
  );
};
