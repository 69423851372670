import {
  GetEsrsReportStructureQuery_,
  GetReportMetricsPerDisclosureQuery_,
  useGetReportMaterialStandardsQuery,
} from 'models';
import { useEffect, useState } from 'react';
import { nhost } from 'utils/nhost';

export const useGetMetricsPerReportDisclosure = ({
  assessmentId,
  disclosureRequirementRef,
  groupMetrics,
}: {
  assessmentId: string;
  disclosureRequirementRef: string;
  groupMetrics: string[];
}) => {
  const [metrics, setMetrics] = useState<GetReportMetricsPerDisclosureQuery_>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (assessmentId && disclosureRequirementRef) {
      setLoading(true);
      nhost.functions
        .call<GetReportMetricsPerDisclosureQuery_>('esrs/esrs-report/get-metrics-per-disclosure', {
          assessmentId,
          disclosureRequirementRef,
          groupMetrics,
        })
        .then((res) => {
          setLoading(false);
          if (res.error) {
            console.error(res.error);
            return;
          }

          setMetrics(res.res.data);
        });
    }
  }, [assessmentId, disclosureRequirementRef]);
  return { data: metrics, loading };
};

export const useGetReportMaterialStandards = ({
  esrsAssessmentId,
  isClicked,
  groupMaterialMetricsRefs,
  groupMaterialStandardsRefs,
}: {
  esrsAssessmentId: string;
  isClicked: boolean;
  groupMaterialMetricsRefs: string[];
  groupMaterialStandardsRefs: string[];
}) => {
  const { data, loading } = useGetReportMaterialStandardsQuery({
    variables: {
      esrsAssessmentId,
      groupMetrics: groupMaterialMetricsRefs,
      groupStandards: groupMaterialStandardsRefs,
    },
    fetchPolicy: 'cache-first',
    skip: !esrsAssessmentId || !isClicked,
  });
  return { data, loading };
};

export const useGetEsrsReportStructure = ({
  assessmentId,
  groupStandards,
  groupMetrics,
  isCollectOnly,
}: {
  assessmentId: string;
  groupStandards: string[];
  groupMetrics: string[];
  isCollectOnly: boolean;
}) => {
  const [standards, setStandards] = useState<GetEsrsReportStructureQuery_>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (
      assessmentId &&
      (isCollectOnly ? groupStandards.length > 0 && groupMetrics.length > 0 : true)
    ) {
      setLoading(true);
      nhost.functions
        .call<GetEsrsReportStructureQuery_>('esrs/esrs-report/get-report-structure', {
          assessmentId,
          groupStandards,
          groupMetrics,
        })
        .then((res) => {
          setLoading(false);
          if (res.error) {
            console.error(res.error);
            return;
          }

          setStandards(res.res.data);
        });
    }
  }, [assessmentId, isCollectOnly, groupStandards?.length]);
  return { data: standards, loading };
};
