import { VStack } from '@chakra-ui/react';
import {
  AggregatedMetricsTableData,
  E1_5Metrics,
  E1_5Table,
  E1_6_Target_Metrics,
  E1_6TargetsTable,
  getFlattenedMetricRows,
  GOV_4ParentMetric,
  GOV_4Table,
  MetricsTableData,
  S1_6CountryMetrics,
  S1_6GenderMetrics,
  S1_6MetricsPerTags,
  S1_6SingleMetric,
  S1_8Metrics,
  S1_8Table,
  SelectedMetric,
} from 'containers/Esrs';
import { GetTargetsDrQuery_, ShortUser } from 'models';
import { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import { ClickableReportAnswerComponent } from './ReportClickableAnswers/ClickableReportAnswer';

export const DRHardcodedTables = ({
  aggregatedMetrics,
  answersMap,
  year,
  rowData,
  selectedMetric,
  setRowData,
  setSelectedMetric,
  projectLeader,
  targets,
}: {
  aggregatedMetrics: AggregatedMetricsTableData[];
  answersMap: Map<string, string>;
  year: number | undefined;
  rowData: MetricsTableData | undefined;
  selectedMetric: SelectedMetric | null | undefined;
  setRowData: Dispatch<SetStateAction<MetricsTableData | undefined>>;
  setSelectedMetric: (metric: SelectedMetric) => void;
  projectLeader: ShortUser | undefined;
  targets: GetTargetsDrQuery_ | undefined;
}) => {
  const { disclosureRequirementRef = '' } = useParams();

  if (disclosureRequirementRef === 'S1-6') {
    const customGenderMetrics =
      aggregatedMetrics.filter((m) => S1_6GenderMetrics.includes(m.metric.reference)) ?? [];

    const customCountryMetrics =
      aggregatedMetrics.filter((m) => S1_6CountryMetrics.includes(m.metric.reference)) ?? [];

    const employeesGender = customGenderMetrics.find(
      (m) => m.metric.reference === 'employeesGender'
    );

    const employeesCountry = customCountryMetrics.find(
      (m) => m.metric.reference === 'employeesCountry'
    );

    return (
      <VStack alignItems="start" spacing="20px" w="100%">
        {employeesGender && (
          <ClickableReportAnswerComponent
            metric={employeesGender.metric}
            rowData={rowData}
            selectedMetric={selectedMetric}
            aggregatedMetric={employeesGender}
            projectLeader={projectLeader}
            setSelectedMetric={setSelectedMetric}
            setRowData={setRowData}
          >
            <S1_6SingleMetric metric={employeesGender} type="gender" />
          </ClickableReportAnswerComponent>
        )}
        {employeesCountry && (
          <ClickableReportAnswerComponent
            metric={employeesCountry.metric}
            rowData={rowData}
            selectedMetric={selectedMetric}
            aggregatedMetric={employeesCountry}
            projectLeader={projectLeader}
            setSelectedMetric={setSelectedMetric}
            setRowData={setRowData}
          >
            <S1_6SingleMetric metric={employeesCountry} type="country" />
          </ClickableReportAnswerComponent>
        )}
        {customGenderMetrics?.length && (
          <S1_6MetricsPerTags metrics={customGenderMetrics} type="gender" />
        )}
        {customCountryMetrics?.length && (
          <S1_6MetricsPerTags metrics={customCountryMetrics} type="country" />
        )}
      </VStack>
    );
  }

  if (disclosureRequirementRef === 'S1-8') {
    const customMetrics =
      aggregatedMetrics.filter((m) => S1_8Metrics.includes(m.metric.reference)) ?? [];
    const adjustedMetrics = customMetrics.map((metric) => ({
      ...metric,
      materialMetricTags: metric.metric.materialMetrics?.[0].materialMetricTags,
    }));

    return <S1_8Table metrics={adjustedMetrics} />;
  }

  if (disclosureRequirementRef === 'E1-5') {
    const customMetrics = aggregatedMetrics.filter((m) => E1_5Metrics.includes(m.metric.reference));
    return <E1_5Table metrics={customMetrics} year={year} />;
  }

  if (disclosureRequirementRef === 'GOV-4') {
    const parentSubrows = aggregatedMetrics.find(
      (m) => m.metric.reference === GOV_4ParentMetric
    )?.subRows;
    const gov4MetricsData = parentSubrows?.map((row) => ({
      metric: {
        metric: {
          shortTitle: row.metric.shortTitle,
          title: row.metric.title,
        },
      },
      textAnswer: answersMap.get(row.metric.reference),
    }));
    return <GOV_4Table metrics={gov4MetricsData} />;
  }

  if (disclosureRequirementRef === 'E1-6') {
    const flattenedRows = aggregatedMetrics.flatMap((metric) => getFlattenedMetricRows(metric));
    const customMetrics = flattenedRows.filter((m) =>
      E1_6_Target_Metrics.includes(m.metric.reference)
    );
    return (
      <E1_6TargetsTable
        targets={targets?.DisclosureRequirement_by_pk?.targets}
        metrics={customMetrics}
        year={year ?? new Date().getFullYear()}
      />
    );
  }
  return <></>;
};
