import { useState } from 'react';
import { useGetFilteredFinancialsData } from './FinancialTables.hooks.ts';
import { FinancialFilter } from '../Header/FinancialsHeader.hooks.ts';
import { Box, Skeleton, VStack } from '@chakra-ui/react';
import { BusinessUnitFinancialTable } from './BusinessUnitFinancialTable.tsx';
import { GroupFinancials } from './GroupFinancials.tsx';
import { CompanyFinancialResults } from 'Features/Financials/Financials.hooks.ts';
import { CompanyNotEligibleTable } from './CompanyNotEligibleTable.tsx';
import { FinancialSummary } from './SummaryTable.tsx';
import { TotalComparisonTable } from './TotalComparisonTable.tsx';
import { FinancialTableSkeleton } from './TableComponents/FinancialTableSkeleton.tsx';

export const useInfiniteScroll = (initialCount = 5, loadMoreCount = 5, delay = 300) => {
  const [visibleCount, setVisibleCount] = useState(initialCount);
  const [isLoading, setIsLoading] = useState(false);

  const lastElementRef = (node: HTMLDivElement | null) => {
    if (!node) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsLoading(true);

          setTimeout(() => {
            setVisibleCount((prev) => prev + loadMoreCount);
            setIsLoading(false);
          }, delay);

          observer.disconnect();
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(node);
  };

  return { visibleCount, lastElementRef, isLoading };
};

export const FilteredFinancials = () => {
  const { filter, filteredFinancials, loading, sortedItems } = useGetFilteredFinancialsData();
  const { visibleCount, lastElementRef, isLoading } = useInfiniteScroll(5);

  const tableComponents = sortedItems.map((item) =>
    item.isGroup ? (
      <GroupFinancials key={item.id} reportingGroupId={item.id} bg="solid" />
    ) : (
      <BusinessUnitFinancialTable
        key={item.id}
        businessUnit={item.data as CompanyFinancialResults['businessUnits'][number]}
      />
    )
  );

  const staticTables = [
    <Box key="not-eligible" width="100%">
      <CompanyNotEligibleTable />
    </Box>,
    <Box key="summary" width="100%">
      <FinancialSummary />
    </Box>,
    <Box key="comparison" width="100%">
      <TotalComparisonTable />
    </Box>,
  ];

  const allTables = [...tableComponents, ...staticTables];

  if (filter === FinancialFilter.byId && filteredFinancials) {
    return (
      <Skeleton isLoaded={!loading}>
        <BusinessUnitFinancialTable businessUnit={filteredFinancials} />
      </Skeleton>
    );
  }

  return (
    <Skeleton isLoaded={!loading} width="100%" padding="16px" borderRadius="16px">
      <VStack spacing="16px" paddingBottom="16px" width="100%" alignItems="flex-start">
        {allTables.slice(0, visibleCount).map((table, index) => {
          const isLastItem = index === visibleCount - 1;
          return (
            <Box key={index} ref={isLastItem ? lastElementRef : null} width="100%">
              {table}
            </Box>
          );
        })}

        {isLoading && <FinancialTableSkeleton />}
      </VStack>
    </Skeleton>
  );
};
