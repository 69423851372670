import {
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  Attachment_Constraint_,
  Attachment_Update_Column_,
  EsrsAssessmentAnswersAggregateDocument_,
  Esrs_Answer_Constraint_,
  Esrs_Answer_Update_Column_,
  Esrs_DatapointTag_Constraint_,
  GetEsrsMetricAnswerDocument_,
  GetEsrsMetricsDatapointsDocument_,
  GetEsrsMetricsDatapointsQuery_,
  GetMetricAnswerDocumentationByIdDocument_,
  GetSingleEsrsMetricAnswerDocument_,
  ShortUser,
  useGetEsrsMetricsDatapointsQuery,
  useUpsertDatapointMutation,
} from 'models';
import { useMemo } from 'react';
import { GeneratedAnswer } from './MetricsAITypes';
import { useParams } from 'react-router-dom';
import { useUserData } from '@nhost/react';
import { DatapointSourceEnum, TimePeriodsEnums } from '../../Requirement';
import { MetricsTableData, areArraysOfObjectsEqual } from '../MetricAnswers.hooks';
import { useEsrsAssessmentProjectLeader } from 'containers/Esrs';
import { uniq } from 'lodash';
import { AssessableMetrics } from '../Metrics/ProcessMetrics/MetricsHelperFunctions';

export const omitArrayTypename = (arr: any[]) => arr.map(({ __typename, ...rest }) => rest);

// helpers
const getNestedDataPoints = (metric: MetricsTableData): MetricsTableData[] => {
  const childrenDataPoints: MetricsTableData[] = [];

  const recurseChildren = (row: MetricsTableData) => {
    if (!row.subRows?.length) {
      childrenDataPoints.push(row);
    } else {
      row.subRows.forEach((subRow) => {
        recurseChildren(subRow);
      });
    }
  };

  recurseChildren(metric);

  return childrenDataPoints;
};

const filterUniqueMetricsByNameAndTags = (metrics: MetricsTableData[]): MetricsTableData[] => {
  const uniqueMetrics: MetricsTableData[] = [];
  const uniqueSet = new Set<string>();

  metrics.forEach((metric) => {
    const metricRef = metric.metric.reference;
    const tagsString = metric.tags?.map((tag) => `${tag.tagType} - ${tag.tagValue}`).join(', ');

    const key = `${metricRef}${metric.tags ? ' | ' + tagsString : ''}`;

    if (!uniqueSet.has(key)) {
      uniqueSet.add(key);
      uniqueMetrics.push(metric);
    }
  });

  return uniqueMetrics;
};

export const useMetricAiAnswers = ({
  reportingUnitId,
  quantitativeMetrics,
  narrativeMetrics,
}: {
  reportingUnitId: string;
  quantitativeMetrics: MetricsTableData[];
  narrativeMetrics: AssessableMetrics;
}) => {
  const [upsertDatapoint] = useUpsertDatapointMutation();
  const user: ShortUser | null = useUserData();
  const { esrsAssessmentId = '' } = useParams();
  const { esrsAssessmentProjectLeaderId } = useEsrsAssessmentProjectLeader(esrsAssessmentId);

  const flatNestedMetrics = useMemo(
    () => quantitativeMetrics.flatMap((metric) => getNestedDataPoints(metric)),
    [quantitativeMetrics]
  );

  const uniqueNumericMetrics = useMemo(
    () => filterUniqueMetricsByNameAndTags(flatNestedMetrics),
    [flatNestedMetrics]
  );

  const metricRefs = useMemo(() => {
    const quantitativeMetricRefs = uniqueNumericMetrics.map((metric) => metric.metric.reference);
    const narrativeMetricsRefs = narrativeMetrics.map((metric) => metric.reference);
    return uniq([...quantitativeMetricRefs, ...narrativeMetricsRefs]);
  }, [uniqueNumericMetrics, narrativeMetrics]);

  const { data: metricAnswersData } = useGetEsrsMetricsDatapointsQuery({
    variables: {
      assessmentId: esrsAssessmentId,
      metricRefs: metricRefs,
      reportingUnitId: reportingUnitId,
    },
  });

  const metricAnswers = useMemo(() => {
    return metricAnswersData?.answers ?? [];
  }, [metricAnswersData]);

  // Find empty datapoints that are not answered (metrics with no answers yet)
  const emptyNumericDataPoints = useMemo(() => {
    return uniqueNumericMetrics.filter((metric) => {
      const matchingAnswer = metricAnswers.find((a) => a.metricRef === metric.metric.reference);

      if (!matchingAnswer) {
        return true;
      }

      const matchingDatapoint = matchingAnswer.datapoints.find((dp) =>
        areArraysOfObjectsEqual(omitArrayTypename(dp.datapointTags), metric.tags)
      );

      return !matchingDatapoint || !matchingDatapoint.value;
    });
  }, [uniqueNumericMetrics, metricAnswers]);

  const emptyNarrativeDataPoints = useMemo(() => {
    return narrativeMetrics.filter((metric) => {
      const answer = metricAnswers.find((a) => a.metricRef === metric.reference);
      return !answer || !answer.datapoints[0]?.value;
    });
  }, [narrativeMetrics, metricAnswers]);

  const areAnswersGenerated = useMemo(() => {
    return metricAnswers?.some((answer) =>
      answer.datapoints?.some((dp) => dp.source === DatapointSourceEnum.AI)
    );
  }, [metricAnswers]);

  const populateNarrativeAnswers = (generatedAnswers: GeneratedAnswer[]) => {
    upsertDatapoint({
      variables: {
        objects:
          narrativeMetrics?.map((metric) => {
            const currentAnswer = metricAnswers?.find((dp) => dp.metricRef === metric.reference);
            const dataPoint = currentAnswer?.datapoints[0];
            return {
              id: dataPoint?.id ?? undefined,
              value:
                generatedAnswers.find(
                  (generatedAnswer) => generatedAnswer.metricRef === metric.reference
                )?.answer ?? '',
              timeframe: TimePeriodsEnums.year,
              authorId: user?.id,
              ownerId: dataPoint?.ownerId ?? esrsAssessmentProjectLeaderId,
              source: DatapointSourceEnum.AI,
              answer: {
                data: {
                  metricRef: currentAnswer?.metricRef ?? metric.reference,
                  hasOptedOut: currentAnswer?.hasOptedOut ?? false,
                  optOutReason: currentAnswer?.optOutReason ?? '',
                  reportingUnitId: reportingUnitId,
                  assessmentId: esrsAssessmentId,
                  attachmentBox: {
                    data: {},
                    on_conflict: {
                      constraint: AttachmentBox_Constraint_.AttachmentBoxMetricAnswerIdKey1_,
                      update_columns: [AttachmentBox_Update_Column_.MetricAnswerId_],
                    },
                  },
                  optOutAuthorId: user?.id,
                },
                on_conflict: {
                  constraint:
                    Esrs_Answer_Constraint_.AnswerMetricRefAssessmentIdReportingUnitIdKey_,
                  update_columns: [
                    Esrs_Answer_Update_Column_.HasOptedOut_,
                    Esrs_Answer_Update_Column_.OptOutReason_,
                    Esrs_Answer_Update_Column_.OptOutAuthorId_,
                  ],
                },
              },
            };
          }) ?? [],
      },

      refetchQueries: [
        GetEsrsMetricAnswerDocument_,
        GetEsrsMetricsDatapointsDocument_,
        GetSingleEsrsMetricAnswerDocument_,
        EsrsAssessmentAnswersAggregateDocument_,
      ],
    });
  };

  const populateQuantitativeAnswers = (generatedAnswers: GeneratedAnswer[]) => {
    // Response from ai backend will return NA if it is not valid => filter out results that have answers
    const filteredAnswers = generatedAnswers.filter((a) => a.answer !== 'NA');

    upsertDatapoint({
      variables: {
        objects:
          filteredAnswers?.map((generatedAnswer) => {
            const currentAnswer = metricAnswers?.find(
              (dp) => dp.metricRef === generatedAnswer.metricRef
            );
            const dataPoint = currentAnswer?.datapoints.find((dp) =>
              areArraysOfObjectsEqual(omitArrayTypename(dp.datapointTags), generatedAnswer.tags)
            );

            return {
              id: dataPoint?.id ?? undefined,
              value: generatedAnswer?.answer ?? '0',
              timeframe: dataPoint?.timeframe ?? TimePeriodsEnums.year,
              authorId: user?.id,
              ownerId: dataPoint?.ownerId ?? esrsAssessmentProjectLeaderId,
              source: DatapointSourceEnum.AI,
              datapointTags: {
                data: generatedAnswer?.tags ?? [],
                on_conflict: {
                  constraint:
                    Esrs_DatapointTag_Constraint_.DatapointTagDatapointIdTagValueTagTypeKey_,
                },
              },
              answer: {
                data: {
                  metricRef: currentAnswer?.metricRef ?? generatedAnswer.metricRef,
                  hasOptedOut: currentAnswer?.hasOptedOut ?? false,
                  optOutReason: currentAnswer?.optOutReason ?? '',
                  reportingUnitId: reportingUnitId,
                  assessmentId: esrsAssessmentId,
                  attachmentBox: {
                    data: {},
                    on_conflict: {
                      constraint: AttachmentBox_Constraint_.AttachmentBoxMetricAnswerIdKey1_,
                      update_columns: [AttachmentBox_Update_Column_.MetricAnswerId_],
                    },
                  },
                  optOutAuthorId: user?.id,
                },
                on_conflict: {
                  constraint:
                    Esrs_Answer_Constraint_.AnswerMetricRefAssessmentIdReportingUnitIdKey_,
                  update_columns: [
                    Esrs_Answer_Update_Column_.HasOptedOut_,
                    Esrs_Answer_Update_Column_.OptOutReason_,
                    Esrs_Answer_Update_Column_.OptOutAuthorId_,
                  ],
                },
              },
            };
          }) ?? [],
      },

      refetchQueries: [
        GetEsrsMetricAnswerDocument_,
        GetEsrsMetricsDatapointsDocument_,
        GetSingleEsrsMetricAnswerDocument_,
        EsrsAssessmentAnswersAggregateDocument_,
      ],
    });
  };

  return {
    populateNarrativeAnswers,
    populateQuantitativeAnswers,
    areAnswersGenerated,
    uniqueNumericMetrics,
    emptyNumericDataPoints,
    emptyNarrativeDataPoints,
    metricAnswers,
  };
};

export const usePopulateMetricAIAnswers = ({
  metricAnswers,
  reportingUnitId,
  fileId,
}: {
  metricAnswers: GetEsrsMetricsDatapointsQuery_['answers'];
  reportingUnitId?: string;
  fileId: string;
}) => {
  const [upsertDatapoint] = useUpsertDatapointMutation();
  const user: ShortUser | null = useUserData();
  const { esrsAssessmentId = '' } = useParams();
  const { esrsAssessmentProjectLeaderId } = useEsrsAssessmentProjectLeader(esrsAssessmentId);

  const populateNarrativeAnswers = (
    generatedAnswers: GeneratedAnswer[],
    narrativeMetrics: AssessableMetrics
  ) => {
    upsertDatapoint({
      variables: {
        objects:
          narrativeMetrics?.map((metric) => {
            const currentAnswer = metricAnswers?.find(
              (answer) => answer.metricRef === metric.reference
            );
            const dataPoint = currentAnswer?.datapoints[0];
            const generatedAnswer = generatedAnswers.find((a) => a.metricRef === metric.reference);

            return {
              id: dataPoint?.id ?? undefined,
              value: generatedAnswer?.answer ?? '',
              timeframe: TimePeriodsEnums.year,
              authorId: user?.id,
              ownerId: dataPoint?.ownerId ?? esrsAssessmentProjectLeaderId,
              source: DatapointSourceEnum.AI,
              answer: {
                data: {
                  metricRef: currentAnswer?.metricRef ?? metric.reference,
                  hasOptedOut: currentAnswer?.hasOptedOut ?? false,
                  optOutReason: currentAnswer?.optOutReason ?? '',
                  reportingUnitId: reportingUnitId,
                  assessmentId: esrsAssessmentId,
                  attachmentBox: {
                    data: {
                      attachments: {
                        data: [
                          {
                            fileId,
                            deletedAt: null,
                          },
                        ],
                        on_conflict: {
                          constraint: Attachment_Constraint_.AttachmentAttachmentBoxIdFileIdKey_,
                          update_columns: [Attachment_Update_Column_.DeletedAt_],
                        },
                      },
                    },
                    on_conflict: {
                      constraint: AttachmentBox_Constraint_.AttachmentBoxMetricAnswerIdKey1_,
                      update_columns: [AttachmentBox_Update_Column_.MetricAnswerId_],
                    },
                  },
                  optOutAuthorId: user?.id,
                },
                on_conflict: {
                  constraint:
                    Esrs_Answer_Constraint_.AnswerMetricRefAssessmentIdReportingUnitIdKey_,
                  update_columns: [
                    Esrs_Answer_Update_Column_.HasOptedOut_,
                    Esrs_Answer_Update_Column_.OptOutReason_,
                    Esrs_Answer_Update_Column_.OptOutAuthorId_,
                  ],
                },
              },
            };
          }) ?? [],
      },

      refetchQueries: [
        GetEsrsMetricAnswerDocument_,
        GetEsrsMetricsDatapointsDocument_,
        GetSingleEsrsMetricAnswerDocument_,
        EsrsAssessmentAnswersAggregateDocument_,
        GetMetricAnswerDocumentationByIdDocument_,
      ],
    });
  };

  const populateQuantitativeAnswers = (
    generatedAnswers: GeneratedAnswer[],
    numericMetrics: MetricsTableData[]
  ) => {
    upsertDatapoint({
      variables: {
        objects:
          (numericMetrics ?? [])?.map((metric) => {
            const currentAnswer = metricAnswers?.find(
              (answer) => answer.metricRef === metric.metric.reference
            );

            const generatedAnswer = generatedAnswers.find(
              (a) =>
                a.metricRef === metric.metric.reference &&
                areArraysOfObjectsEqual(a.tags, metric.tags)
            );

            const currentDataPoint = currentAnswer?.datapoints.find((dp) =>
              areArraysOfObjectsEqual(omitArrayTypename(dp.datapointTags), generatedAnswer?.tags)
            );

            return {
              id: currentDataPoint?.id ?? undefined,
              value: generatedAnswer?.answer ?? '0',
              timeframe: currentDataPoint?.timeframe ?? TimePeriodsEnums.year,
              authorId: user?.id,
              ownerId: currentDataPoint?.ownerId ?? esrsAssessmentProjectLeaderId,
              source: DatapointSourceEnum.AI,
              datapointTags: {
                data: generatedAnswer?.tags ?? [],
                on_conflict: {
                  constraint:
                    Esrs_DatapointTag_Constraint_.DatapointTagDatapointIdTagValueTagTypeKey_,
                },
              },
              answer: {
                data: {
                  metricRef: currentAnswer?.metricRef ?? metric.metric.reference,
                  hasOptedOut: currentAnswer?.hasOptedOut ?? false,
                  optOutReason: currentAnswer?.optOutReason ?? '',
                  reportingUnitId: reportingUnitId,
                  assessmentId: esrsAssessmentId,
                  attachmentBox: {
                    data: {
                      attachments: {
                        data: [
                          {
                            fileId,
                            deletedAt: null,
                          },
                        ],
                        on_conflict: {
                          constraint: Attachment_Constraint_.AttachmentAttachmentBoxIdFileIdKey_,
                          update_columns: [Attachment_Update_Column_.DeletedAt_],
                        },
                      },
                    },
                    on_conflict: {
                      constraint: AttachmentBox_Constraint_.AttachmentBoxMetricAnswerIdKey1_,
                      update_columns: [AttachmentBox_Update_Column_.MetricAnswerId_],
                    },
                  },
                  optOutAuthorId: user?.id,
                },
                on_conflict: {
                  constraint:
                    Esrs_Answer_Constraint_.AnswerMetricRefAssessmentIdReportingUnitIdKey_,
                  update_columns: [
                    Esrs_Answer_Update_Column_.HasOptedOut_,
                    Esrs_Answer_Update_Column_.OptOutReason_,
                    Esrs_Answer_Update_Column_.OptOutAuthorId_,
                  ],
                },
              },
            };
          }) ?? [],
      },

      refetchQueries: [
        GetEsrsMetricAnswerDocument_,
        GetEsrsMetricsDatapointsDocument_,
        GetSingleEsrsMetricAnswerDocument_,
        EsrsAssessmentAnswersAggregateDocument_,
        GetMetricAnswerDocumentationByIdDocument_,
      ],
    });
  };

  return { populateQuantitativeAnswers, populateNarrativeAnswers };
};
