import { HStack } from '@chakra-ui/react';
import { TruncatableText } from 'Atoms';
import { MetricTypes, MetricTypeIcon } from 'Molecules/MetricTypeIcon';
import { UserAvatar } from 'Organisms';
import { ShortUser } from 'models';

export const MdrmPopover = ({
  question,
  isVisible,
  owner,
}: {
  question: string;
  isVisible: boolean;
  owner?: ShortUser;
}) => {
  return (
    <HStack
      position="absolute"
      w="100%"
      style={{ transform: 'translateY(calc(-100% - 4px))' }}
      shadow="interactive.accent"
      borderRadius="8px"
      padding="8px"
      bg="bg.default"
      gap="8px"
      opacity={isVisible ? 1 : 0}
      transition="all 0.4s"
      pointerEvents="none"
    >
      <MetricTypeIcon type={MetricTypes.text} />
      <UserAvatar user={owner} size="xs" />
      <TruncatableText variant="body" text={question} />
    </HStack>
  );
};
