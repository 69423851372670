import { AggregatedMetricsTableData } from 'containers/Esrs/pieces/DisclosureRequirements';
import {
  processCountryMetricsData,
  processGenderMetricsData,
} from '../../DocGenerator/CustomTables/employees-by-contract';
import { ColumnDefResolved } from '@tanstack/react-table';
import { Typography } from 'Tokens';
import { NestedTable } from 'Molecules/NestedTable';
import { uniq } from 'lodash';
import { S1_6CountryMetrics } from './HardCodedTablesData';

type TableData = {
  title: string;
  female?: string | number;
  male?: string | number;
  other?: string | number;
  notReported?: string | number;
  total: string | number;
  subRows: undefined;
};

const genderHeader = [
  { label: '', value: 'title' },
  { label: 'FEMALE', value: 'female' },
  { label: 'MALE', value: 'male' },
  { label: 'OTHER (*)', value: 'other' },
  { label: 'NOT DISCLOSED', value: 'notReported' },
  { label: 'TOTAL', value: 'total' },
];

export const S1_6MetricsPerTags = ({
  metrics,
  type,
}: {
  metrics: AggregatedMetricsTableData[];
  type: 'country' | 'gender';
}) => {
  // Country
  const countries = uniq(
    metrics
      .flatMap((metric) =>
        metric.metric.materialMetrics?.[0]?.materialMetricTags
          .find((tag) => tag.tagType === 'Country')
          ?.materialTagValues.flatMap((value) => value.tagValue)
      )
      .filter((t): t is string => !!t)
  );
  const countryHeader = [
    { label: '', value: 'title' },
    ...countries.map((country) => ({ label: country, value: country })),
    { label: 'TOTAL', value: 'total' },
  ];
  const countryMetricsData = processCountryMetricsData(metrics, countries, S1_6CountryMetrics);

  // Gender
  const genderMetricsData = processGenderMetricsData(
    metrics,
    metrics.map((m) => m.metric.reference)
  );
  const tableData = (type === 'gender' ? genderMetricsData : countryMetricsData).map((metric) => ({
    title: metric.title,
    ...metric.answers,
    subRows: undefined,
  }));

  const columns: ColumnDefResolved<TableData>[] = (
    type === 'gender' ? genderHeader : countryHeader
  ).map((column) => ({
    header: column.label,
    accessorKey: column.value,
    cell: ({ row }) => (
      <Typography variant="body">{row.original?.[column.value as keyof TableData]}</Typography>
    ),
  }));

  return (
    <NestedTable<TableData>
      columns={columns}
      data={tableData}
      withOuterBorder={true}
      borderRadius="0px"
      rowHeight="36px"
    />
  );
};
