import { GetDatapointMdrmAnswersQuery_ } from 'models';

export type MdrmAnswerType = GetDatapointMdrmAnswersQuery_['esrs_DatapointMdrm'][number];

export type MdrmAnswers = {
  metricUsed: MdrmAnswerType | undefined;
  methodology: MdrmAnswerType | undefined;
  validation: MdrmAnswerType | undefined;
  entitySpecific: MdrmAnswerType | undefined;
};

export enum MdrmTypeEnum {
  metricUsed = 'metricUsed',
  methodology = 'methodology',
  validation = 'validation',
  entitySpecific = 'entitySpecific',
}

export type MdrmType = MdrmTypeEnum;

export type MdrmQuestionType = {
  id: MdrmType;
  question: string;
};

export type MappedMdrm = MdrmQuestionType & {
  answer: MdrmAnswerType | undefined;
};

export type OnMdrmAnswerChangeType = (value: string, mdrmId: MdrmType, ownerId: string) => void;

export const MDRM_QUESTIONS: MdrmQuestionType[] = [
  {
    id: MdrmTypeEnum.metricUsed,
    question:
      'Description of the metric(s) used to evaluate performance and effectiveness, in relation to material impact, risk or opportunity',
  },
  {
    id: MdrmTypeEnum.methodology,
    question:
      'Disclosure of the methodologies and significant assumptions behind the metric(s), including the limitations of the methodologies used',
  },
  {
    id: MdrmTypeEnum.validation,
    question:
      'Disclosure of whether the measurement of the metric(s) is validated by an external body other than the assurance provider and, if so, which body',
  },
  {
    id: MdrmTypeEnum.entitySpecific,
    question:
      'If the entity-specific metric is stemming from other legislation or generally accepted sustainability reporting standards and frameworks, provide the name and reference to the other legislation/standard/framework',
  },
];
