import { useTranslation } from 'utils/translation';
import { MetricsTableData } from '..';
import { useCurrentCompany, useToast } from 'utils/hooks';
import {
  extractNarrativeAnswer,
  extractNumericAnswer,
  extractTextFromPdf,
} from './AIRequestFunctions';
import { GeneratedAnswer } from './MetricsAITypes';
import { useCallback, useMemo } from 'react';
import { Language } from 'utils/language';
import { isAxiosError } from 'axios';
import { AssessableMetrics } from '../Metrics/ProcessMetrics/MetricsHelperFunctions';

export const useGenerateNumericAnswers = ({
  quantitativeMetrics,
  abortControllerRef,
}: {
  quantitativeMetrics: MetricsTableData[];
  abortControllerRef: React.RefObject<AbortController | null>;
}) => {
  const { t } = useTranslation('ai');
  const toast = useToast();

  const generateNumericAnswers = useCallback(
    async (text: string): Promise<GeneratedAnswer[]> => {
      const requestSignal = abortControllerRef.current?.signal;
      const errors = new Set<string>();
      let successCount = 0;
      let isCancelled = false;

      const availableDataPoints = quantitativeMetrics.map((metric) => {
        const resolveTagsString = () => {
          const metricStandaloneTitle = metric.metric.title;
          const tags = metric.tags;
          const tagsString = tags?.map((tag) => `by ${tag.tagType} for ${tag.tagValue}`).join(', ');
          return tags?.length ? `${metricStandaloneTitle} ${tagsString}` : metricStandaloneTitle;
        };

        return {
          reference: metric.metric.reference,
          metricTitleWithTags: resolveTagsString(),
          unit: metric.metric.unitOfMeasurement ?? 'NA',
          title: metric.metric.title,
          tags: metric.tags,
        };
      });

      const extractedAnswers = await Promise.all(
        availableDataPoints.map(async (dp) => {
          try {
            const populatedAnswer = await extractNumericAnswer(
              dp.metricTitleWithTags,
              dp.unit,
              text,
              requestSignal
            );

            successCount++;

            const metricAnswerData = populatedAnswer.data;

            return {
              ...metricAnswerData,
              metricRef: dp.reference,
              metricTitle: dp.title,
              tags: dp.tags,
            };
          } catch (error) {
            if (requestSignal?.aborted) {
              isCancelled = true;
              return null;
            }

            let errorMessage = t('esrs.errors.generateNumericAnswers'); // Default error message

            if (isAxiosError(error) && error.response?.statusText === 'Payload Too Large') {
              errorMessage =
                'The file is too large or contains too much text to process. Please try splitting it into smaller sections or using a file with shorter text.';
            }

            errors.add(errorMessage);
            return null;
          }
        })
      );

      if (isCancelled) {
        toast({
          text: 'Answers generation was cancelled.',
        });
      }

      if (errors.size > 0) {
        const errorMessages = Array.from(errors).join('\n');

        toast({
          variant: 'danger',
          text:
            successCount > 0
              ? `Generated ${successCount} answers successfully, but some issues occurred:\n${errorMessages}`
              : errorMessages,
        });
      }

      return extractedAnswers.filter(Boolean);
    },
    [quantitativeMetrics, abortControllerRef]
  );

  return { generateNumericAnswers };
};

export const useGenerateNarrativeAnswers = ({
  narrativeMetrics,
  abortControllerRef,
}: {
  narrativeMetrics: AssessableMetrics;
  abortControllerRef: React.RefObject<AbortController | null>;
}) => {
  const { t } = useTranslation('ai');
  const toast = useToast();
  const { company } = useCurrentCompany();

  const aiConfig = useMemo(() => {
    return {
      preferredLanguage: company?.aiConfig?.preferredLanguage ?? Language.EnglishUK,
      toneOfVoice: company?.aiConfig?.toneOfVoice ?? '',
    };
  }, [company?.aiConfig]);

  const { preferredLanguage, toneOfVoice } = aiConfig;

  const generateNarrativeAnswers = useCallback(
    async (text: string): Promise<GeneratedAnswer[]> => {
      const requestSignal = abortControllerRef?.current?.signal;
      const errors = new Set<string>();
      let successCount = 0;
      let isCancelled = false;

      const extractedAnswers = await Promise.all(
        narrativeMetrics.map(async (metric) => {
          try {
            const populatedAnswer = await extractNarrativeAnswer(
              metric.title,
              text,
              preferredLanguage,
              toneOfVoice,
              requestSignal
            );

            successCount++;

            const metricAnswerData = populatedAnswer.data;

            return { ...metricAnswerData, metricRef: metric.reference };
          } catch (error) {
            if (requestSignal?.aborted) {
              isCancelled = true;
              return null;
            }

            let errorMessage = t('esrs.errors.generateNarrativeAnswers'); // Default error message

            if (isAxiosError(error) && error.response?.statusText === 'Payload Too Large') {
              errorMessage =
                'The file is too large or contains too much text to process. Please try splitting it into smaller sections or using a file with shorter text.';
            }

            errors.add(errorMessage);
            return null;
          }
        })
      );

      if (isCancelled) {
        toast({
          variant: 'danger',
          text: 'Answers generation was cancelled.',
        });
      }

      if (errors.size > 0) {
        const errorMessages = Array.from(errors).join('\n');

        toast({
          variant: 'danger',
          text:
            successCount > 0
              ? `Generated ${successCount} answers successfully, but some issues occurred:\n${errorMessages}`
              : errorMessages,
        });
      }

      return extractedAnswers.filter(Boolean);
    },
    [narrativeMetrics, abortControllerRef, aiConfig]
  );

  return { generateNarrativeAnswers };
};

export const useReadPdfText = ({
  abortControllerRef,
}: {
  abortControllerRef: React.RefObject<AbortController | null>;
}) => {
  const { t } = useTranslation('ai');
  const toast = useToast();

  const readPdfText = async (fileUrl: string, fileName: string) => {
    const requestSignal = abortControllerRef?.current?.signal;

    try {
      const result = await extractTextFromPdf(fileUrl, fileName, requestSignal);
      const combinedContent = result.content.reduce((acc: string, curr: any) => {
        return acc + curr.text;
      }, '');
      return combinedContent;
    } catch (error) {
      if (requestSignal?.aborted) {
        toast({
          text: 'Answers generation cancelled',
        });
      } else {
        toast({
          variant: 'danger',
          text: t('pdfRestriction'),
        });
      }
    }
  };

  return { readPdfText };
};
