import bm25 from 'wink-bm25-text-search';
import winkNLP, { ItsHelpers, ModelAddons, WinkMethods } from 'wink-nlp';
import model from 'wink-eng-lite-web-model';
import ActivitiesSearchJson from 'utils/constants/activitiesSearch.json';
import synonymsArray from 'synonyms-array';
import synonyms from 'synonyms';
import { Activity } from 'models';
import './search.d';

export const getQuerySynonyms = (searchQuery: string | string[]) => {
  const tokens = searchQuery instanceof Array ? searchQuery : searchQuery.split(' ');
  const querySynonyms = tokens.map((token) => {
    // Raw synonyms
    const defaultSynonyms = synonymsArray.get(token);

    // POS (part of speech) synonyms
    const { v = [], n = [], s = [] } = synonyms(token) ?? { v: [], n: [], s: [] };
    const posSynonyms = [...v, ...n, ...s];
    return [...defaultSynonyms, ...posSynonyms] as string[];
  });
  return [...new Set(querySynonyms.flatMap((q) => q))];
};

const extractQueryKeywords = (its: ItsHelpers, nlp: WinkMethods, text: string) => {
  const tokens: Array<string> = [];
  nlp
    .readDoc(text)
    .tokens()
    // Use only words ignoring punctuations etc and from them remove stop words
    .filter(
      (t) =>
        (t.out(its.type) === 'word' || t.out(its.type) === 'number') && !t.out(its.stopWordFlag)
    )
    // Extract stem of the word
    .each(
      (t: {
        out: (
          arg0?: (index: number, token: any, cache: any, addons: ModelAddons) => string
        ) => string;
      }) => {
        const tokenType = t.out(its.type);
        if (tokenType === 'number') {
          tokens.push(t.out()); // Directly push the number without stemming/normalization
        } else {
          tokens.push(t.out(its.stem) || t.out(its.normal));
        }
      }
    );

  return tokens;
};

const calculateSimpleSearchMatchScore = (query: string, activity: Activity): number => {
  const lowerQuery = query.toLowerCase();
  const queryWords = lowerQuery.split(/\s+/);
  let score = 0;

  // Higher weight for exact matches in the name
  if (activity.name.toLowerCase() === lowerQuery) score += 100;

  // Partial match in the name (title)
  if (activity.name.toLowerCase().includes(lowerQuery)) score += 50;

  // Check for each word in the name
  queryWords.forEach((word) => {
    if (activity.name.toLowerCase().includes(word)) score += 20;
  });

  // Partial match in the description
  if (activity.description.toLowerCase().includes(lowerQuery)) score += 30;

  // Check for each word in the description
  queryWords.forEach((word) => {
    if (activity.description.toLowerCase().includes(word)) score += 10;
  });

  // Match in reference numbers (if applicable)
  if (activity.referenceNumber && activity.referenceNumber.toLowerCase().includes(lowerQuery))
    score += 20;

  // Match in reference (if applicable)
  if (activity.reference && activity.reference.toLowerCase().includes(lowerQuery)) score += 20;

  // Match in naceCodes (if applicable)
  if (activity.naceCodes.length > 0) {
    const naceCodesMatchScore = activity.naceCodes.reduce(
      (acc, naceCode) => (naceCode.code.toLowerCase().includes(lowerQuery) ? acc + 10 : acc),
      0
    );
    score += naceCodesMatchScore;
  }

  return score;
};

const winkNLPSearch = (its: ItsHelpers, nlp: WinkMethods, searchQuery: string) => {
  const engine = bm25();
  engine.importJSON(JSON.stringify(ActivitiesSearchJson ?? {}));
  engine.definePrepTasks([(text: string) => extractQueryKeywords(its, nlp, text)]);
  return engine.search(searchQuery);
};

export const smartSearchActivities = async (searchQuery: string, activities: Array<Activity>) => {
  const nlp = winkNLP(model);
  const its = nlp.its;

  try {
    // Search by raw query
    const winkResults = await winkNLPSearch(its, nlp, searchQuery);

    const rawQueryResults = [...(winkResults as Array<[ref: string, score: number]>)];

    // Search by query semantics
    const tokens = extractQueryKeywords(its, nlp, searchQuery);
    const querySynonyms = getQuerySynonyms(tokens);
    const synonymResults = querySynonyms.reduce(
      (acc: Array<[ref: string, score: number]>, curToken: string) => {
        const synonymSearchResults = winkNLPSearch(its, nlp, curToken);
        return [...acc, ...(synonymSearchResults as Array<[ref: string, score: number]>)];
      },
      []
    );

    const simpleSearchResults = activities
      .map((a) => ({
        ref: a.reference ?? '',
        score: calculateSimpleSearchMatchScore(searchQuery, a),
      }))
      .sort((a, b) => b.score - a.score)
      .filter((a) => a.score > 0 && !!a.ref);

    const smartSearchResults = [...rawQueryResults, ...synonymResults];
    const sortedSmartSearchResults = smartSearchResults
      .sort((a, b) => b[1] - a[1])
      .map((a: [ref: string, score: number]) => ({
        ref: a[0],
        score: a[1],
      }));

    const uniqueResults = [...new Set([...simpleSearchResults, ...sortedSmartSearchResults])];

    return uniqueResults;
  } catch (e) {
    // silently fail
    return activities
      .map((a) => ({
        ref: a.reference ?? '',
        score: calculateSimpleSearchMatchScore(searchQuery, a),
      }))
      .sort((a, b) => b.score - a.score)
      .filter((a) => a.score > 0 && !!a.ref);
  }
};
