import { HStack, VStack, Box, useDisclosure } from '@chakra-ui/react';
import { Modal } from 'Molecules';
import { Typography } from 'Tokens';
import { AIIcon } from 'Tokens/Icons/Function';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  DisclosureRequirementAttachment,
  PolicyAttachment,
  useDisclosureRequirementAttachment,
} from '../../DisclosureRequirementAttachment';
import { AISelectorMenu } from './MetricsAIOptionsSelector';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import {
  Attachment,
  GetAssessmentCurrencyConversionQuery_,
  GetDisclosureRequirementAttachmentDocument_,
  GetDisclosureRequirementGroupsDocument_,
  GetRequiredMaterialMetricsQuery_,
  QuestionType_Enum_,
  User,
} from 'models';
import {
  AggregatedMetricsTableData,
  AggregatedQualitativeAnswers,
  getNestedRows,
  hasChildOnBULevel,
  hasChildOnSubLevel,
  MetricsTableData,
} from '..';
import { TimePeriods } from '../..';
import { SelectedMetric } from 'containers/Esrs';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { GeneratedAnswer, GeneratedAnswerStatus } from './MetricsAITypes';
import { useMetricAiAnswers, usePopulateMetricAIAnswers } from './MetricsAI.hooks';
import { filterLockedChildrenMetrics, flattenNarrativeMetrics } from '../Metrics.utils';
import { AnswersGeneratorButton } from './AnswersGeneratorButton';
import {
  AIProgressModal,
  AIStickyToolbar,
  AnswersApprovalWarningModal,
  InteractionBlocker,
} from './AIUtils';
import { MetricsAITable } from './MetricsAITable';
import { MetricSidebar } from '../MetricSidebar';
import { Alert, IconButton } from 'Atoms';
import { InfoIcon } from 'Tokens/Icons/Status';
import { AssessableMetrics } from '../Metrics/ProcessMetrics/MetricsHelperFunctions';

export enum AIGenerationOptions {
  empty = 'All empty',
  all = 'All',
  selected = 'Selected',
}

const filterAggregatedMetrics = (
  metrics: AggregatedMetricsTableData[],
  isGroup: boolean
): AggregatedMetricsTableData[] => {
  const checkChildLevel = isGroup ? hasChildOnSubLevel : hasChildOnBULevel;

  const filterSubRows = (subRows: AggregatedMetricsTableData[]): AggregatedMetricsTableData[] => {
    return subRows.filter((s) => {
      if (s.metric.metricType === QuestionType_Enum_.Decimal_ && checkChildLevel(s.metric)) {
        return false;
      }

      if (s.subRows?.length) {
        s.subRows = filterSubRows(s.subRows);
      }
      return true;
    });
  };

  return metrics
    .filter((m) => {
      if (m.metric.metricType === QuestionType_Enum_.Decimal_ && checkChildLevel(m.metric)) {
        return false;
      }
      return true;
    })
    .map((m) => {
      return {
        ...m,
        subRows: filterSubRows(m.subRows || []),
      };
    });
};

type AIModalProps = {
  isOpen: boolean;
  onClose: () => void;
  materialStandardId: string;
  disclosureRequirementRef: string;
  reportingUnitId?: string;
  allFilteredMetrics: MetricsTableData[];
  selectedQuarter: TimePeriods;
  selectedMetric?: SelectedMetric;
  setSelectedMetric: (param: SelectedMetric) => void;
  onOpen: () => void;
  isGroup: boolean;
  rowData?: MetricsTableData;
  setRowData: (data: MetricsTableData | undefined) => void;
  esrsAssessmentProjectLeader?: Partial<User>;
  qualitativeMetrics: AggregatedMetricsTableData[];
  summaryQualitativeMetrics: AssessableMetrics;
  aggregatedMetricsAnswers: AggregatedQualitativeAnswers;
  isPolicy?: boolean;
  policyAttachments?: Attachment[];
  parentStandardId: string | undefined;
  requiredMaterialMetrics: GetRequiredMaterialMetricsQuery_['esrs_MaterialMetric'];
  onAIDisclaimerOpen: () => void;
  currencyConversionData?: GetAssessmentCurrencyConversionQuery_['esrs_AssessmentCurrencyConversion'];
};

export const MetricsAIModal = ({
  isOpen,
  onClose,
  disclosureRequirementRef,
  materialStandardId,
  reportingUnitId,
  allFilteredMetrics,
  selectedQuarter,
  selectedMetric,
  setSelectedMetric,
  onOpen,
  isGroup,
  rowData,
  setRowData,
  esrsAssessmentProjectLeader,
  qualitativeMetrics,
  summaryQualitativeMetrics,
  aggregatedMetricsAnswers,
  isPolicy = false,
  policyAttachments,
  parentStandardId,
  requiredMaterialMetrics,
  onAIDisclaimerOpen,
  currencyConversionData,
}: AIModalProps) => {
  const abortControllerRef = useRef<AbortController | null>(null);

  const toast = useToast();

  const [selectedOption, setSelectedOption] = useState<AIGenerationOptions>(
    AIGenerationOptions.empty
  );
  const [isComments, setIsComments] = useState<boolean>(false);
  const isBusinessUnit = useMemo(() => !!reportingUnitId, [reportingUnitId]);

  const [selectedNumericMetrics, setSelectedNumericMetrics] = useState<MetricsTableData[]>([]);
  const [selectedNarrativeMetrics, setSelectedNarrativeMetrics] = useState<AssessableMetrics>([]);

  const [generatedAnswers, setGeneratedAnswers] = useState<GeneratedAnswer[]>([]);

  const validGeneratedAnswers = useMemo(() => {
    return generatedAnswers.filter((a) => {
      return a.answer !== 'NA' && a.answer !== '-';
    });
  }, [generatedAnswers]);

  const [isGeneratingAnswers, setIsGeneratingAnswers] = useState<boolean>(false);

  const { attachmentBox, attachmentDataLoading, upsertDRAttachment } =
    useDisclosureRequirementAttachment({
      disclosureRequirementRef,
      materialStandardId,
      reportingUnitId: reportingUnitId ?? '',
    });

  // For policies
  const [selectedPolicyFileId, setSelectedPolicyFileId] = useState<string>('');

  const attachment = useMemo(() => {
    return isPolicy
      ? policyAttachments?.find((a) => a.file.id === selectedPolicyFileId)
      : attachmentBox?.attachments?.[0];
  }, [attachmentBox, isPolicy, policyAttachments, selectedPolicyFileId]);

  useEffect(() => {
    if (isPolicy && policyAttachments?.length === 1) {
      setSelectedPolicyFileId(policyAttachments[0].file.id);
    }
  }, [isPolicy, policyAttachments]);

  const filteredAggregatedNarrativeMetics = useMemo(
    () => filterAggregatedMetrics(qualitativeMetrics, isGroup),
    [qualitativeMetrics, isGroup]
  );
  const filteredAggregatedQuantitativeMetics = useMemo(
    () => filterAggregatedMetrics(allFilteredMetrics, isGroup),
    [allFilteredMetrics, isGroup]
  );

  const filteredNarrativeMetics = useMemo(
    () => filterLockedChildrenMetrics(filteredAggregatedNarrativeMetics),
    [filteredAggregatedNarrativeMetics]
  );

  const flattenedNarrativeMetrics = useMemo(() => {
    return flattenNarrativeMetrics(filteredNarrativeMetics.map((m) => m.metric));
  }, [filteredNarrativeMetics]);

  // Get all narrative metrics with children flattened
  const allNarrativeMetrics = useMemo(() => {
    return [
      ...flattenedNarrativeMetrics.filter((m) => m.metricType !== QuestionType_Enum_.Decimal_),
      ...summaryQualitativeMetrics,
    ];
  }, [filteredNarrativeMetics, summaryQualitativeMetrics]);

  // Get all numeric metrics and numeric children from narrative parents
  const allNumericMetrics = useMemo(() => {
    const numericMetricsFromNarrativeParents = flattenedNarrativeMetrics
      .filter((m) => m.metricType === QuestionType_Enum_.Decimal_)
      .map((metric) => {
        return getNestedRows({ metric, materialStandardId, isGroup });
      });

    const standaloneNumericMetrics = isBusinessUnit
      ? allFilteredMetrics
      : filteredAggregatedQuantitativeMetics;

    return [...standaloneNumericMetrics, ...numericMetricsFromNarrativeParents];
  }, [flattenedNarrativeMetrics, materialStandardId]);

  const { uniqueNumericMetrics, emptyNumericDataPoints, emptyNarrativeDataPoints, metricAnswers } =
    useMetricAiAnswers({
      reportingUnitId: reportingUnitId ?? '',
      quantitativeMetrics: allNumericMetrics,
      narrativeMetrics: allNarrativeMetrics,
    });

  const { populateQuantitativeAnswers, populateNarrativeAnswers } = usePopulateMetricAIAnswers({
    metricAnswers,
    reportingUnitId,
    fileId: isPolicy ? selectedPolicyFileId : attachmentBox?.attachments?.[0]?.file.id,
  });

  const aiNumericDatapoints = useMemo(() => {
    if (selectedOption === AIGenerationOptions.all) {
      return uniqueNumericMetrics;
    } else if (selectedOption === AIGenerationOptions.selected) {
      return selectedNumericMetrics;
    } else if (selectedOption === AIGenerationOptions.empty) {
      return emptyNumericDataPoints;
    } else {
      return [];
    }
  }, [selectedOption, selectedNumericMetrics, uniqueNumericMetrics, emptyNumericDataPoints]);

  const aiNarrativeDatapoints = useMemo(() => {
    if (selectedOption === AIGenerationOptions.all) {
      return allNarrativeMetrics;
    } else if (selectedOption === AIGenerationOptions.selected) {
      return selectedNarrativeMetrics;
    } else if (selectedOption === AIGenerationOptions.empty) {
      return emptyNarrativeDataPoints;
    } else {
      return [];
    }
  }, [selectedOption, selectedNarrativeMetrics, allNarrativeMetrics, emptyNarrativeDataPoints]);

  const hasEmptyDatapoints = useMemo(() => {
    return emptyNumericDataPoints.length > 0 || emptyNarrativeDataPoints.length > 0;
  }, [emptyNumericDataPoints, emptyNarrativeDataPoints]);

  useEffect(() => {
    if (hasEmptyDatapoints) {
      setSelectedOption(AIGenerationOptions.empty);
    } else {
      setSelectedOption(AIGenerationOptions.selected);
    }
  }, [emptyNarrativeDataPoints, emptyNumericDataPoints]);

  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();

  const {
    isOpen: isApprovalWarningModalOpen,
    onOpen: onApprovalWarningModalOpen,
    onClose: onApprovalWarningModalClose,
  } = useDisclosure();

  const {
    isOpen: isAIProgressModalOpen,
    onClose: onAIProgressModalClose,
    onOpen: onAIProgressModalOpen,
  } = useDisclosure();

  const { company } = useCurrentCompany();

  // Add a DR Attachment to db if it does not exist
  useEffect(() => {
    if (!attachmentDataLoading && !attachmentBox && !isPolicy) {
      upsertDRAttachment();
    }
  }, [attachmentBox, attachmentDataLoading, isPolicy]);

  useEffect(() => {
    const handleClick = () => {
      if (isGeneratingAnswers) {
        onAIProgressModalOpen();
      }
    };
    window.addEventListener('click', handleClick);
    if (!isGeneratingAnswers) {
      onAIProgressModalClose();
    }

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [isGeneratingAnswers]);

  const selectedDatapointsCount = useMemo(() => {
    return selectedNumericMetrics.length + selectedNarrativeMetrics.length;
  }, [selectedNumericMetrics, selectedNarrativeMetrics]);

  const hasUnapprovedAnswers = useMemo(() => {
    return validGeneratedAnswers.some((answer) => answer.status === null);
  }, [validGeneratedAnswers]);

  const isDisabled = useMemo(() => {
    return (
      !attachment ||
      (selectedOption === AIGenerationOptions.selected && selectedDatapointsCount === 0)
    );
  }, [selectedOption, selectedDatapointsCount, attachment]);

  const showStickyToolbar = useMemo(() => {
    return (
      isOpen &&
      !isGeneratingAnswers &&
      !!validGeneratedAnswers.length &&
      validGeneratedAnswers.some((a) => !a.status)
    );
  }, [isGeneratingAnswers, validGeneratedAnswers, isOpen]);

  const checkboxesDisabled = useMemo(() => {
    return selectedOption !== AIGenerationOptions.selected;
  }, [selectedOption]);

  const approveAllAnswers = () => {
    try {
      populateNarrativeAnswers(validGeneratedAnswers, aiNarrativeDatapoints);
      populateQuantitativeAnswers(validGeneratedAnswers, aiNumericDatapoints);
      setGeneratedAnswers((prevAnswers) => {
        return prevAnswers.map((answer) => {
          return {
            ...answer,
            status: GeneratedAnswerStatus.approved,
          };
        });
      });
    } catch (error) {
      toast({
        text: 'Failed to approve answers',
        variant: 'danger',
      });
    }
  };

  const discardAllAnswers = () => {
    setGeneratedAnswers((prevAnswers) => {
      return prevAnswers.map((answer) => {
        return {
          ...answer,
          status: GeneratedAnswerStatus.discarded,
        };
      });
    });
  };

  const initializeAbortController = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
  };

  const handleCancelRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
    setIsGeneratingAnswers(false);
    setGeneratedAnswers([]);
  };

  return (
    <AttachmentDrawer
      isOpen={isAttachmentDrawerOpen}
      onClose={onAttachmentDrawerClose}
      attachmentBox={attachmentBox}
      refetch={[GetDisclosureRequirementAttachmentDocument_]}
      singleFileSelect={true}
    >
      <Modal
        isOpen={isOpen}
        size="xxl"
        customHeader={
          <VStack w="100%" alignItems="start">
            <Typography variant="h1">Generate answers with AI</Typography>
            <Typography w="615px" variant="body">
              Use your documents to populate answers for this disclosure requirement. Select data
              points and a document that contains relevant information to get started.
            </Typography>
          </VStack>
        }
        hasFooter={false}
        onClose={() => {
          if (hasUnapprovedAnswers) {
            onApprovalWarningModalOpen();
          } else {
            setGeneratedAnswers([]);
            onClose();
          }
        }}
      >
        <HStack w="100%" alignItems="start">
          <VStack w="100%" alignItems="start" spacing="24px" overflow={'auto'}>
            <VStack w="100%" alignItems="start" spacing="16px">
              <Alert
                status="warning"
                closable={false}
                padding="4px 4px 4px 8px"
                title="AI functionality can make mistakes, please ensure to check the output with the source documentation."
                rightElement={
                  <IconButton
                    size="sm"
                    aria-label="ai-disclaimer"
                    onClick={onAIDisclaimerOpen}
                    variant="ghost"
                    icon={<InfoIcon />}
                  />
                }
              />
              <Box bg="bg.info" p="16px" w="100%" borderRadius="6px">
                <HStack w="100%" justifyContent="space-between" alignItems="center">
                  <HStack spacing="12px">
                    <AIIcon boxSize="24px" color="text.info" />
                    <HStack spacing="4px">
                      <Typography variant="body">Run AI on</Typography>
                      <AISelectorMenu
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        hasEmptyDatapoints={hasEmptyDatapoints}
                      />
                      <Typography variant="body">data points, using</Typography>
                      {isPolicy ? (
                        <PolicyAttachment
                          attachments={policyAttachments ?? []}
                          selectedPolicyFileId={selectedPolicyFileId}
                          setSelectedPolicyFileId={setSelectedPolicyFileId}
                        />
                      ) : (
                        <DisclosureRequirementAttachment
                          attachments={attachmentBox?.attachments ?? []}
                          onAttachmentDrawerOpen={onAttachmentDrawerOpen}
                          refetchQueries={[
                            GetDisclosureRequirementAttachmentDocument_,
                            GetDisclosureRequirementGroupsDocument_,
                          ]}
                        />
                      )}
                    </HStack>
                  </HStack>

                  <HStack spacing="8px">
                    <Typography variant="body">
                      {selectedDatapointsCount} data points selected
                    </Typography>
                    <AnswersGeneratorButton
                      isDisabled={isDisabled}
                      setGeneratedAnswers={setGeneratedAnswers}
                      numericMetrics={aiNumericDatapoints ?? []}
                      narrativeMetrics={aiNarrativeDatapoints ?? []}
                      abortControllerRef={abortControllerRef}
                      isGeneratingAnswers={isGeneratingAnswers}
                      setIsGeneratingAnswers={setIsGeneratingAnswers}
                      attachment={attachment}
                      initializeAbortController={initializeAbortController}
                    />
                  </HStack>
                </HStack>
              </Box>
            </VStack>

            <MetricsAITable
              allFilteredMetrics={
                isBusinessUnit ? allFilteredMetrics : filteredAggregatedQuantitativeMetics
              }
              materialStandardId={materialStandardId}
              isGroup={isGroup}
              selectedQuarter={selectedQuarter}
              setRowData={setRowData}
              esrsAssessmentProjectLeader={esrsAssessmentProjectLeader}
              rowData={rowData}
              setIsComments={setIsComments}
              companyLevelReportingUnitId={reportingUnitId}
              selectedNumericMetrics={selectedNumericMetrics}
              setSelectedNumericMetrics={setSelectedNumericMetrics}
              selectedNarrativeMetrics={selectedNarrativeMetrics}
              setSelectedNarrativeMetrics={setSelectedNarrativeMetrics}
              aggregatedMetricsAnswers={aggregatedMetricsAnswers}
              qualitativeMetrics={filteredNarrativeMetics}
              summaryQualitativeMetrics={summaryQualitativeMetrics}
              generatedAnswers={generatedAnswers}
              setGeneratedAnswers={setGeneratedAnswers}
              populateQuantitativeAnswers={populateQuantitativeAnswers}
              populateNarrativeAnswers={populateNarrativeAnswers}
              checkboxesDisabled={checkboxesDisabled}
              parentStandardId={parentStandardId}
              requiredMaterialMetrics={requiredMaterialMetrics}
              currencyConversionData={currencyConversionData}
            />
          </VStack>

          {!!rowData && (
            <Box mt="-120px" position="sticky" top="0px" h="50px">
              <MetricSidebar
                row={rowData}
                metric={rowData?.metric ?? ({} as MetricsTableData['metric'])}
                materialStandardId={materialStandardId}
                companyReportingUnit={reportingUnitId}
                selectedMetric={selectedMetric}
                esrsAssessmentProjectLeader={esrsAssessmentProjectLeader}
                setSelectedMetric={setSelectedMetric}
                onMenuOpen={onOpen}
                selectedQuarter={selectedQuarter}
                setRowData={setRowData}
                isGroup={isGroup}
                isMetricDr={true}
                isComments={isComments}
                companyCurrency={company?.currency ?? ''}
                isOpenedFromAI={true}
                generatedAnswers={generatedAnswers}
              />
            </Box>
          )}
        </HStack>
      </Modal>

      {showStickyToolbar && (
        <AIStickyToolbar
          onApprove={approveAllAnswers}
          count={validGeneratedAnswers.length}
          onDiscard={discardAllAnswers}
        />
      )}

      <AnswersApprovalWarningModal
        isOpen={isApprovalWarningModalOpen}
        onClose={onApprovalWarningModalClose}
        onConfirm={() => {
          setGeneratedAnswers([]);
          onClose();
        }}
      />

      <AIProgressModal
        isOpen={isAIProgressModalOpen}
        onClose={onAIProgressModalClose}
        setIsGenerating={setIsGeneratingAnswers}
        cancelRequest={handleCancelRequest}
      />

      {isGeneratingAnswers && <InteractionBlocker />}
    </AttachmentDrawer>
  );
};
