import { Box, HStack } from '@chakra-ui/react';
import {
  Button,
  Tag,
  TruncatableText,
  IconButton,
  FormField,
  Alert,
  EmptyState,
  Tooltip,
} from 'Atoms';
import {
  EsrsAssessmentsYearsDocument_,
  useUpsertAssessmentCompanyIntegrationMutation,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { Typography } from 'Tokens';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { RefreshIcon } from 'Tokens/Icons/Function';
import { calculateTime, CompanyIntegration, MoreScopeCompanyRow } from './MoreScopeCompany';
import { Row } from '@tanstack/react-table';
import { nhost } from 'utils/nhost';
import { useUserData } from '@nhost/react';
import { useToast } from 'utils/hooks';
import { StepComponent } from 'containers/Integrations/IntegrationsUtilComponents';
import { Loader, Table } from 'Molecules';
import { Link, useNavigate, useParams } from 'react-router-dom';

export const IntegrationTable = ({
  integration,
  assessments,
}: {
  integration?: CompanyIntegration;
  assessments?: MoreScopeCompanyRow[];
}) => {
  const [syncObject, setSyncObject] = useState<{ [key: number]: boolean }>();
  const [loading, setLoading] = useState<boolean>(false);
  const user = useUserData();
  const toast = useToast();
  const [upsertAssessmentCompanyIntegration] = useUpsertAssessmentCompanyIntegrationMutation();
  const [moreScopeYears, setMoreScopeYears] = useState<number[]>([]);
  const [missingYears, setMissingYears] = useState<number[]>([]);
  const assessmentYears = assessments?.map((a) => a.reportingYear) ?? [];
  const navigate = useNavigate();
  const { companyId } = useParams();

  const onAssessmentIntegration = async (values: {
    assessmentId: string;
    year: number;
    projectLeaderId: string;
    companyReportingUnitId: string;
    apiKey: string;
  }) => {
    const { assessmentId, year, projectLeaderId, companyReportingUnitId, apiKey } = values;
    try {
      setSyncObject({ ...(syncObject ?? {}), [year]: true });
      const res = await nhost.functions.call('esrs/integrate-morescope-data', {
        assessmentId: assessmentId,
        assessmentYear: year,
        companyReportingUnitId: companyReportingUnitId,
        userId: user?.id ?? '',
        assessmentProjectLeaderId: projectLeaderId,
        apiKey,
      });
      if (res.error) {
        setSyncObject({ ...(syncObject ?? {}), [year]: false });
        if (res.error.message === 'E1-6 not assessed') {
          toast({
            text: 'Please assess the disclosure requirement E1-6 in Climate Change first.',
            variant: 'danger',
            closable: true,
            duration: null,
          });
          return;
        } else {
          console.error(res.error);
          throw new Error('Error integrating data: ' + res.error.message);
        }
      }
      await upsertAssessmentCompanyIntegration({
        variables: {
          object: {
            esrsAssessmentId: assessmentId,
            companyIntegrationId: integration?.id,
            syncedAt: new Date(),
          },
        },
        refetchQueries: [EsrsAssessmentsYearsDocument_],
        onError: () => {
          toast({
            text: 'Error saving synced time',
            variant: 'danger',
          });
        },
      });

      setSyncObject({ ...(syncObject ?? {}), [year]: false });
      toast({
        text: 'Data integrated successfully',
      });
    } catch (error) {
      setSyncObject({ ...(syncObject ?? {}), [year]: false });
      console.error(error);
      toast({
        variant: 'danger',
        text: 'Unable to create integrate data. Please review API Key',
      });
    }
  };

  const filteredAssessments = useMemo(
    () =>
      assessments?.map((assessment) => ({
        ...assessment,
        apiKey: integration?.apiKey,
        isSyncDisabled: !moreScopeYears.includes(assessment.reportingYear),
      })),
    [assessments, moreScopeYears]
  );
  const fetchMoreScopeYears = async () => {
    if (!assessmentYears) return;

    setLoading(true);
    const res = await nhost.functions.call('esrs/get-morescope-years', {
      apiKey: integration?.apiKey,
      years: assessmentYears,
    });

    if (res.error) {
      setMoreScopeYears([]);
    } else {
      setMoreScopeYears((res.res?.data as { result: number[] })?.result);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchMoreScopeYears();
  }, [assessments, integration?.apiKey]);

  useEffect(() => {
    setMissingYears(assessmentYears?.filter((year) => !moreScopeYears.includes(year)) ?? []);
  }, [moreScopeYears, assessments]);

  const hasApiKey = useMemo(() => {
    const key = integration?.apiKey;
    return !!key && key !== '';
  }, [integration]);
  const columns = useMemo(
    () => [
      {
        header: 'Assessments',
        accessorKey: 'name',
        meta: {
          width: '57%',
        },
        cell: ({
          row,
        }: {
          row: Row<
            MoreScopeCompanyRow & {
              apiKey: string | null | undefined;
              isSyncDisabled: boolean;
            }
          >;
        }) => <Typography variant="body">{row.original.reportingYear}</Typography>,
        enableSorting: false,
      },
      {
        header: 'Data',
        accessorKey: 'year',
        enableSorting: false,
        meta: {
          width: '19%',
        },
        cell: ({
          row,
        }: {
          row: Row<
            MoreScopeCompanyRow & {
              apiKey: string | null | undefined;
              isSyncDisabled: boolean;
            }
          >;
        }) => {
          const assessment = row.original;
          const [isSyncing, setIsSyncing] = useState(false);
          return (
            <Tooltip
              label={'No matching data found in MoreScope'}
              isDisabled={!assessment.isSyncDisabled}
            >
              <HStack w="100%" justifyContent="flex-start">
                <Button
                  height={'28px'}
                  leftIcon={
                    <RefreshIcon
                      color={assessment.isSyncDisabled ? 'text.disabled' : 'text.action'}
                    />
                  }
                  justifyContent="flex-start"
                  variant="secondary"
                  onClick={async () => {
                    setIsSyncing(true);
                    await onAssessmentIntegration({
                      year: assessment.reportingYear,
                      assessmentId: assessment.id,
                      projectLeaderId: assessment.projectLeaderId ?? '',
                      companyReportingUnitId: assessment.reportingUnits?.[0]?.id,
                      apiKey: assessment.apiKey ?? '',
                    });
                    setIsSyncing(false);
                  }}
                  isLoading={isSyncing}
                  padding={'6px 8px'}
                  isDisabled={assessment.isSyncDisabled}
                >
                  <Typography color={assessment.isSyncDisabled ? 'text.disabled' : 'text.action'}>
                    Sync data
                  </Typography>
                </Button>
              </HStack>
            </Tooltip>
          );
        },
      },
      {
        header: 'Status',
        id: 'status',
        meta: {
          width: '19%',
        },
        cell: ({
          row,
        }: {
          row: Row<
            MoreScopeCompanyRow & {
              apiKey: string | null | undefined;
              isSyncDisabled: boolean;
            }
          >;
        }) => {
          const assessment = row.original;
          const syncedAt = assessment.esrsAssessmentCompanyIntegrations?.[0]?.syncedAt ?? '';
          return (
            <Tag variant={!!syncedAt ? 'info' : 'undefined'}>
              {!!syncedAt ? (
                <HStack>
                  <Typography variant="bodyStrong" color={'text.info'}>
                    Synced
                  </Typography>
                  <TruncatableText
                    color="text.info"
                    fontSize={'12px'}
                    text={`${calculateTime(syncedAt)} ago`}
                  />
                </HStack>
              ) : (
                'Manual Input'
              )}
            </Tag>
          );
        },
      },
      {
        header: '',
        id: 'link',
        cell: ({
          row,
        }: {
          row: Row<
            MoreScopeCompanyRow & {
              apiKey: string | null | undefined;
              isSyncDisabled: boolean;
            }
          >;
        }) => {
          const assessment = row.original;
          return (
            <IconButton
              icon={
                <Link to={`/${companyId}/esrs/${assessment?.id}`} target="_blank">
                  <ArrowUpRightIcon mt={'5px'} />
                </Link>
              }
              aria-label="chevron icon"
              variant={'ghost'}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <StepComponent title="Sync ESRS data per assessment" titleColor="text.hint" isEnd>
      {loading ? (
        <Loader height={'150px'} />
      ) : hasApiKey ? (
        !!assessmentYears?.length ? (
          <>
            {missingYears.length ? (
              <Alert status="warning" width={'100%'} closable={false} justifyContent="flex-start">
                <Typography fontSize={'14px'} lineHeight={'19px'}>
                  There are no matching data points in MoreScope and assessments for&nbsp;
                </Typography>
                <TruncatableText
                  fontSize={'14px'}
                  lineHeight={'19px'}
                  fontWeight={'500'}
                  text={missingYears.join(', ')}
                  width={'37%'}
                />
              </Alert>
            ) : (
              <></>
            )}
            <FormField id="years">
              <Table
                columns={columns}
                headerHeight="48px"
                headerPadding="5px 8px"
                headerBorderColor="border.decorative"
                borderTop={'1px solid'}
                borderColor={'border.decorative'}
                cellPadding="5px 8px"
                data={filteredAssessments ?? []}
              />
            </FormField>
          </>
        ) : (
          <Box h={'100%'} w={'100%'}>
            <EmptyState
              component
              title="Create your first ESRS assessment"
              description={
                'You have no ESRS assessments yet, get started by creating your first one'
              }
              callToAction={{
                text: 'Go to the ESRS module',
                variant: 'secondary',
                onClick: () => navigate(`/${companyId}/esrs`),
              }}
            />
          </Box>
        )
      ) : (
        <Alert title="Add API key to be able to sync data" status="info" closable={false} />
      )}
    </StepComponent>
  );
};
