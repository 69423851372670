import { Box, CloseButton, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { Alert, Button, IconButton, Tabs, Tag, TruncatableText } from 'Atoms';
import { SelectedMetric } from 'containers/Esrs/EsrsAssessment.hooks';
import { createGraphObject } from 'containers/Esrs/utils';
import { QuestionType_Enum_, useGetAssessmentCurrencyConversionQuery, User } from 'models';
import { LearnMoreContent, Loader, MetricLearnMoreHeader } from 'Molecules';
import { TOP_MENU_HEIGHT } from 'containers/Navigation/pieces';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DesignIllustration, Typography } from 'Tokens';
import { DocumentIcon } from 'Tokens/Icons/Data';
import { ArrowLeftIcon, ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { AIIcon, MaximizeIcon } from 'Tokens/Icons/Function';
import { HelpIcon } from 'Tokens/Icons/Status';
import { QuartersEnums, QuartersObject, TimePeriods, TimePeriodsEnums } from '../../Requirement';
import { AggregatedQualitativeAnswers } from '../AggregatedMetrics';
import { MetricMenu } from '../InputTable/MetricMenu';
import {
  areArraysOfObjectsEqual,
  isFrequencyYearly,
  MetricsTableData,
  useAggregatedRowValue,
} from '../MetricAnswers.hooks';
import { GraphCard } from '../MetricsGraphModal';
import { MetricComments } from './MetricComments';
import { MetricOverview } from './MetricOverview';
import { MetricSource } from './MetricSource';
import { getMetricChartUrl } from '../Metrics.utils';
import { GeneratedAnswer } from '../MetricAI';
import { AnswersApprovalWarningModal } from '../MetricAI/AIUtils';
import { useGetDatapointValues } from '../MetricsTable/MetricsTableComponents/InputFields/QuantitativeInputs';
import { GeneratedSummaryStatus } from '../Metrics/ProcessMetrics/MetricsHelperFunctions';

type PartialYearObject = QuartersObject & { Year?: number };

enum MetricSidebarTabs {
  About = 'about',
  Source = 'source',
  Comments = 'comments',
}

export const MetricSidebar = ({
  row,
  metric,
  materialStandardId,
  companyReportingUnit,
  selectedMetric,
  setSelectedMetric,
  onMenuOpen,
  selectedQuarter,
  esrsAssessmentProjectLeader,
  setRowData,
  isGroup,
  isMetricDr,
  isComments,
  companyCurrency,
  isOpenedFromAI = false,
  generatedAnswers,
  generatedSummaryStatus,
  setGeneratedSummaryStatus,
}: {
  row?: MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] };
  metric: MetricsTableData['metric'];
  materialStandardId: string;
  companyReportingUnit?: string;
  selectedMetric: SelectedMetric | undefined;
  setSelectedMetric: (param: SelectedMetric) => void;
  onMenuOpen: () => void;
  selectedQuarter: TimePeriods;
  esrsAssessmentProjectLeader?: Partial<User>;
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  isGroup: boolean;
  isMetricDr: boolean;
  isComments?: boolean;
  companyCurrency: string;
  isOpenedFromAI?: boolean;
  generatedAnswers?: GeneratedAnswer[];
  generatedSummaryStatus?: GeneratedSummaryStatus;
  setGeneratedSummaryStatus?: React.Dispatch<React.SetStateAction<GeneratedSummaryStatus>>;
}) => {
  const {
    companyId = '',
    esrsAssessmentId = '',
    standardRef = '',
    disclosureRequirementRef = '',
  } = useParams();
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');
  const [selectedTab, setSelectedTab] = useState<MetricSidebarTabs | undefined>(
    MetricSidebarTabs.Source
  );
  const [viewLearnMore, setViewLearnMore] = useState(false);
  const user = useUserData();
  const { data: currencyConversionData } = useGetAssessmentCurrencyConversionQuery({
    fetchPolicy: 'network-only',
    variables: {
      assessmentId: esrsAssessmentId,
    },
    skip: !isGroup,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const { answer, dataPointsPerQuarter, dataPointPerYear, isAggregated, loading } =
    useGetDatapointValues(row ?? { metric: metric }, companyReportingUnit);

  const isYearly = useMemo(() => isFrequencyYearly(row?.metric ?? metric), [row, metric]);
  const isParentMetric = useMemo(() => !!row?.subRows?.length, [row]);
  const isLongText = useMemo(() => metric.metricType === QuestionType_Enum_.LongText_, [metric]);

  const { result: aggregatedValues } = useAggregatedRowValue(
    row ?? { metric: metric },
    isYearly,
    companyReportingUnit
  );

  const {
    isOpen: isApprovalWarningModalOpen,
    onOpen: onApprovalWarningModalOpen,
    onClose: onApprovalWarningModalClose,
  } = useDisclosure();

  const datapoint = useMemo(
    () =>
      isParentMetric || isLongText || isYearly
        ? dataPointPerYear
        : dataPointsPerQuarter.find((dp) => dp.field === selectedQuarter)?.value,
    [isYearly, dataPointPerYear, dataPointsPerQuarter, selectedQuarter, isLongText, isParentMetric]
  );

  const hasGraph = useMemo(() => metric.metricType === QuestionType_Enum_.Decimal_, [metric]);

  useEffect(() => {
    if (openDrawer) {
      setSelectedTab(MetricSidebarTabs.Comments);
    } else if (searchParams.get('source') === 'true') {
      setSelectedTab(MetricSidebarTabs.Source);
    }
  }, [openDrawer, searchParams]);

  useEffect(() => {
    if (isComments) setSelectedTab(MetricSidebarTabs.Comments);
  }, [isComments]);

  const getValuePerQuarter = (quarter: string) =>
    Number(dataPointsPerQuarter.find((dp) => dp.field === quarter)?.value?.value ?? 0);

  const graphData: PartialYearObject = useMemo(
    () =>
      createGraphObject(
        isYearly,
        getValuePerQuarter(QuartersEnums.q1),
        getValuePerQuarter(QuartersEnums.q2),
        getValuePerQuarter(QuartersEnums.q3),
        getValuePerQuarter(QuartersEnums.q4),
        Number(dataPointPerYear?.value) ?? 0
      ),
    [dataPointsPerQuarter, isYearly]
  );

  const aggregatedGraphData: PartialYearObject = useMemo(
    () =>
      createGraphObject(
        isYearly,
        aggregatedValues[QuartersEnums.q1],
        aggregatedValues[QuartersEnums.q2],
        aggregatedValues[QuartersEnums.q3],
        aggregatedValues[QuartersEnums.q4],
        aggregatedValues.Year
      ),
    [aggregatedValues, isYearly]
  );

  const graph = useMemo(
    () => (isAggregated ? aggregatedGraphData : graphData),
    [aggregatedGraphData, graphData, isAggregated]
  );

  const subTitle = useMemo(() => {
    const isTag = !!row?.tagName;
    const isParent = !!row?.parentMetric;
    if (isTag) return row.metric.shortTitle ?? row.metric.title;
    if (isParent) return row?.parentMetric?.title;
  }, [row]);

  const mainTitle = useMemo(() => {
    if (!row) return metric.shortTitle ?? metric.title;
    return !!row?.tagName ? row?.tagName : (row?.metric.shortTitle ?? row?.metric.title);
  }, [row]);

  const hideComments = useMemo(() => {
    const quarterlyMetricInYearView =
      !isYearly && selectedQuarter === TimePeriodsEnums.year && !isParentMetric && !isLongText;
    const groupedTagsRow = !!(row?.tagName && row?.tagType);
    if (groupedTagsRow || quarterlyMetricInYearView) {
      return true;
    }
    return false;
  }, [isYearly, selectedQuarter, isParentMetric, isLongText, row]);

  const commentsCount = useMemo(
    () => datapoint?.thread?.comments_aggregate.aggregate?.count,
    [datapoint]
  );

  const allSubsidiariesAnswered = useMemo(() => {
    return row?.sourceData?.subsidiaries?.every(
      (subsidiary) =>
        !!subsidiary.answer?.datapoints.find((dp) => dp.timeframe === TimePeriodsEnums.year)?.value
    );
  }, [row?.sourceData]);

  const showAvailableAISummary = useMemo(() => {
    return (
      row?.metric.metricType === QuestionType_Enum_.LongText_ && allSubsidiariesAnswered && isGroup
    );
  }, [allSubsidiariesAnswered, isGroup, row]);

  const generatedAnswer = useMemo(() => {
    return generatedAnswers?.find(
      (a) => a.metricRef === row?.metric.reference && areArraysOfObjectsEqual(a.tags, row?.tags)
    );
  }, [generatedAnswers, row]);

  useEffect(() => {
    setSelectedMetric({
      description: metric.description ?? '',
      reference: metric.reference,
      tags: metric.materialMetrics
        .find((mm) => mm.materialStandardId === materialStandardId)
        ?.materialMetricTags?.map((tag) => ({
          type: tag.tagType,
        })),
      childrenMetrics: metric.childrenMetrics,
    });
  }, [metric]);

  const tabs = useMemo(() => {
    return (
      [
        {
          title: (
            <HStack spacing="8px">
              <Typography variant="bodyStrong" color="currentColor">
                Source
              </Typography>
              {showAvailableAISummary && (
                <Tag variant="info" size="xs">
                  <AIIcon color="text.info" />
                </Tag>
              )}
            </HStack>
          ),
          id: MetricSidebarTabs.Source,
          content: (
            <MetricSource
              answerId={answer?.id}
              metric={row?.metric ?? metric}
              companyReportingUnit={companyReportingUnit}
              answerData={row?.sourceData}
              isGroup={isGroup}
              username={user?.displayName ?? ''}
              row={row}
              selectedQuarter={selectedQuarter}
              esrsAssessmentProjectLeader={esrsAssessmentProjectLeader}
              datapoint={datapoint}
              isAggregated={isAggregated}
              generatedAnswer={generatedAnswer}
              generatedSummaryStatus={generatedSummaryStatus}
              currencyConversionData={currencyConversionData?.esrs_AssessmentCurrencyConversion}
              setGeneratedSummaryStatus={setGeneratedSummaryStatus}
            />
          ),
        },
        {
          title: 'About',
          id: MetricSidebarTabs.About,
          content: (
            <MetricOverview
              row={row}
              metric={metric}
              materialStandardId={materialStandardId}
              companyCurrency={companyCurrency}
            />
          ),
        },
        {
          title: (
            <HStack spacing="6px">
              <Typography variant="bodyStrong" color="currentColor">
                Comments
              </Typography>
              {commentsCount && (
                <Tag variant="info" size="xs">
                  {commentsCount}
                </Tag>
              )}
            </HStack>
          ),
          id: MetricSidebarTabs.Comments,
          content: (
            <MetricComments
              datapoint={datapoint}
              companyReportingUnit={companyReportingUnit}
              selectedQuarter={selectedQuarter}
              isYearly={isYearly || isParentMetric || isLongText}
              row={row ?? { metric: metric, tags: [] }}
            />
          ),
        },
      ].filter((tab) => (hideComments ? tab.id !== MetricSidebarTabs.Comments : true)) ?? []
    );
  }, [
    row,
    metric,
    selectedQuarter,
    companyReportingUnit,
    hideComments,
    isYearly,
    isParentMetric,
    selectedTab,
    datapoint,
    commentsCount,
    isLongText,
    materialStandardId,
    generatedAnswer,
    isAggregated,
    esrsAssessmentProjectLeader,
    answer,
    isGroup,
    user,
    showAvailableAISummary,
    generatedSummaryStatus,
    currencyConversionData,
  ]);

  const showOptOut = useMemo(
    () =>
      metric.additionalTypes.some(
        (type) =>
          type.additionalType.reference === 'Voluntary' ||
          type.additionalType.reference === 'Conditional'
      ),
    [metric]
  );

  return (
    <>
      <VStack
        minWidth="352px"
        maxWidth="352px"
        height={`calc(100vh - ${TOP_MENU_HEIGHT})`}
        pb="24px"
        mt={isOpenedFromAI ? 'unset' : '-16px'}
        mr={isOpenedFromAI ? '-16px' : 'unset'}
        mb={isOpenedFromAI ? '-16px' : 'unset'}
        spacing="0px"
        alignItems="flex-start"
        borderLeft="1px solid"
        borderColor="border.decorative"
        overflowY="auto"
        overflowX="hidden"
        position={isOpenedFromAI ? 'sticky' : 'absolute'}
        top="0px"
        right={isOpenedFromAI ? 'unset' : '-16px'}
        bg="bg.default"
        boxSizing="border-box"
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <HStack padding="8px 12px 8px 20px" justifyContent="space-between" w="100%">
              {viewLearnMore ? (
                <HStack spacing="6px">
                  <IconButton
                    variant="ghost"
                    icon={<ArrowLeftIcon />}
                    onClick={() => setViewLearnMore(false)}
                    aria-label={''}
                    size="sm"
                  />
                  <Typography color="text.muted" variant="bodyStrong" py="8px">
                    Help center
                  </Typography>
                </HStack>
              ) : (
                <Typography color="text.hint" variant="bodyStrong" py="8px">
                  Data point
                </Typography>
              )}
              <CloseButton
                zIndex={2}
                onClick={() => {
                  searchParams.delete('sidebar');
                  searchParams.delete('source');
                  setSearchParams(searchParams);
                  if (openDrawer && !!hash) {
                    navigate(pathname.split('?')[0]);
                  }
                  if (
                    generatedSummaryStatus?.isApproved === false &&
                    generatedSummaryStatus?.metricRef === row?.metric.reference
                  ) {
                    onApprovalWarningModalOpen();
                  } else {
                    setRowData(undefined);
                  }
                }}
              />
            </HStack>
            {!viewLearnMore ? (
              <>
                {hasGraph ? (
                  <Box padding="16px 8px" w="100%" h="fit-content" minH="156px" position="relative">
                    <GraphCard
                      data={graph}
                      height={132}
                      margins={{
                        top: 5,
                        right: 8,
                        left: -20,
                        bottom: 0,
                      }}
                      showXAxis={false}
                    />
                    <IconButton
                      aria-label="maximize"
                      icon={<MaximizeIcon color="text.action" />}
                      size={'sm'}
                      onClick={() => {
                        const url = getMetricChartUrl({
                          row: row ?? { metric },
                          metricRef: metric.reference,
                          standardId: materialStandardId,
                          companyId,
                          esrsAssessmentId,
                          standardRef,
                          disclosureRequirementRef,
                        });
                        navigate(url);
                      }}
                      position="absolute"
                      right="12px"
                      top="4px"
                    />
                  </Box>
                ) : (
                  <Box h="100px">
                    <DesignIllustration
                      boxSize="244px 145px"
                      w="224px"
                      position="absolute"
                      top={0}
                      zIndex={1}
                      right={0}
                    />
                  </Box>
                )}
                <VStack alignItems="start" width="100%" spacing="16px" p="0px 20px 12px">
                  {answer?.hasOptedOut && (
                    <VStack width={'100%'}>
                      <Alert
                        status="info"
                        closable={false}
                        title="This metric is marked as irrelevant"
                      />
                    </VStack>
                  )}
                  <VStack justifyContent="end" width="100%" spacing="4px" height="44px">
                    {subTitle && (
                      <TruncatableText variant="detail" color="text.detail" text={subTitle} />
                    )}
                    <TruncatableText variant="h3" text={mainTitle} />
                  </VStack>
                  <HStack width="100%" spacing="6px">
                    <Button
                      variant="secondary"
                      justifyContent="space-between"
                      rightIcon={<ArrowUpRightIcon color="text.action" opacity={0.4} />}
                      flex={1}
                      onClick={() =>
                        window.open(
                          `/${companyId}/esrs/${esrsAssessmentId}/report?standardRef=${standardRef}&drRef=${disclosureRequirementRef}&metricRef=${metric.reference}`
                        )
                      }
                    >
                      <HStack>
                        <DocumentIcon color="text.action" />
                        <Typography variant="bodyStrong" color="text.action">
                          Open in report
                        </Typography>
                      </HStack>
                    </Button>
                    <IconButton
                      aria-label="learn more"
                      icon={<HelpIcon color="text.action" />}
                      size="md"
                      onClick={() => {
                        setViewLearnMore(true);
                      }}
                    />
                    {showOptOut && (
                      <MetricMenu
                        metric={metric}
                        onOpen={onMenuOpen}
                        setSelectedMetric={setSelectedMetric}
                        companyReportingUnit={companyReportingUnit}
                      />
                    )}
                  </HStack>
                </VStack>
                <Tabs
                  currentTab={selectedTab}
                  onChange={(tabId) => {
                    setSelectedTab(tabId as MetricSidebarTabs);
                  }}
                  padList="20px"
                  items={tabs}
                />
              </>
            ) : (
              <VStack alignItems="first" p="16px">
                <LearnMoreContent
                  header={selectedMetric?.isDisclosure ? selectedMetric.title : undefined}
                  description={selectedMetric?.description}
                  customHeader={
                    !selectedMetric?.isDisclosure ? (
                      <MetricLearnMoreHeader
                        metricRef={selectedMetric?.reference ?? ''}
                        tags={selectedMetric?.tags ?? []}
                        titleVariant="h3"
                      />
                    ) : undefined
                  }
                />
              </VStack>
            )}
          </>
        )}
      </VStack>

      <AnswersApprovalWarningModal
        isOpen={isApprovalWarningModalOpen}
        onClose={onApprovalWarningModalClose}
        onConfirm={() => {
          setRowData(undefined);
        }}
      />
    </>
  );
};
