import { convertInchesToTwip, Paragraph } from 'docx';
import { TextStyle } from '../reportThemes';
import { SideMenuStructure } from '../../Report.types';
import { toRomanNumeral } from '../../ReportUtils';

export const getTOCStructure = (structure: SideMenuStructure) => {
  const filteredStructure = structure.filter((s) => s[0][0].reference !== 'general');
  const tableOfContentsData = filteredStructure.flatMap((category, index) => {
    const standards = category.slice(1).flatMap((standard, index) => {
      // add placeholder for taxonomy DR
      return [
        ...(category[0][0].title === 'Environment information' && index === 0
          ? [
              {
                key: '',
                reference: 'placeholder',
                title:
                  'Disclosures pursuant to Article 8 of Regulation (EU) 2020/852 (Taxonomy Regulation)',
              },
            ]
          : []),
        ...standard,
      ].map((item, i) => {
        if (
          i === 0 ||
          (category[0][0].title === 'Environment information' && index === 0 && i === 1)
        )
          return { title: item.title, level: 2 };

        return { title: item.title, level: 3 };
      });
    });
    return [
      { title: `${toRomanNumeral(index + 1)}. ${category[0][0].title}`, level: 1 },
      ...standards,
    ];
  });
  return tableOfContentsData;
};

export const tableOfContents = (structure: SideMenuStructure) => {
  const tableOfContentsData = getTOCStructure(structure);
  return [
    new Paragraph({
      text: 'Table of Contents',
      style: TextStyle.h1,
      spacing: { after: 300 },
    }),
    ...tableOfContentsData.map(({ title, level }) => {
      const indentation = level * 0.25;
      return new Paragraph({
        text: title,
        style: level === 1 ? TextStyle.h2 : level === 2 ? TextStyle.h3 : TextStyle.body,
        spacing: { after: 200 },
        indent: { left: convertInchesToTwip(indentation) },
      });
    }),
  ];
};
