import { Paragraph, Table, TableCell, TableRow, VerticalAlign, WidthType } from 'docx';
import { EsrsReportMetric } from '../../Report.types';
import { TextStyle, tableBorders } from '../reportThemes';
import { MaterialMetricTagFieldsFragment_ } from 'models';

type AnswersData = {
  rate: string;
  metrics: {
    [metric: string]: string;
  };
}[];

type MetricsWithTags = (EsrsReportMetric['tableData'] & {
  materialMetricTags: MaterialMetricTagFieldsFragment_[];
})[];

const HEADER_TITLES = [
  'Coverage Rate',
  'Employees - EEA',
  'Employees - Non-EEA',
  'Work representation (EEA only)',
];

const METRIC_KEYS = [
  'percentageBargainingEEA',
  'percentageBargainingNonEEA',
  'percentageRepresentativesEEA',
];

const COVERAGE_RANGES = [
  { min: 0, max: 20, label: '0-19%' },
  { min: 20, max: 40, label: '20-39%' },
  { min: 40, max: 60, label: '40-59%' },
  { min: 60, max: 80, label: '60-79%' },
  { min: 80, max: 101, label: '80-100%' },
];

const findRowByTagName = (row: EsrsReportMetric['tableData'] | undefined, tagValue: string) => {
  return row?.subRows?.find((subRow) => subRow.tagName === tagValue);
};

const concatText = (text: string, country: string) => {
  return !!text?.length ? `${text} - ${country}` : country;
};

const assignToCoverageRate = (
  coverageRateObject: Record<string, Record<string, string>>,
  answer: number,
  metricRef: string,
  country: string
) => {
  COVERAGE_RANGES.forEach(({ min, max, label }) => {
    if (answer >= min && answer < max) {
      coverageRateObject[label][metricRef] = concatText(
        coverageRateObject[label][metricRef],
        country
      );
    }
  });
};

export const processCountriesMetricsData = (metrics: MetricsWithTags) => {
  const coverageRateObject: { [key: string]: { [key: string]: string } } = Object.fromEntries(
    COVERAGE_RANGES.map(({ label }) => [label, {}])
  );

  metrics.forEach((metric) => {
    const metricRef = metric.metric.reference;

    const countries =
      metric.materialMetricTags
        .find((tag) => ['EEA Countries', 'Non-EEA countries'].includes(tag.tagType))
        ?.materialTagValues.flatMap((value) => value.tagValue) ?? [];

    countries.forEach((country) => {
      const answer = findRowByTagName(metric, country)?.result?.Year;

      if (answer !== undefined && answer !== null) {
        assignToCoverageRate(coverageRateObject, answer, metricRef, country);
      }
    });
  });
  return Object.entries(coverageRateObject).map(([rate, metrics]) => ({ rate, metrics }));
};

const createHeaderRow = () => {
  const topHeaderRow = new TableRow({
    tableHeader: true,
    children: [
      new TableCell({
        verticalAlign: VerticalAlign.CENTER,
        children: [new Paragraph({ text: '', style: TextStyle.tableTitle })],
      }),
      new TableCell({
        verticalAlign: VerticalAlign.CENTER,
        columnSpan: 2,
        children: [
          new Paragraph({
            text: 'Collective Bargaining Coverage',
            style: TextStyle.tableTitle,
          }),
        ],
      }),
      new TableCell({
        verticalAlign: VerticalAlign.CENTER,
        children: [
          new Paragraph({
            text: 'Social Dialogue',
            style: TextStyle.tableTitle,
          }),
        ],
      }),
    ],
  });

  const bottomHeaderRow = new TableRow({
    tableHeader: true,
    children: HEADER_TITLES.map(
      (header) =>
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          width: { size: '25%', type: WidthType.PERCENTAGE },
          children: [new Paragraph({ text: header, style: TextStyle.tableTitle })],
        })
    ),
  });

  return [topHeaderRow, bottomHeaderRow];
};

const createCountriesTableContentRows = (dataRows: AnswersData) =>
  dataRows.map(({ rate, metrics }) => {
    return new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [new Paragraph({ text: rate, style: TextStyle.body })],
        }),
        ...METRIC_KEYS.map(
          (key) =>
            new TableCell({
              verticalAlign: VerticalAlign.TOP,
              children: [
                new Paragraph({
                  text: String(metrics[key] ?? ''),
                  style: TextStyle.body,
                }),
              ],
            })
        ),
      ],
    });
  });

export const createS18CountriesCoverageTable = (metrics: EsrsReportMetric[]) => {
  const adjustedMetrics = metrics.map((metric) => ({
    ...metric.tableData,
    materialMetricTags: metric.metric.materialMetricTags,
  }));
  const dataRows = processCountriesMetricsData(adjustedMetrics);
  const tableContentRows = createCountriesTableContentRows(dataRows);

  return new Table({
    columnWidths: [2500, 2500, 2500, 2500],
    margins: { top: 60, bottom: 60, right: 60, left: 60 },
    width: { size: '100%', type: WidthType.PERCENTAGE },
    borders: tableBorders,
    rows: [...createHeaderRow(), ...tableContentRows],
  });
};
