import { ReportMetricTable } from 'containers/Esrs/pieces/Report/ReportComponents/ReportMetricTable';
import { Typography } from 'Tokens';
import { GetSingleEsrsMetricAnswerQuery_, QuestionType_Enum_, ShortUser } from 'models';
import { AggregatedMetricsTableData } from '../../../AggregatedMetrics';
import { Box, VStack } from '@chakra-ui/react';
import { Dispatch, MouseEvent, SetStateAction, useMemo, useState } from 'react';
import {
  MappedMdrm,
  MDRM_QUESTIONS,
  MdrmTypeEnum,
  MetricsTableData,
  OnMdrmAnswerChangeType,
  useMdrmAnswers,
} from '../../..';
import { AnswerPopover } from './AnswerPopover';
import { useGetDatapointValues } from '../../../MetricsTable/MetricsTableComponents/InputFields/QuantitativeInputs';
import { AssessableMetrics } from '../../../Metrics/ProcessMetrics/MetricsHelperFunctions';
import { SelectedMetric, TimePeriodsEnums } from 'containers/Esrs';
import { MdrmTextInput } from './MDRMTextInput';
import { MdrmPopover } from './MDRMPopover';
import React from 'react';

const MdrmEditableInput = ({
  mdrm,
  selectedMdrm,
  setSelectedMdrm,
  projectLeader,
  onMdrmAnswerChange,
}: {
  mdrm: MappedMdrm;
  selectedMdrm?: string;
  setSelectedMdrm?: (mdrm: string) => void;
  projectLeader?: ShortUser;
  onMdrmAnswerChange: OnMdrmAnswerChangeType;
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  return (
    <Box w="100%">
      <MdrmPopover
        question={mdrm.question}
        owner={mdrm.answer?.owner ?? projectLeader}
        isVisible={isHovered}
      />
      <MdrmTextInput
        mdrmId={mdrm.id}
        onMdrmAnswerChange={onMdrmAnswerChange}
        answer={mdrm.answer}
        placeholder={mdrm.answer?.value ? undefined : `Write your answer: MDR-M (${mdrm.question})`}
        isSelected={selectedMdrm === mdrm.id}
        onFocus={() => setSelectedMdrm?.(mdrm.id)}
        setIsHovered={setIsHovered}
      />
    </Box>
  );
};

export const ClickableReportAnswerComponent = ({
  children,
  metric,
  aggregatedMetric,
  projectLeader,
  padding = 24,
  rowData,
  selectedMetric,
  setSelectedMetric,
  setRowData,
  answer,
}: {
  children: React.ReactNode;
  metric: AssessableMetrics[number];
  aggregatedMetric?: AggregatedMetricsTableData;
  projectLeader?: ShortUser;
  rowData?: MetricsTableData;
  padding?: number;
  selectedMetric?: SelectedMetric | null;
  setSelectedMetric: (metric: SelectedMetric) => void;
  setRowData: Dispatch<SetStateAction<MetricsTableData | undefined>>;
  answer?: GetSingleEsrsMetricAnswerQuery_['esrs_Answer'][number];
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const isSelected = useMemo(
    () => selectedMetric?.reference === metric.reference,
    [selectedMetric, metric]
  );

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    setSelectedMetric(metric as SelectedMetric);
    if (rowData === aggregatedMetric) setRowData(undefined);
    else setRowData(aggregatedMetric ?? { metric });
  };

  return (
    <VStack
      alignItems="stretch"
      width={`calc(100% + ${padding}px)`}
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      cursor="pointer"
      ml={`-${padding}px`}
      paddingLeft={`${padding}px`}
      _before={{
        content: `''`,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        opacity: isHovered || isSelected ? 1 : 0,
        width: '2px',
        backgroundColor: 'border.selected.accent',
        transition: 'opacity 0.3s',
      }}
      boxSizing="border-box"
      borderColor="border.selected.accent"
      paddingY="4px"
      bg={isSelected ? 'bg.selected' : ''}
      transition="all 0.4s"
      onClick={handleClick}
    >
      <AnswerPopover
        metric={metric}
        owner={answer?.datapoints?.[0]?.owner ?? projectLeader}
        isVisible={isHovered}
        padding={24}
      />
      {children}
    </VStack>
  );
};

export const ClickableReportAnswer = ({
  metric,
  aggregatedMetric,
  companyLevelReportingUnitId,
  currency,
  materialStandardId,
  projectLeader,
  padding = 24,
  rowData,
  selectedMetric,
  setSelectedMetric,
  setRowData,
  selectedMdrm,
  setSelectedMdrm,
}: {
  metric: AssessableMetrics[number];
  aggregatedMetric?: AggregatedMetricsTableData;
  companyLevelReportingUnitId?: string;
  currency?: string;
  projectLeader?: ShortUser;
  rowData?: MetricsTableData;
  materialStandardId: string;
  padding?: number;
  selectedMetric?: SelectedMetric | null;
  setSelectedMetric: (metric: SelectedMetric) => void;
  setRowData: Dispatch<SetStateAction<MetricsTableData | undefined>>;
  selectedMdrm?: string;
  setSelectedMdrm?: (mdrm: string) => void;
}) => {
  const { answer, dataPointPerYear } = useGetDatapointValues(
    {
      metric,
    },
    companyLevelReportingUnitId ?? ''
  );
  const isDecimal = useMemo(() => metric.metricType === QuestionType_Enum_.Decimal_, [metric]);

  const { answers: mdrmAnswers, onMdrmAnswerChange } = useMdrmAnswers({
    metricRef: metric.reference,
    row: aggregatedMetric ?? { metric },
    datapointId: dataPointPerYear?.id,
    companyReportingUnitId: companyLevelReportingUnitId,
    timeframe: TimePeriodsEnums.year,
  });

  const isEntitySpecific = useMemo(
    () =>
      metric.additionalTypes.some((type) => {
        return type.additionalType.reference === 'Entity-specific';
      }),
    [metric]
  );

  const mappedMdrms = useMemo(() => {
    return MDRM_QUESTIONS.filter((q) => {
      if (q.id === MdrmTypeEnum.entitySpecific) {
        return isEntitySpecific;
      }
      return true;
    }).map((q) => {
      return {
        id: q.id,
        question: q.question,
        answer: mdrmAnswers[q.id],
      };
    });
  }, [mdrmAnswers, isEntitySpecific]);

  return (
    <ClickableReportAnswerComponent
      metric={metric}
      aggregatedMetric={aggregatedMetric}
      projectLeader={projectLeader}
      rowData={rowData}
      selectedMetric={selectedMetric}
      setSelectedMetric={setSelectedMetric}
      setRowData={setRowData}
      answer={answer}
      padding={padding}
    >
      {isDecimal && <Typography variant="bodyStrong">{metric.title}</Typography>}
      {aggregatedMetric && (
        <VStack spacing="16px">
          <ReportMetricTable
            metrics={[aggregatedMetric]}
            currency={currency ?? ''}
            standardId={materialStandardId}
          />
          {dataPointPerYear?.isMdrmDisclosed && (
            <VStack w="100%" spacing="12px">
              {mappedMdrms.map((mdrm) => (
                <MdrmEditableInput
                  key={mdrm.answer?.id}
                  mdrm={mdrm}
                  onMdrmAnswerChange={onMdrmAnswerChange}
                  projectLeader={projectLeader}
                  selectedMdrm={selectedMdrm}
                  setSelectedMdrm={setSelectedMdrm}
                />
              ))}
            </VStack>
          )}
        </VStack>
      )}
    </ClickableReportAnswerComponent>
  );
};
