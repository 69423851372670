import { HStack, VStack } from '@chakra-ui/react';
import { Infobox, Button } from 'Atoms';
import { NavigateFunction } from 'react-router-dom';
import { Typography } from 'Tokens';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { WarningIcon } from 'Tokens/Icons/Status';

export const MetricConfigurationWarning = ({
  navigate,
  companyId,
  esrsAssessmentId,
  standardRef,
  drRef,
  hasNonAggregatableMetrics,
  someTagsNotConfigured,
}: {
  navigate: NavigateFunction;
  companyId?: string;
  esrsAssessmentId?: string;
  standardRef?: string;
  drRef?: string;
  hasNonAggregatableMetrics: boolean;
  someTagsNotConfigured: boolean;
}) => {
  return (
    <Infobox
      status="warning"
      closable={false}
      withIcon={false}
      w="100%"
      minH="0px"
      p="0px"
      containerStyles={{ alignItems: 'center', pl: '8px' }}
      title={
        hasNonAggregatableMetrics ? (
          <HStack gap="0px">
            <VStack justifyContent="flex-start" mr="8px">
              <WarningIcon color="text.warning" />
            </VStack>
            <Typography fontSize="14px" fontWeight="500" variant="body">
              Some data points are {someTagsNotConfigured ? 'misconfigured or ' : ''} advised to be
              reviewed.
            </Typography>
          </HStack>
        ) : (
          <HStack gap="0px">
            <VStack justifyContent="flex-start" mr="8px">
              <WarningIcon color="text.warning" />
            </VStack>
            <Typography fontSize="14px" fontWeight="500" variant="body" mr="4px">
              Some data points are misconfigured.
            </Typography>
            <Typography fontSize="14px" fontWeight="400" variant="body">
              Complete the required configurations in the DR setup.
            </Typography>
          </HStack>
        )
      }
      rightElement={
        <Button
          variant="ghost"
          size="md"
          rightIcon={<ArrowUpRightIcon />}
          onClick={() => {
            navigate(
              `/${companyId}/esrs/${esrsAssessmentId}/settings/data-collection/${standardRef}/disclosure-requirement/${drRef}`
            );
          }}
        >
          {hasNonAggregatableMetrics
            ? someTagsNotConfigured
              ? 'Complete setup'
              : 'Review setup'
            : 'Complete setup'}
        </Button>
      }
    />
  );
};
