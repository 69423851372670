import { Loader, Modal, RadioCardGroup } from 'Molecules';
import {
  EsrsAssessmentTargetsDocument_,
  GetDisclosureRequirementGroupsDocument_,
  GetParentTargetsDrQuery_,
  GetTargetQuery_,
  GetTargetsDrDocument_,
  GetTargetsDrQuery_,
  GetTargetsSubsidiariesQuery_,
  useDeleteTargetMutation,
  useGetRequiredTargetMaterialMetricsQuery,
  useGetSetTargetMetricsQuery,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { MetricsSection } from './MetricsSection';
import { Box, VStack, useDisclosure } from '@chakra-ui/react';
import { Button, Infobox, Input } from 'Atoms';
import { Controller, useForm } from 'react-hook-form';
import { UserAssigner } from 'Organisms';
import { useUserData } from '@nhost/react';
import { Typography } from 'Tokens';
import { useAddTargetModal } from './AddTargetModal.hooks';
import { DeleteIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

type FormFields = {
  name: string;
  ownerId: string;
  isBottomUp: boolean;
};

const intensityMetrics = [
  'energyIntensityHighImpact',
  'LBGHGIntensityPerRevenue',
  'MBGHGIntensityPerRevenue',
  'ghgIntensityEmployeesLocation',
  'ghgIntensityEmployeesMarket',
  'waterIntensity',
];

const IntensityMetricWarning = () => (
  <Infobox
    status="neutral"
    description={
      <Box>
        <Typography color="text.muted" variant="body" mb="8px">
          This metric can be set only on this company level (“top-down” approach), and can not be
          aggregated from subsidiaries (“bottom-up” approach).
        </Typography>
        <Typography color="text.muted" variant="body">
          This is because the metric is calculated by dividing one metric (numerator) on another
          (denominator). To aggregate the target bottom-up using subsidiaries’ input would therefore
          require detailed forecasts from each subsidiary for both the numerator and the denominator
          values which the system is not set up for and would in many cases require very detailed
          input from subsidiaries. Please contact your customer success manager if you have any
          questions.
        </Typography>
      </Box>
    }
    closable={false}
    withIcon={false}
  />
);

export const AddTargetModal = ({
  isOpen,
  onClose,
  materialStandardId,
  parentStandardId,
  standardRef,
  disclosureRequirementRef,
  subsidiaries,
  hasAccessToAllSubs,
  parentTargets,
  targets,
  isGroup,
  targetToEdit,
  companyName,
}: {
  isOpen: boolean;
  onClose: () => void;
  materialStandardId: string;
  parentStandardId?: string;
  standardRef: string;
  disclosureRequirementRef: string;
  subsidiaries: NonNullable<GetTargetsSubsidiariesQuery_['esrs']>['subsidiaries'] | undefined;
  parentTargets?: GetParentTargetsDrQuery_['esrs_Target'] | undefined;
  targets?: NonNullable<GetTargetsDrQuery_['DisclosureRequirement_by_pk']>['targets'] | undefined;
  isGroup: boolean;
  companyName?: string;
  hasAccessToAllSubs: boolean;
  targetToEdit?: {
    id: string;
    metricRef: string;
    metricTitle: string;
    ownerId: string;
    name: string;
    isBottomUp?: boolean;
    parentTargetId?: string | null;
    parentName?: string;
    keyResults?: NonNullable<GetTargetQuery_['target']>['keyResults'];
  };
}) => {
  const [step, setStep] = useState(!!targetToEdit ? 2 : 1);
  const [selectedMetric, setSelectedMetric] = useState<{
    title: string;
    reference: string;
    calculation?: string | null;
  }>();
  const user = useUserData();
  const createTarget = useAddTargetModal();
  const [deleteTarget] = useDeleteTargetMutation();
  const navigate = useNavigate();
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = useForm<FormFields>();

  const { data: requiredTargetMetrics } = useGetRequiredTargetMaterialMetricsQuery({
    variables: {
      standardRef,
      materialStandardId,
      parentStandardId: parentStandardId || materialStandardId,
    },
    skip: !standardRef || !materialStandardId,
  });

  const { data: metricsData, loading } = useGetSetTargetMetricsQuery({
    variables: {
      companyAssessmentId: materialStandardId,
      requiredMetrics: requiredTargetMetrics?.esrs_MaterialMetric.map((mm) => mm.metricRef) ?? [],
    },
    skip: !standardRef || !materialStandardId || !requiredTargetMetrics?.esrs_MaterialMetric.length,
  });

  const metrics = useMemo(() => metricsData?.metrics ?? [], [metricsData]);

  const isIntensityMetric = useMemo(
    () => intensityMetrics.includes(selectedMetric?.reference ?? ''),
    [selectedMetric]
  );

  useEffect(() => {
    reset({
      name: targetToEdit?.name,
      ownerId: targetToEdit?.ownerId ?? user?.id,
      isBottomUp: isIntensityMetric ? false : targetToEdit?.isBottomUp,
    });
    setSelectedMetric(metrics.find((m) => m.reference === targetToEdit?.metricRef));
  }, [targetToEdit]);

  const modalTitle = useMemo(() => {
    if (!!targetToEdit) return 'Edit target';
    if (step === 1) return 'Link to a metric';
    return 'Add target';
  }, [step, targetToEdit]);

  const onSubmit = (values: FormFields) => {
    createTarget({
      values,
      selectedMetric: selectedMetric?.reference,
      subsidiaries,
      disclosureRequirementRef,
      standardRef,
      targetToEdit,
      onClose,
      materialStandardId,
      isGroup,
      isTopDown: isIntensityMetric || !!selectedMetric?.calculation,
    });
  };

  const handleDeleteTarget = () => {
    deleteTarget({
      variables: {
        targetId: targetToEdit?.id,
      },
      refetchQueries: [
        GetTargetsDrDocument_,
        GetDisclosureRequirementGroupsDocument_,
        EsrsAssessmentTargetsDocument_,
      ],
    }).then(() => navigate(-1));
  };

  if (loading) return <Loader />;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={modalTitle}
      overline={targetToEdit ? undefined : `Add target — Step ${step}/2`}
      size="md"
      confirmText={step === 1 ? 'Next step' : targetToEdit ? 'Confirm' : 'Create target'}
      onConfirm={(e) => {
        if (step === 1) {
          e?.preventDefault();
          setStep(2);
        }
      }}
      confirmButtonProps={{
        isDisabled: step === 1 ? !selectedMetric : !isValid,
        form: step === 2 ? 'add-target' : '',
        type: step === 1 ? 'button' : 'submit',
      }}
      cancelText={step === 1 || targetToEdit ? 'Cancel' : 'Prev. step'}
      onCancel={() => {
        if (step === 1 || targetToEdit) onClose();
        else setStep(1);
      }}
      actions={
        targetToEdit && !targetToEdit.parentTargetId
          ? [
              <Button variant="destructive" leftIcon={<DeleteIcon />} onClick={onAlertOpen}>
                Delete target
              </Button>,
            ]
          : []
      }
    >
      {step === 1 ? (
        <MetricsSection
          metrics={metrics}
          selectedMetric={selectedMetric}
          setSelectedMetric={setSelectedMetric}
          subsidiaries={subsidiaries}
          parentTargets={parentTargets}
          targets={targets}
          isGroup={isGroup}
        />
      ) : (
        <form id="add-target" onSubmit={handleSubmit(onSubmit)}>
          <VStack alignItems="start" width="100%" spacing="16px">
            {!targetToEdit && (
              <Infobox
                status="neutral"
                description={`You are creating a target for ${selectedMetric?.title}. Add main information and select how you want to collect data for this target. You can change this information later.`}
                closable={false}
                withIcon={false}
              />
            )}
            {!!targetToEdit?.parentTargetId && (
              <Infobox
                status="info"
                description={`${targetToEdit.parentName} has decided that the group shall set a target for ${targetToEdit.metricTitle}. They have requested you to add data about ${companyName}'s baseline, target and milestones for this metric for defined years. You can also add additional milestones.`}
                closable={false}
                withIcon={false}
              />
            )}
            <Controller
              name="name"
              control={control}
              rules={{ required: 'Add a name' }}
              render={({ field: { onChange, value } }) => (
                <VStack alignItems="start" spacing="4px" width="100%">
                  <Typography variant="bodyStrong">Target name</Typography>
                  <Input
                    width="100%"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder="e.g. Reduce emissions of air pollutants"
                  />
                </VStack>
              )}
            />
            <Controller
              name="ownerId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <VStack alignItems="start" spacing="4px" w="100%">
                  <Typography variant="bodyStrong">Owner</Typography>
                  <UserAssigner
                    assignedTo={value ?? user?.id}
                    setAssignedTo={(id) => onChange(id as string)}
                    placeholder="Select owner"
                  />
                </VStack>
              )}
            />
            {isGroup && !selectedMetric?.calculation && !isIntensityMetric && (
              <Controller
                name="isBottomUp"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <VStack alignItems="start" spacing="10px" mt="8px" w="100%">
                    <VStack alignItems="start" spacing="0px" w="100%">
                      <Typography variant="bodyStrong">Select approach</Typography>
                      <Typography variant="detail">
                        Do you want to set this target yourself (“top-down” approach) or collect
                        data from {isGroup ? 'subsidiaries' : 'BUs'} (“bottom-up” approach)?
                      </Typography>
                    </VStack>
                    <RadioCardGroup
                      value={value === true ? 'bottomUp' : 'topDown'}
                      defaultValue={'topDown'}
                      direction="horizontal"
                      onChange={(val) => onChange(val === 'bottomUp' ? true : false)}
                      options={[
                        {
                          title: 'Top-down',
                          subtitle:
                            'Input data about baseline, target and milestones on the group level. Subsidiaries will be able to see the information added for the group in their interface and use it as a guideline to set their own targets. However, in the group report, only information input about the target at group level will be included.',
                          value: 'topDown',
                        },
                        {
                          title: 'Bottom-up',
                          disabled: !hasAccessToAllSubs,
                          subtitle:
                            "Delegate target setting to subsidiaries and aggregate information about baselines, targets and milestones from subsidiaries' input. You will be able to specify baseline year as well as target- and milestone years and the subsidiaries will be prompted to input baseline-, target and milestone values for these years. Data added by the subsidiaries will be automatically aggregated to the group consolidated level.",
                          value: 'bottomUp',
                        },
                      ]}
                    />
                  </VStack>
                )}
              />
            )}
            {!hasAccessToAllSubs && (
              <Infobox
                status="warning"
                description="You can only create a “Top-down” target. The “Bottom-up” approach requires access to all subsidiaries. Please ask your colleagues to invite you to their companies if you need to set up a bottom-up target."
                closable={false}
                withIcon={false}
              />
            )}
            {isGroup && (isIntensityMetric || !!selectedMetric?.calculation) && (
              <IntensityMetricWarning />
            )}
          </VStack>
          <Modal
            isOpen={isAlertOpen}
            onClose={onAlertClose}
            title="Are you sure you want to delete this target?"
            size="xs"
            confirmVariant="destructive"
            confirmText="Delete"
            onConfirm={handleDeleteTarget}
          >
            <></>
          </Modal>
        </form>
      )}
    </Modal>
  );
};
