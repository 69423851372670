import { Checkbox } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import { AggregatedMetricsTableData, MetricsTableData } from '../../..';
import { MetricCheckboxSelector } from '../CheckboxSelector/CheckboxSelector';
import { TableData } from 'Molecules/NestedTable';
import { QuestionType_Enum_ } from 'models';
import { AssessableMetrics } from '../../../Metrics/ProcessMetrics/MetricsHelperFunctions';

export const SelectedRows = ({
  isNarrative,
  row,
  selectedNarrativeMetrics,
  setSelectedNarrativeMetrics,
  selectedNumericMetrics,
  setSelectedNumericMetrics,
  checkboxesDisabled,
}: {
  isNarrative: boolean;
  row: TableData<AggregatedMetricsTableData>;
  selectedNumericMetrics?: MetricsTableData[];
  setSelectedNumericMetrics?: Dispatch<SetStateAction<MetricsTableData[]>>;
  selectedNarrativeMetrics?: AssessableMetrics;
  setSelectedNarrativeMetrics?: Dispatch<SetStateAction<AssessableMetrics>>;
  checkboxesDisabled?: boolean;
}) => {
  if (isNarrative) {
    const isParent =
      row.metric.childrenMetrics.length > 0 && row.metric.metricType !== QuestionType_Enum_.YesNo_;
    const isChecked = isParent
      ? row.metric.childrenMetrics.every((c) =>
          selectedNarrativeMetrics?.some((r) => r.reference === c.childMetric?.reference)
        )
      : (selectedNarrativeMetrics?.some((met) => met.reference === row.metric.reference) ?? false);

    const isIntermediate =
      isParent && !isChecked
        ? row.metric.childrenMetrics.some((c) =>
            selectedNarrativeMetrics?.some((r) => r.reference === c.childMetric?.reference)
          )
        : false;

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isParent) {
        if (e.target.checked) {
          const childrenMetrics = (row.metric.childrenMetrics ?? []).map(
            (c) => c.childMetric
          ) as AssessableMetrics;
          setSelectedNarrativeMetrics?.([...(selectedNarrativeMetrics ?? []), ...childrenMetrics]);
        } else {
          const childrenReferences = (row.metric.childrenMetrics ?? []).map(
            (c) => c.childMetric?.reference
          );
          setSelectedNarrativeMetrics?.(
            (selectedNarrativeMetrics ?? []).filter(
              (r) => !childrenReferences.includes(r.reference)
            )
          );
        }
      } else {
        if (e.target.checked) {
          setSelectedNarrativeMetrics?.([
            ...(selectedNarrativeMetrics ?? []),
            row.metric as AssessableMetrics[number],
          ]);
        } else {
          setSelectedNarrativeMetrics?.(
            (selectedNarrativeMetrics ?? []).filter((r) => r.reference !== row.metric.reference)
          );
        }
      }
    };
    return (
      <Checkbox
        isDisabled={checkboxesDisabled}
        isChecked={isChecked}
        isIndeterminate={isIntermediate}
        onChange={handleCheckboxChange}
      />
    );
  }
  return (
    selectedNumericMetrics &&
    setSelectedNumericMetrics && (
      <MetricCheckboxSelector
        row={row}
        selectedRows={selectedNumericMetrics}
        setSelectedRows={setSelectedNumericMetrics}
        isDisabled={checkboxesDisabled ?? false}
      />
    )
  );
};
