import { NestedTable } from 'Molecules/NestedTable';
import { processCountriesMetricsData } from '../../DocGenerator/CustomTables/countries-coverage';
import { ColumnDefResolved } from '@tanstack/react-table';
import { Typography } from 'Tokens';
import { EsrsDRReportMetric } from '../../Report.types';
import { MaterialMetricTagFieldsFragment_ } from 'models';

type TableData = {
  rate: string;
  [key: string]: string | number | undefined;
  subRows: undefined;
};

export const S1_8Table = ({
  metrics,
}: {
  metrics: (EsrsDRReportMetric['tableData'] & {
    materialMetricTags: MaterialMetricTagFieldsFragment_[];
  })[];
}) => {
  const data = processCountriesMetricsData(metrics);

  const tableData = data.map((row) => ({
    rate: row.rate,
    ...row.metrics,
    subRows: undefined,
  }));

  const columns: ColumnDefResolved<TableData>[] = [
    {
      header: '',
      columns: [
        {
          header: 'Coverage Rate',
          accessorKey: 'rate',
          meta: {
            width: '30%',
          },
          cell: ({ row }) => <Typography variant="body">{row.original?.rate}</Typography>,
        },
      ],
    },
    {
      header: 'Collective Bargaining Coverage',
      columns: [
        {
          header: 'Employees - EEA',
          accessorKey: 'eea',
          cell: ({ row }) => (
            <Typography variant="body">{row.original?.percentageBargainingEEA}</Typography>
          ),
        },
        {
          header: 'Employees - Non-EEA',
          accessorKey: 'non-eea',
          cell: ({ row }) => (
            <Typography variant="body">{row.original?.percentageBargainingNonEEA}</Typography>
          ),
        },
      ],
    },
    {
      header: 'Social Dialogue',
      columns: [
        {
          header: 'Work representation (EEA only)',
          accessorKey: 'eea-only',
          cell: ({ row }) => (
            <Typography variant="body">{row.original?.percentageRepresentativesEEA}</Typography>
          ),
        },
      ],
    },
  ];

  return (
    <NestedTable<TableData>
      columns={columns}
      data={tableData}
      withOuterBorder={true}
      borderRadius="0px"
      rowHeight="36px"
    />
  );
};
