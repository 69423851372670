import { Box, Stack, VStack } from '@chakra-ui/react';
import { ColumnDefResolved } from '@tanstack/react-table';
import {
  AggregatedMetricsTableData,
  MetricRowTitle,
  MetricsTableData,
} from 'containers/Esrs/pieces/DisclosureRequirements';
import { NestedTable } from 'Molecules/NestedTable';
import { useMemo } from 'react';
import { Typography } from 'Tokens';

export const S1_6SingleMetric = ({
  metric,
  type,
}: {
  metric: AggregatedMetricsTableData;
  type: 'gender' | 'country';
}) => {
  const data = [...(metric.subRows ?? []), { ...metric, subRows: [] }];

  const columns: ColumnDefResolved<AggregatedMetricsTableData>[] = useMemo(
    () => [
      {
        header: type === 'gender' ? 'Gender' : 'Country',
        accessorKey: 'title',
        meta: {
          width: '70%',
        },
        cell: ({ row }) => <MetricRowTitle row={row} isReport={true} />,
      },
      {
        header: 'Number of employees (head count)',
        accessorKey: 'yearly',
        cell: ({ row }) => (
          <Stack w="100%" alignItems="center">
            <Typography variant="body">
              {isNaN(row.original.result?.Year ?? 0)
                ? '--'
                : Math.floor(row.original.result?.Year ?? 0)}
            </Typography>
          </Stack>
        ),
      },
    ],
    [data]
  );

  return (
    <NestedTable<MetricsTableData>
      columns={columns}
      data={data}
      withOuterBorder={true}
      borderRadius="0px"
      rowHeight="36px"
    />
  );
};
