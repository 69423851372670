import { sortBy, uniqBy } from 'lodash';
import {
  Document,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  WidthType,
  VerticalAlign,
  TextRun,
  LevelFormat,
  AlignmentType,
  convertInchesToTwip,
  Footer,
  PageNumber,
  NumberFormat,
  Bookmark,
  ImageRun,
} from 'docx';
import {
  AggregatedMetricsExtraData,
  AggregatedMetricsTableData,
  DREnums,
  MDRM_QUESTIONS,
  MdrmTypeEnum,
} from '../../DisclosureRequirements';
import {
  DocumentInput,
  EsrsReportCategory,
  EsrsReportData,
  EsrsReportDisclosureRequirement,
  EsrsReportMetric,
  EsrsReportStandard,
  SideMenuStructure,
  SimplifiedReportNarrativeMetric,
} from '../Report.types';
import { styles, tableBorders, TextStyle } from './reportThemes';
import { ORDER_OF_CATEGORIES } from 'utils/categoriesOrder';
import { mapUnitToCompanyCurrency, percentageToNumber } from 'containers/Esrs/utils';
import { ActionFieldsFragment_, QuestionType_Enum_, TargetFieldsFragment_ } from 'models';
import { simplifyNarrativeMetrics } from '../Report.hooks';
import { ClimateTargetQuestionRefs, adjustMDRReportData, getReportMDRA } from '../ReportUtils';
import { createE16TargetsTable, E1_6TargetMetrics } from './CustomTables/E1-6-targets';
import { createOtherEULegislationsTable } from './CustomTables/other-eu-legislations';
import { tableOfContents } from './CustomTables/table-of-content';
import { createS18CountriesCoverageTable } from './CustomTables/countries-coverage';
import { createE15Table } from './CustomTables/energy-consumption-mix';
import { ReportMdrmMetricAnswers } from '../MetricAnswerCalculation';
import { TableData } from 'Molecules/NestedTable';
import { getRowTitle } from './report-helper-functions';
import { createGOV4Table } from './CustomTables/gov4-table';
import { createS16Tables } from './CustomTables/S1-6-tables';
import { MDRType } from '../../DisclosureRequirements/MDR/MDRData';
import { S1_6Metrics, S1_8Metrics, E1_5Metrics, GOV_4Metrics } from '../ReportComponents';

const getTableRowText = (row: TableData<AggregatedMetricsExtraData>) => {
  const percentage = row.percentage;
  const result = row.result?.Year ?? 0;
  const isResultNaN = isNaN(result);
  const parsedResult = isResultNaN ? 0 : parseFloat(result.toFixed(2));
  const stringifiedResult = String(parsedResult) ?? '--';
  const isUmbrellaMetric = row.metric.calculation === 'NONE';
  if (isUmbrellaMetric) return '';

  return percentage ? stringifiedResult + ` (${percentage}%)` : stringifiedResult;
};

const addEmptySpace = (spacing: number): Paragraph => {
  return new Paragraph({
    text: '',
    spacing: {
      after: spacing,
    },
  });
};

const createRowIdentifier = (row: TableData<AggregatedMetricsTableData>): string => {
  const reference = row.metric.reference;
  const tagName = row.subRows?.length && row.tagName ? row.tagName : null;
  const tagValues = row.tags?.length ? row.tags.map((t) => t.tagValue).join('-') : null;

  return [reference, tagName, tagValues].filter(Boolean).join('-');
};

export class EsrsDocumentCreator {
  public async create(input: DocumentInput, companyCurrency: string): Promise<Document> {
    const guidanceImage = await fetch('/images/guidance_word_report.png').then(function (response) {
      return response.arrayBuffer();
    });

    const document = new Document({
      creator: 'Celsia.io',
      revision: 1,
      subject: 'Esrs report',
      title: `${input.data.companyName} - ESRS report ${input.data.reportingYear}`,
      styles: styles,
      numbering: {
        config: [
          {
            reference: 'number-system',
            levels: [
              {
                level: 0,
                format: LevelFormat.UPPER_ROMAN,
                text: '%1.',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.25), hanging: convertInchesToTwip(0.25) },
                  },
                },
              },
            ],
          },
        ],
      },
      sections: [
        {
          properties: {
            page: {
              margin: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              },
              pageNumbers: {
                start: 1,
                formatType: NumberFormat.DECIMAL,
              },
            },
          },
          children: [
            new Paragraph({
              children: [
                new ImageRun({
                  data: guidanceImage,
                  transformation: { width: 800, height: 1120 },
                }),
              ],
            }),
          ],
        },
        {
          properties: {
            page: {
              margin: {
                left: convertInchesToTwip(1.25),
                right: convertInchesToTwip(1.25),
              },
              pageNumbers: {
                start: 2,
                formatType: NumberFormat.DECIMAL,
              },
            },
          },
          children: this.createSections(
            {
              categories: input.data.categories,
              companyName: input.data.companyName,
              reportingYear: input.data.reportingYear,
              materialStandards: input.data.materialStandards,
              structure: input.structure,
            },
            companyCurrency
          ),
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      children: [PageNumber.CURRENT],
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          },
        },
      ],
    });

    return document;
  }

  public createSections(
    {
      companyName,
      reportingYear,
      categories,
      materialStandards,
      structure,
    }: {
      companyName: string;
      reportingYear: number;
      categories: EsrsReportCategory[];
      materialStandards: EsrsReportData['materialStandards'];
      structure: SideMenuStructure;
    },
    companyCurrency: string
  ) {
    const reportStandards = categories.flatMap((category) => category.standards);

    const showAppendix = reportStandards?.some((standard) =>
      standard.disclosureRequirements.some((dr) =>
        dr.metrics.some(
          (metric) =>
            metric.metric.metricRef ===
              'disclosureoflistofdatapointsthatderivefromothereulegislationandinformationontheirlocationinsustainabilitystatement' &&
            metric.metric.isMaterial
        )
      )
    );

    return [
      new Paragraph({
        text: `ESRS sustainability statement for ${companyName} ${reportingYear}`,
        style: TextStyle.title,
        spacing: {
          after: 400,
        },
      }),
      this.createCategories(categories ?? [], companyCurrency, reportingYear, structure),
      addEmptySpace(300),

      ...(showAppendix
        ? [
            new Paragraph({
              text: 'Appendix',
              style: TextStyle.h2,
              spacing: {
                after: 200,
              },
            }),
            ...createOtherEULegislationsTable(materialStandards, reportStandards),
          ]
        : []),
    ].flat();
  }

  public createCategories(
    categories: EsrsReportCategory[],
    companyCurrency: string,
    year: number,
    structure: SideMenuStructure
  ) {
    const visibleCategories = categories
      .filter(
        (category) =>
          category.reference === 'general' ||
          category.standards.some((standard) =>
            standard.disclosureRequirements.some((dr) => !dr.isHidden)
          )
      )
      .sort((a, b) => ORDER_OF_CATEGORIES[a.title] - ORDER_OF_CATEGORIES[b.title]);

    const displayedCategories = visibleCategories.flatMap((category, index) => {
      let tableOfContent: any[] = [];
      if (visibleCategories?.[index - 1]?.reference === 'general') {
        tableOfContent = tableOfContent = [
          new Paragraph({
            children: [],
            pageBreakBefore: true,
          }),
          ...tableOfContents(structure),
          new Paragraph({
            children: [],
            pageBreakBefore: true,
          }),
        ];
      }

      return [
        ...tableOfContent,
        new Paragraph({
          text: `${category.title === 'Environment' ? 'Environmental' : category.title} information`,
          style: TextStyle.h3,
          spacing: {
            after: 300,
          },
          numbering: {
            level: 0,
            reference: 'number-system',
          },
        }),
        category.title === 'Environment'
          ? new Paragraph({
              style: TextStyle.h1,
              shading: {
                type: 'solid',
                color: 'DCF6ED',
              },
              children: [
                new TextRun({
                  text: 'Disclosures pursuant to Article 8 of Regulation (EU) 2020/852 (Taxonomy Regulation)',
                }),
              ],
              spacing: {
                after: 800,
              },
            })
          : {},
        this.createStandards(category.standards, companyCurrency, year),
      ];
    });
    return displayedCategories.flat();
  }

  public createStandards(standards: EsrsReportStandard[], companyCurrency: string, year: number) {
    const visibleStandards = standards
      .filter(
        (standard) =>
          !standard.disclosureRequirements.every(
            (dr) => dr.isHidden || dr.metrics.every((metric) => metric.metric.isHidden)
          )
      )
      .sort((a, b) => a.reference.localeCompare(b.reference));

    const displayedStandards = visibleStandards.flatMap((standard) => {
      return [
        new Paragraph({
          text: `${standard.reference.replace(/-/g, ' ')} ${standard.title}`,
          style: TextStyle.h1,
          spacing: {
            after: 200,
          },
        }),
        this.createDisclosures(
          standard.disclosureRequirements,
          companyCurrency,
          standard.targetQuestions,
          standard.actionQuestions,
          standard.reference,
          year
        ),
      ];
    });
    return displayedStandards.flat();
  }

  public createDisclosures(
    disclosureRequirements: EsrsReportDisclosureRequirement[],
    companyCurrency: string,
    targetQuestions: EsrsReportStandard['targetQuestions'],
    actionQuestions: EsrsReportStandard['actionQuestions'],
    standardRef: string,
    year: number
  ) {
    const visibleDisclosureRequirements = disclosureRequirements.filter((dr) =>
      dr.metricsIncludingOptedOut.some((metric) => !metric.metric.isHidden)
    );
    const displayedDisclosureRequirements = visibleDisclosureRequirements
      .sort((a, b) => a.order - b.order)
      .flatMap((dr) => {
        const isEntitySpecific = dr.additionalTypes?.some(
          (type) => type.additionalTypeRef === 'Entity-specific'
        );
        const titleParagraph = new Paragraph({
          children: [
            new Bookmark({
              id: dr.reference,
              children: [
                new TextRun({
                  text: isEntitySpecific ? `${dr.title}` : `${dr.reference} – ${dr.title}`,
                }),
              ],
            }),
          ],
          style: TextStyle.h2,
          spacing: {
            after: 150,
          },
        });
        const isTarget = dr.type === DREnums.target;
        const isAction = dr.type === DREnums.action;
        const isPolicy = dr.type === DREnums.policy;

        if (isTarget || isAction || isPolicy) {
          const withAssociationMetrics =
            dr.metrics.filter((metric) => metric.metric.metric.hasAssociation === true) ?? [];
          const withoutAssociationMetrics =
            dr.metrics.filter((metric) => metric.metric.metric.hasAssociation === false) ?? [];

          const withAssociationMetricsArray = withAssociationMetrics?.length
            ? this.createMetrics(withAssociationMetrics, companyCurrency)
            : [];

          const withoutAssociationMetricsArray = withoutAssociationMetrics?.length
            ? this.createMetrics(withoutAssociationMetrics, companyCurrency)
            : [];

          return [
            titleParagraph,
            ...withoutAssociationMetricsArray,
            isTarget
              ? this.createTargets(dr.targets, targetQuestions, standardRef, companyCurrency)
              : this.createActions(dr.actions, actionQuestions, companyCurrency),
            ...withAssociationMetricsArray,
          ];
        } else {
          if (dr.reference === 'E1-6') {
            const targets = disclosureRequirements.find((dr) => dr.reference === 'E1-4')?.targets;
            const customMetrics = dr.metrics.filter((m) =>
              E1_6TargetMetrics.map((m) => m.reference).includes(m.metric.metricRef)
            );
            const otherMetrics = dr.metrics.filter(
              (m) =>
                ![...E1_6TargetMetrics, { reference: 'grossScopeGHG2' }].some(
                  (tm) => tm.reference === m.metric.metricRef
                )
            );

            return [
              titleParagraph,
              addEmptySpace(100),
              createE16TargetsTable(
                targets,
                dr.metrics.map((m) => m.tableData),
                year
              ),
              addEmptySpace(200),
              customMetrics.flatMap((metric) => this.createMdrm(metric)),
              addEmptySpace(200),
              this.createMetrics(otherMetrics, companyCurrency),
              addEmptySpace(200),
            ];
          } else if (dr.reference === 'S1-6') {
            const metrics = dr.metrics.filter(
              (m) =>
                !S1_6Metrics.includes(m.metric.metricRef) || m.metric.metricRef === 'totalEmployees'
            );
            const customMetrics = dr.metrics.filter((m) =>
              S1_6Metrics.includes(m.metric.metricRef)
            );
            return [
              titleParagraph,
              addEmptySpace(200),
              ...createS16Tables(customMetrics, companyCurrency),
              addEmptySpace(200),
              customMetrics.flatMap((metric) => this.createMdrm(metric)),
              addEmptySpace(200),
              this.createMetrics(metrics, companyCurrency),
              addEmptySpace(200),
            ];
          } else if (
            dr.reference === 'S1-8' &&
            dr.metrics.some((m) => S1_8Metrics.includes(m.metric.metricRef))
          ) {
            const S1_8MetricsData =
              dr.metrics.filter((m) => S1_8Metrics.includes(m.metric.metricRef)) ??
              ([] as EsrsReportMetric[]);
            const otherMetrics = (dr.metrics.filter(
              (m) => !S1_8Metrics.includes(m.metric.metricRef)
            ) ?? []) as EsrsReportMetric[];
            return [
              titleParagraph,
              addEmptySpace(200),
              createS18CountriesCoverageTable(S1_8MetricsData),
              addEmptySpace(200),
              S1_8MetricsData.flatMap((metric) => this.createMdrm(metric)),
              addEmptySpace(200),
              this.createMetrics(otherMetrics, companyCurrency),
              addEmptySpace(200),
              ,
            ];
          } else if (dr.reference === 'E1-5') {
            const E1_5MetricsData = (dr.metrics.filter((m) =>
              E1_5Metrics.includes(m.metric.metricRef)
            ) ?? []) as EsrsReportMetric[];
            const otherMetrics = (dr.metrics.filter(
              (m) => !E1_5Metrics.includes(m.metric.metricRef)
            ) ?? []) as EsrsReportMetric[];

            return [
              titleParagraph,
              addEmptySpace(100),
              createE15Table(E1_5MetricsData, year),
              addEmptySpace(200),
              E1_5MetricsData.flatMap((metric) => this.createMdrm(metric)),
              addEmptySpace(200),
              this.createMetrics(otherMetrics, companyCurrency),
              addEmptySpace(200),
            ];
          }
          return [titleParagraph, this.createMetrics(dr.metrics, companyCurrency)];
        }
      });
    return displayedDisclosureRequirements.flat();
  }

  public createMetrics(
    metrics: EsrsReportMetric[],
    companyCurrency: string
  ): (Paragraph | Table)[] {
    const visibleMetrics = metrics.filter((metric) => !metric.metric.isHidden);

    // Use unique refs to avoid displaying the same metric twice
    const uniqueRowRefs: string[] = [];

    const simplifiedMetrics = simplifyNarrativeMetrics(metrics);

    const combinedMetricsSection = visibleMetrics.flatMap((metric) => {
      const isQuantitative =
        metric.metric.metric.metricType === QuestionType_Enum_.Decimal_ &&
        metric.metric.metric.isAssessable === true &&
        !metric.metric.metric.parentMetrics.some(
          (pm) => pm.parentMetric.metricType !== QuestionType_Enum_.Decimal_
        );

      if (isQuantitative) {
        return [this.createQuantitativeMetrics([metric], companyCurrency, uniqueRowRefs)];
      } else {
        // Narrative metrics
        if (
          metric.metric.metricRef ===
          'disclosureofmappingofinformationprovidedinsustainabilitystatementaboutduediligenceprocess'
        ) {
          return [
            [
              createGOV4Table(
                metrics.filter((m) =>
                  GOV_4Metrics.includes(m.metric.metricRef)
                ) as EsrsReportMetric[]
              ),
            ],
            addEmptySpace(200),
          ];
        }

        const simplifiedMetric = simplifiedMetrics.find(
          (m) => m.materialMetric.metricRef === metric.metric.metricRef
        );
        if (!simplifiedMetric) {
          return [];
        }
        return [
          this.createNarrativeMetric(
            simplifiedMetric,
            true,
            metrics,
            companyCurrency,
            uniqueRowRefs
          ),
          addEmptySpace(200),
        ];
      }
    });

    return combinedMetricsSection.flat();
  }

  public createQuantitativeMetrics(
    quantitativeMetrics: EsrsReportMetric[],
    companyCurrency: string,
    uniqueRowRefs: string[]
  ) {
    const displayedMetrics = quantitativeMetrics.flatMap((metric) => {
      const { shortTitle, title } = metric.metric.metric;

      const hasAnswer = metric.tableData?.result?.Year;

      const metricHeader = hasAnswer
        ? []
        : new Paragraph({
            children: [
              new Bookmark({
                id: metric.metric.metricRef,
                children: [
                  new TextRun({
                    text: shortTitle ?? title,
                  }),
                ],
              }),
            ],
            style: TextStyle.h4,
          });

      const mdrmSection = this.createMdrm(metric);

      return [
        metricHeader,
        addEmptySpace(100),
        this.createMetricTable(metric, companyCurrency, uniqueRowRefs),
        addEmptySpace(100),
        mdrmSection,
        addEmptySpace(250),
      ];
    });

    return displayedMetrics.flat();
  }

  public createMetricTable(
    metric: EsrsReportMetric,
    companyCurrency: string,
    uniqueRowRefs: string[]
  ) {
    const metricInfo = metric.metric.metric;
    const isUmbrellaMetric = metricInfo.calculation === 'NONE';
    const quantitativeMetricTable = new Table({
      columnWidths: [4000, 2000, 2000],
      margins: {
        top: 60,
        bottom: 60,
        right: 60,
        left: 60,
      },
      width: {
        size: '100%',
        type: WidthType.PERCENTAGE,
      },
      borders: tableBorders,
      rows: [
        new TableRow({
          tableHeader: true,
          children: [
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: '60%',
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  text: !isUmbrellaMetric
                    ? 'Metric'
                    : `${metricInfo.shortTitle ?? metricInfo.title}`,
                  style: TextStyle.tableTitle,
                }),
              ],
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: '20%',
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  text: 'Total',
                  style: TextStyle.tableTitle,
                }),
              ],
            }),
          ],
        }),
        this.createDataPointRows(metric, companyCurrency, uniqueRowRefs),
      ].flat(),
    });

    if (metric.metric.metric.metricType !== QuestionType_Enum_.Decimal_) return [];

    return quantitativeMetricTable;
  }

  public createDataPointRows(
    metric: EsrsReportMetric,
    companyCurrency: string,
    uniqueRowRefs: string[]
  ) {
    const metricResults = metric.tableData;
    const metricInfo = metric.metric.metric;
    const metricTitle = metricInfo.shortTitle ?? metricInfo.title;
    const unitOfMeasurement = metricInfo.unitOfMeasurement
      ? ` (${mapUnitToCompanyCurrency(metricInfo.unitOfMeasurement, companyCurrency)})`
      : '';
    const hasCalculation = metricInfo.calculation !== '' && metricInfo.calculation !== null;

    const withChildrenAndTags =
      metric.metric.childrenMetrics?.length > 0 && !!metricResults.subRows?.[0].tagName;

    // Umbrella metrics are metrics with calculation set to "NONE"
    const isUmbrellaMetric = metricInfo.calculation === 'NONE';

    const uniqueRowIdentifier = createRowIdentifier(metricResults);
    const alreadyExists = uniqueRowRefs.includes(uniqueRowIdentifier);
    if (!alreadyExists) {
      uniqueRowRefs.push(uniqueRowIdentifier);
    }

    const displayedRows = [
      !isUmbrellaMetric
        ? new TableRow({
            children: [
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  new Paragraph({
                    children: [
                      new Bookmark({
                        id: metricInfo.reference,
                        children: [
                          new TextRun({
                            text: `${metricTitle}${unitOfMeasurement}`,
                          }),
                        ],
                      }),
                    ],
                    style: TextStyle.body,
                  }),
                ],
              }),
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  new Paragraph({
                    text: getTableRowText(metric.tableData),
                    style: TextStyle.body,
                  }),
                ],
              }),
            ],
          })
        : [],
      // If the metric has calculation, we do not display nested rows
      hasCalculation && !withChildrenAndTags && !isUmbrellaMetric
        ? []
        : alreadyExists
          ? []
          : this.createNestedDataPointsRows(metricResults, companyCurrency, uniqueRowRefs),
    ];
    return displayedRows.flat();
  }

  public createNestedDataPointsRows(
    metricResults: AggregatedMetricsTableData,
    companyCurrency: string,
    uniqueRowRefs: string[],
    depth = 1
  ) {
    const subRows = metricResults.subRows;

    if (!subRows?.length) return [];

    const isUmbrellaMetric = metricResults.metric.calculation === 'NONE';

    const isTagWithChildMetrics =
      metricResults.metric.childrenMetrics.length && metricResults.tagName;

    const withChildrenAndTags = isTagWithChildMetrics && metricResults.tagType;

    // Case for tag with child metric subRows => show only the tag
    if (isTagWithChildMetrics && !isUmbrellaMetric && !withChildrenAndTags) return [];

    const nestedRows: TableRow[] =
      subRows?.flatMap((row) => {
        const uniqueIdentifier = createRowIdentifier(row);
        const alreadyExists = uniqueRowRefs.includes(uniqueIdentifier);
        if (!alreadyExists) {
          uniqueRowRefs.push(uniqueIdentifier);
        }

        return [
          new TableRow({
            children: [
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  new Paragraph({
                    indent: {
                      start: depth * 220,
                    },
                    children: [
                      new Bookmark({
                        id: row.metric.reference,
                        children: [
                          new TextRun({
                            text: getRowTitle(row, metricResults, companyCurrency),
                          }),
                        ],
                      }),
                    ],
                    style: TextStyle.body,
                  }),
                ],
              }),
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  new Paragraph({
                    text: getTableRowText(row),
                    style: TextStyle.body,
                  }),
                ],
              }),
            ],
          }),
          ...(alreadyExists
            ? []
            : this.createNestedDataPointsRows(row, companyCurrency, uniqueRowRefs, depth + 1)),
        ];
      }) ?? [];

    return nestedRows.flat();
  }

  public createMdrm(metric: EsrsReportMetric) {
    const isNarrativeMetric = metric.metric.metric.metricType !== QuestionType_Enum_.Decimal_;

    if (isNarrativeMetric) return [];

    const metricMdrmDataPoints = metric.mdrmAnswers;

    const mdrmAnswersArray = Object.values(metricMdrmDataPoints.answers);

    const isNotDisclosed = !metric.isMdrmDisclosed;
    const noMdrmAnswered = mdrmAnswersArray.every((answer) => !answer || answer.value === '');
    if (noMdrmAnswered || isNotDisclosed) return [];

    return [this.createMetricMdrmAnswers(metricMdrmDataPoints), addEmptySpace(100)].flat();
  }

  public createMetricMdrmAnswers(mdrmMetricAnswers: ReportMdrmMetricAnswers) {
    const isEntitySpecific = mdrmMetricAnswers.metric.additionalTypes.some((type) => {
      return type.additionalType.reference === 'Entity-specific';
    });

    const mappedMdrms = MDRM_QUESTIONS.filter((q) => {
      if (q.id === MdrmTypeEnum.entitySpecific) {
        return isEntitySpecific;
      }
      return true;
    })
      .map((q) => {
        return {
          id: q.id,
          question: q.question,
          answer: mdrmMetricAnswers.answers[q.id],
        };
      })
      // Remove opted out MDR-Ms
      .filter((q) => !q.answer?.hasOptedOut);

    const displayedAnswers = mappedMdrms.flatMap((q) => {
      const answer = mdrmMetricAnswers.answers?.[q.id];

      if (!!answer?.value) {
        return [
          new Paragraph({
            text: answer?.value ?? '',
            style: TextStyle.body,
            spacing: {
              after: 100,
            },
          }),
        ];
      }

      return [];
    });

    return displayedAnswers.flat();
  }

  public createNarrativeMetric(
    metric: SimplifiedReportNarrativeMetric,
    isTopLevel: boolean,
    allMetrics: EsrsReportMetric[],
    companyCurrency: string,
    uniqueRowRefs: string[]
  ) {
    const { shortTitle, title, reference } = metric.materialMetric.metric;

    const titleParagraph = new Paragraph({
      children: [
        new Bookmark({
          id: reference,
          children: [
            new TextRun({
              text: shortTitle ?? title,
            }),
          ],
        }),
      ],
      style: TextStyle.h4,
      spacing: {
        after: 100,
      },
    });

    const hasAnswer = !!metric?.textAnswer && metric?.textAnswer !== '';
    const isBooleanOrEnum =
      metric.materialMetric.metric.metricType === QuestionType_Enum_.YesNo_ ||
      metric.materialMetric.metric.metricType === QuestionType_Enum_.MultipleChoice_ ||
      metric.materialMetric.metric.metricType === QuestionType_Enum_.SingleChoice_;
    const isBoolean = metric.materialMetric.metric.metricType === QuestionType_Enum_.YesNo_;
    const isStandalone =
      !metric.children.length && !metric.materialMetric.metric.parentMetrics.length;

    const hideTitle = (isBoolean || isStandalone) && hasAnswer;

    // Split answers with multiple lines into paragraphs
    const answerParagraphs = this.createNarrativeAnswer(metric, hasAnswer);

    const textBefore = metric.isBeforeVisible
      ? [
          new Paragraph({
            text: metric.textBefore,
            style: TextStyle.body,
            spacing: {
              after: 100,
            },
          }),
        ]
      : [];

    const textAfter = metric.isAfterVisible
      ? [
          new Paragraph({
            text: metric.textAfter,
            style: TextStyle.body,
            spacing: {
              before: 100,
            },
          }),
        ]
      : [];

    // For children that are numeric
    const isNumericChild = metric.materialMetric.metric.metricType === QuestionType_Enum_.Decimal_;

    if (isNumericChild) {
      const numericChild = (allMetrics ?? []).find(
        (m) => m.metric.metricRef === metric.materialMetric.metricRef
      );

      if (!numericChild) {
        return [];
      }

      const numericChildSection = [
        titleParagraph,
        this.createMetricTable(numericChild, companyCurrency, uniqueRowRefs),
      ].flat();

      return [...numericChildSection, addEmptySpace(100)];
    }

    if (metric.children.length > 0 && !metric.isLocked) {
      const nestedChildren: (Paragraph | Table)[] = (
        metric.children.flatMap((child) => {
          const narrativeMetricSection = this.createNarrativeMetric(
            child,
            false,
            allMetrics,
            companyCurrency,
            uniqueRowRefs
          );

          return [narrativeMetricSection, addEmptySpace(100)];
        }) ?? []
      ).flat();

      const booleanOrEnumParentParagraph = [answerParagraphs, addEmptySpace(50)].flat();

      return [
        hideTitle ? [] : titleParagraph,
        ...textBefore,
        // Case when the metric is a boolean or enum parent, we show its answer, otherwise we show only title
        isBooleanOrEnum ? booleanOrEnumParentParagraph : [],
        nestedChildren,
        ...textAfter,
      ].flat();
    }

    return isTopLevel || !hasAnswer
      ? hideTitle
        ? [...answerParagraphs]
        : [titleParagraph, ...answerParagraphs]
      : (metric.materialMetric.metricRef === 'otherlongtermtimehorizon' ||
            metric.materialMetric.metricRef === 'othermediumtermtimehorizon') &&
          !hideTitle
        ? [titleParagraph, ...answerParagraphs]
        : [...answerParagraphs];
  }

  public createNarrativeAnswer(metric: SimplifiedReportNarrativeMetric, hasAnswer: boolean) {
    const textAnswer = metric.textAnswer ?? '';
    const answerLines = (hasAnswer ? textAnswer.split('\n') : ['No answer provided.']) ?? [];

    const answerParagraphs = answerLines.map(
      (line) =>
        new Paragraph({
          text: line,
          style: hasAnswer ? TextStyle.body : TextStyle.bodyItalic,
          spacing: {
            afterAutoSpacing: true,
          },
        })
    );

    return answerParagraphs;
  }

  public createTargets(
    targets: TargetFieldsFragment_[] | undefined,
    targetQuestions: EsrsReportStandard['targetQuestions'],
    standardRef: string,
    companyCurrency: string
  ) {
    if (!targets?.length) return [];

    const displayedTargets = targets.flatMap((target) => {
      const targetSection = this.createTarget(
        target,
        targetQuestions,
        standardRef,
        companyCurrency
      );
      return [targetSection, addEmptySpace(200)];
    });

    const summaryTable = this.createTargetsSummaryTable(targets);

    return [...displayedTargets.flat(), ...summaryTable];
  }

  public createTarget(
    target: TargetFieldsFragment_,
    targetQuestions: EsrsReportStandard['targetQuestions'],
    standardRef: string,
    companyCurrency: string
  ) {
    const isClimateChange = standardRef === 'ESRS-E1';
    const reportTargetsMDR = adjustMDRReportData({
      target,
      isDocx: true,
      isClimateChange,
      flatTargetQuestions: targetQuestions,
      companyCurrency,
    });

    const filteredTargetQuestions = isClimateChange
      ? targetQuestions.filter(
          (question) => !ClimateTargetQuestionRefs.includes(question.reference)
        )
      : targetQuestions;

    const groupTQ = uniqBy(
      filteredTargetQuestions.map((tq) => tq.group)?.filter((tq) => !!tq) ?? [],
      'reference'
    );
    const groupedTargetQuestions = groupTQ.map((group) => ({
      ...group,
      targetQuestions: filteredTargetQuestions.filter((q) => q.groupRef === group?.reference) ?? [],
    }));

    const singleTargetQuestions = filteredTargetQuestions.filter((tq) => tq.groupRef === null);

    const title = target.name;

    const titleParagraph = new Paragraph({
      text: title,
      style: TextStyle.h3,
      spacing: {
        after: 300,
      },
    });

    const titleMDRT = new Paragraph({
      text: 'MDR-T',
      style: TextStyle.h4,
      spacing: {
        after: 200,
      },
    });

    const titleOtherDisclosures = new Paragraph({
      text: 'Other Disclosures',
      style: TextStyle.h4,
      spacing: {
        after: 200,
      },
    });

    const displayedMDRT = reportTargetsMDR.flatMap((mdr) => {
      const mdrtSection = this.createMDR({ mdr: mdr as MDRType, target });
      return [...mdrtSection, addEmptySpace(200)];
    });

    const displayedTargetQuestionGroup = groupedTargetQuestions.flatMap((group) => {
      const groupQuestionTitle = new Paragraph({
        text: group.title,
        style: TextStyle.h4,
        spacing: {
          after: 150,
        },
      });
      const groupTargetQuestionsSection = group.targetQuestions?.flatMap((question) => {
        const targetQuestionSection = this.createTargetActionQuestion(
          question,
          target.answers,
          companyCurrency
        );
        return [...targetQuestionSection, addEmptySpace(100)];
      });
      return [groupQuestionTitle, ...groupTargetQuestionsSection, addEmptySpace(100)];
    });

    const displayedSingleTargetQuestion = singleTargetQuestions.flatMap((question) => {
      const targetQuestionSection = this.createTargetActionQuestion(
        question,
        target.answers,
        companyCurrency
      );
      return [...targetQuestionSection, addEmptySpace(100)];
    });

    return [
      titleParagraph,
      titleMDRT,
      ...displayedMDRT,
      titleOtherDisclosures,
      ...displayedTargetQuestionGroup,
      ...displayedSingleTargetQuestion,
    ];
  }

  public createActions(
    actions: ActionFieldsFragment_[] | undefined,
    actionQuestions: EsrsReportStandard['actionQuestions'],
    companyCurrency: string
  ) {
    if (!actions?.length) return [];

    const displayedActions = actions.flatMap((action) => {
      const actionSection = this.createAction(action, actionQuestions, companyCurrency);
      return [actionSection, addEmptySpace(200)];
    });

    return displayedActions.flat();
  }

  public createAction(
    action: ActionFieldsFragment_,
    actionQuestions: EsrsReportStandard['actionQuestions'],
    companyCurrency: string
  ) {
    const actionsMDR = getReportMDRA();

    const groupActionQuestions = uniqBy(
      actionQuestions.map((aq) => aq.group)?.filter((aq) => !!aq) ?? [],
      'reference'
    );
    const groupedActionQuestions = groupActionQuestions.map((group) => ({
      ...group,
      actionQuestions: actionQuestions.filter((q) => q.groupRef === group?.reference) ?? [],
    }));

    const singleActionQuestions = actionQuestions.filter((tq) => tq.groupRef === null);

    const title = action.title;

    const titleParagraph = new Paragraph({
      text: title,
      style: TextStyle.h3,
      spacing: {
        after: 300,
      },
    });

    const titleMDRA = new Paragraph({
      text: 'MDR-A',
      style: TextStyle.h4,
      spacing: {
        after: 200,
      },
    });

    const titleOtherDisclosures = new Paragraph({
      text: 'Other Disclosures',
      style: TextStyle.h4,
      spacing: {
        after: 200,
      },
    });

    const displayedMDRA = actionsMDR.flatMap((mdr) => {
      const mdrtSection = this.createMDR({ mdr, action });
      return [...mdrtSection, addEmptySpace(200)];
    });

    const displayedActionQuestionGroup = groupedActionQuestions.flatMap((group) => {
      const groupQuestionTitle = new Paragraph({
        text: group.title,
        style: TextStyle.h4,
        spacing: {
          after: 150,
        },
      });
      const groupActionQuestionsSection = group.actionQuestions?.flatMap((question) => {
        const actionQuestionSection = this.createTargetActionQuestion(
          question,
          action.answers,
          companyCurrency
        );
        return [...actionQuestionSection, addEmptySpace(100)];
      });
      return [groupQuestionTitle, ...groupActionQuestionsSection, addEmptySpace(100)];
    });

    const displayedSingleActionQuestion = singleActionQuestions.flatMap((question) => {
      const actionQuestionSection = this.createTargetActionQuestion(
        question,
        action.answers,
        companyCurrency
      );
      return [...actionQuestionSection, addEmptySpace(100)];
    });

    return [
      titleParagraph,
      titleMDRA,
      ...displayedMDRA,
      titleOtherDisclosures,
      ...displayedActionQuestionGroup,
      ...displayedSingleActionQuestion,
    ];
  }

  public createMDR(data: {
    mdr: MDRType & { value?: string };
    target?: TargetFieldsFragment_;
    action?: ActionFieldsFragment_;
  }) {
    const { mdr, target, action } = data;
    const title = mdr.title as string;

    const titleParagraph = new Paragraph({
      text: title,
      style: TextStyle.h4,
      spacing: {
        after: 100,
      },
    });

    const field = mdr?.secondField ?? mdr.field;
    const fieldAnswer = !!target
      ? target?.[field as keyof TargetFieldsFragment_]
      : action?.[field as keyof ActionFieldsFragment_];
    const answer = mdr?.value ?? fieldAnswer;
    const isNumeric = mdr.type === 'Number' || (!isNaN(answer) && !isNaN(parseFloat(answer)));

    const answerParagraphs = new Paragraph({
      text: !!answer ? String(answer) : 'No answer provided.',
      style: !!answer ? TextStyle.body : TextStyle.bodyItalic,
      spacing: {
        afterAutoSpacing: true,
      },
    });
    if (mdr.field === 'none') return [titleParagraph];
    return !!answer && !isNumeric ? [answerParagraphs] : [titleParagraph, answerParagraphs];
  }

  public createTargetActionQuestion(
    question:
      | EsrsReportStandard['actionQuestions'][number]
      | EsrsReportStandard['targetQuestions'][number],
    answers: any,
    companyCurrency: string,
    indent?: boolean
  ) {
    const title = question.title;

    const titleParagraph = new Paragraph({
      text: title,
      style: TextStyle.h4,
      spacing: {
        after: 100,
      },
      indent: {
        left: indent ? 400 : 0,
      },
    });

    const answer = answers?.[question.reference]?.value;
    const isNumeric = question.questionType === QuestionType_Enum_.Decimal_;

    const unit = mapUnitToCompanyCurrency(question.unit, companyCurrency);
    const answerWithUnit = isNumeric ? String(`${unit} ${answer}`) : String(answer);

    const answerParagraphs = new Paragraph({
      text: !!answer ? answerWithUnit : 'No answer provided.',
      style: !!answer ? TextStyle.body : TextStyle.bodyItalic,
      spacing: {
        afterAutoSpacing: true,
      },
      indent: {
        left: indent ? 400 : 0,
      },
    });

    return !!answer && !isNumeric ? [answerParagraphs] : [titleParagraph, answerParagraphs];
  }

  public createTargetsSummaryTable(targets: TargetFieldsFragment_[]) {
    if (!targets?.length) return [];

    const nbMilestoneYears = Math.max(...targets.map((t) => t.milestones?.length ?? 0));
    const milestoneMap = Array.from({ length: nbMilestoneYears }, (_, i) => i);

    const titleParagraph = new Paragraph({
      text: 'Summary',
      style: TextStyle.h4,
      spacing: {
        after: 100,
      },
    });

    const columnsNb = 2 + nbMilestoneYears;
    const firstColumnWidth = 10000 - columnsNb * 1000;

    const targetsTable = new Table({
      columnWidths: [firstColumnWidth, 1000, 1000, ...milestoneMap.map(() => 1000)],
      margins: {
        top: 60,
        bottom: 60,
        right: 60,
        left: 60,
      },
      borders: tableBorders,
      rows: [
        new TableRow({
          tableHeader: true,
          children: [
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  text: 'Target name',
                  style: TextStyle.tableTitle,
                }),
              ],
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  text: 'MDR-T Baseline value (decimal)',
                  style: TextStyle.tableTitle,
                }),
              ],
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  text: 'MDR-T Baseline year (decimal)',
                  style: TextStyle.tableTitle,
                }),
              ],
            }),
            ...milestoneMap.map(
              (index) =>
                new TableCell({
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      text:
                        index === nbMilestoneYears - 1 ? 'End target' : `Milestone ${index + 1}`,
                      style: TextStyle.tableTitle,
                    }),
                  ],
                })
            ),
          ],
        }),
        ...targets.map((target) => this.createTargetRows(target, milestoneMap)),
      ].flat(),
    });

    return [titleParagraph, targetsTable];
  }

  public createTargetRows(target: TargetFieldsFragment_, milestoneMap: number[]) {
    const baseline = target.keyResults.find((kr) => kr.reportingUnitId === null);

    const displayedRows = [
      new TableRow({
        children: [
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text: target.name,
                style: TextStyle.body,
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text: baseline?.baseline ?? 'NA',
                style: TextStyle.body,
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text: baseline?.baseYear ? `${new Date(baseline?.baseYear).getFullYear()}` : 'NA',
                style: TextStyle.body,
              }),
            ],
          }),
          ...milestoneMap.map((index) => {
            const milestones = sortBy(target.milestones, 'year');
            const milestone = milestones[index];
            const milestoneResult = milestone?.milestoneResults.find(
              (mr) => mr.reportingUnitId === null
            )?.value;

            const targetValue =
              Number(baseline?.baseline) !== 0
                ? percentageToNumber(milestoneResult, Number(baseline?.baseline))
                : milestoneResult;

            return new TableCell({
              verticalAlign: VerticalAlign.TOP,
              children: [
                new Paragraph({
                  text: !milestone
                    ? ''
                    : isNaN(milestoneResult) || isNaN(targetValue)
                      ? 'NA'
                      : `${targetValue} (${milestone.year})`,
                  style: TextStyle.body,
                }),
              ],
            });
          }),
        ],
      }),
    ];
    return displayedRows.flat();
  }
}
