export const S1_6GenderMetrics = [
  'employeesGender',
  'totalEmployeesByContractGender',
  'employeesContract2',
];

export const S1_6CountryMetrics = [
  'employeesCountry',
  'totalEmployeesContractGenderAndCountry',
  'employeesContract2',
];

export const S1_6Metrics = [
  'totalEmployees',
  'totalEmployeesByContractGender',
  'totalEmployeesContractGenderAndCountry',
  'employeesContract2',
  'employeesGender',
  'employeesCountry',
];

export const S1_8Metrics = [
  'percentageBargainingEEA',
  'percentageBargainingNonEEA',
  'percentageRepresentativesEEA',
];

export const GOV_4ParentMetric =
  'disclosureofmappingofinformationprovidedinsustainabilitystatementaboutduediligenceprocess';

export const GOV_4Metrics = [
  'coreElement1',
  'coreElement2',
  'coreElement3',
  'coreElement4',
  'coreElement5',
];

export const E1_5Metrics = [
  'percentageFossilConsumption',
  'percentageRenewableConsumption',
  'percentageNuclearConsumption',
  'totalEnergyConsumption',
];

export const E1_6_Target_Metrics = [
  'grossScopeGHG1',
  'percentageScope1TradingSchemes',
  'grossScopeGHG2LocationBased',
  'grossScopeGHG2MarketBased',
  'grossScopeGHG3',
  'scope3Cat1',
  'scope3Cat1sub',
  'scope3Cat2',
  'scope3Cat3',
  'scope3Cat4',
  'scope3Cat5',
  'scope3Cat6',
  'scope3Cat7',
  'scope3Cat8',
  'scope3Cat9',
  'scope3Cat10',
  'scope3Cat11',
  'scope3Cat12',
  'scope3Cat13',
  'scope3Cat14',
  'scope3Cat15',
  'totalGHGLocationBased',
  'totalGHGDerivedScope2MarketBased',
];

export const hardcodedMetrics = [
  ...S1_6Metrics,
  ...S1_8Metrics,
  GOV_4ParentMetric,
  ...GOV_4Metrics,
  ...E1_5Metrics,
];
