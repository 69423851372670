import React from 'react';
import { getE1_5MetricsData } from '../../DocGenerator/CustomTables/energy-consumption-mix';
import { EsrsDRReportMetric } from '../../Report.types';
import { ColumnDefResolved } from '@tanstack/react-table';
import { Typography } from 'Tokens';
import { TextStyle } from '../../DocGenerator/reportThemes';
import { NestedTable } from 'Molecules/NestedTable';

type TableData = {
  title: string;
  answer: string;
  style: TextStyle;
  subRows?: [];
};

export const E1_5Table = ({
  metrics,
  year,
}: {
  metrics: EsrsDRReportMetric['tableData'][];
  year: number | undefined;
}) => {
  const data = getE1_5MetricsData(metrics);

  const columns: ColumnDefResolved<TableData>[] = [
    {
      header: 'Energy consumption and mix',
      accessorKey: 'title',
      meta: {
        width: '80%',
      },
      cell: ({ row }) => (
        <Typography variant={row.original.style === 'bodyBold' ? 'bodyStrong' : 'body'}>
          {row.original.title}
        </Typography>
      ),
    },
    {
      header: `${year ?? ''}`,
      accessorKey: 'answer',
      cell: ({ row }) => <Typography variant="body">{row.original.answer}</Typography>,
    },
  ];

  return (
    <NestedTable<TableData>
      columns={columns}
      data={data}
      withOuterBorder={true}
      borderRadius="0px"
      rowHeight="36px"
      pageSize={20}
    />
  );
};
