import { HStack } from '@chakra-ui/react';
import { Input, Button } from 'Atoms';
import { StepComponent } from 'containers/Integrations/IntegrationsUtilComponents';
import {
  EsrsAssessmentsYearsDocument_,
  GetIntegrationApiKeyDocument_,
  useUpsertIntegrationKeyMutation,
} from 'models';
import { useState } from 'react';
import { Typography } from 'Tokens';
import { useToast } from 'utils/hooks';
import { CompanyIntegration } from './MoreScopeCompany';
import { useParams } from 'react-router-dom';
import { CheckIcon } from 'Tokens/Icons/Status';

export const IntegrationInput = ({ integration }: { integration?: CompanyIntegration }) => {
  const [apiKey, setApiKey] = useState<string>(integration?.apiKey ?? '');
  const [savingState, setSavingState] = useState<number>(integration?.apiKey ? 2 : 0);
  const [upsertIntegrationKey] = useUpsertIntegrationKeyMutation();
  const toast = useToast();
  const { companyId = '' } = useParams();

  const onConfirm = async () => {
    if (savingState === 0) {
      setSavingState(1);
      try {
        await upsertIntegrationKey({
          variables: {
            object: {
              id: integration?.id,
              companyId: companyId,
              integrationSystemName: 'moreScope',
              apiKey: apiKey,
              isDisaggregated: false,
            },
          },
          refetchQueries: [GetIntegrationApiKeyDocument_, EsrsAssessmentsYearsDocument_],
        });
        setSavingState(2);
        toast({ text: 'API key saved' });
      } catch {
        setSavingState(0);
        toast({ text: 'Failed to save Api Key', variant: 'danger' });
      }
    }
  };
  return (
    <StepComponent title="Add your API key">
      <HStack width={'100%'}>
        <Input
          value={apiKey}
          width={'100%'}
          placeholder="Enter API key"
          type="password"
          onChange={(e) => {
            setApiKey(e.target.value);
            if (savingState != 0) {
              setSavingState(0);
            }
            if (savingState === 0) {
              if (e.target.value === integration?.apiKey) {
                setSavingState(2);
              }
            }
          }}
        />
        <Button
          variant={'primary'}
          onClick={() => onConfirm()}
          isLoading={savingState === 1}
          background={savingState === 2 ? 'bg.selected' : 'bg.action.accent'}
          selected={savingState === 2}
        >
          {savingState === 0 || savingState === 1 ? (
            'Save'
          ) : (
            <HStack>
              <CheckIcon color={'text.selected'} />
              <Typography color={'text.selected'}>Saved</Typography>
            </HStack>
          )}
        </Button>
      </HStack>
    </StepComponent>
  );
};
