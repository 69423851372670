import { useCallback, useEffect, useMemo, useState } from 'react';
import { MdrmAnswerType, MdrmQuestionType } from './MDRM.types';
import {
  GetDatapointMdrmAnswersDocument_,
  User,
  useUpdateDatapointMdrmOptOutMutation,
  useUpsertDatapointMdrmMutation,
} from 'models';
import { Menu } from 'Molecules/Menu';
import { useNotifications } from 'Features';
import { useMdrmCommentThread } from './MDRMSidebar.hooks';
import dayjs from 'dayjs';
import {
  Box,
  Flex,
  HStack,
  SkeletonCircle,
  SkeletonText,
  Stack,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ThreadBody } from '../MetricSidebar/MetricComments';
import { AggregatedMetricsTableData, MetricsTableData } from 'containers/Esrs';
import { Typography } from 'Tokens';
import { Tag, Tooltip, TruncatableText } from 'Atoms';
import { HelpIcon } from 'Tokens/Icons/Status';
import { EditIcon } from 'Tokens/Icons/Data';
import { UserAvatar } from 'Organisms';
import { getLastEditedData } from '../MetricsTable/MetricsTableComponents/LastEdited/LastEdited.hooks';
import {
  InviteMembersModal,
  TeamMember,
  TeamMembersFilterOption,
  useTeamMembers,
} from 'containers/TeamMembers';
import { ProjectLeaderSelect } from 'containers/Esrs/pieces/Assessment/EsrsConfig/ProjectLeaderSelect';

const MDRMLastEdited = ({ mdrm }: { mdrm: MdrmAnswerType }) => {
  const lastEdited = getLastEditedData(mdrm.updated_at, mdrm.author);
  if (lastEdited.user) {
    return (
      <HStack spacing="4px">
        <UserAvatar user={lastEdited.user} size="xs" />
        <Typography variant="body">{lastEdited.date}</Typography>
      </HStack>
    );
  }

  return <Typography variant="body">—</Typography>;
};

const MDRMOwnerSelect = ({
  mdrm,
  datapointId,
  assessmentProjectLeader,
}: {
  mdrm: MdrmAnswerType;
  datapointId: string;
  assessmentProjectLeader?: Partial<User>;
}) => {
  const [upsertDatapointMdrm] = useUpsertDatapointMdrmMutation();
  const {
    isOpen: isInvitationModalOpen,
    onOpen: onInvitationModalOpen,
    onClose: onInvitationModalClose,
  } = useDisclosure();

  const { members } = useTeamMembers(TeamMembersFilterOption.All);
  const mdrmOwner = useMemo(() => {
    return mdrm?.owner ? mdrm.owner : assessmentProjectLeader;
  }, [mdrm, assessmentProjectLeader]);

  const updateMdrm = (member: TeamMember) => {
    upsertDatapointMdrm({
      variables: {
        object: {
          id: mdrm.id,
          mdrmRef: mdrm.mdrmRef,
          value: mdrm.value,
          authorId: mdrm.authorId,
          ownerId: member.id,
          datapointId: datapointId,
        },
      },
      refetchQueries: [GetDatapointMdrmAnswersDocument_],
    });
  };

  return (
    <HStack spacing="4px" onClick={(e) => e.stopPropagation()}>
      <ProjectLeaderSelect
        onChange={updateMdrm}
        defaultProjectLeader={mdrmOwner}
        avatarText={mdrmOwner?.displayName ?? mdrmOwner?.email}
        isBorderless={true}
        members={members}
        onInvitationModalOpen={onInvitationModalOpen}
        rounded
        small
        size="xs"
      />
      <InviteMembersModal isOpen={isInvitationModalOpen} onClose={onInvitationModalClose} />
    </HStack>
  );
};

export const MDRMOptOutMenu = ({ mdrm }: { mdrm: MdrmAnswerType }) => {
  const [updateOptOut] = useUpdateDatapointMdrmOptOutMutation();
  const [isOptedOut, setIsOptedOut] = useState<boolean>(mdrm?.hasOptedOut ?? false);

  useEffect(() => {
    setIsOptedOut(mdrm?.hasOptedOut ?? false);
  }, [mdrm]);

  const handleChange = async () => {
    try {
      await updateOptOut({
        variables: {
          id: mdrm?.id,
          hasOptedOut: !isOptedOut,
        },
        refetchQueries: [GetDatapointMdrmAnswersDocument_],
      });
      setIsOptedOut(!isOptedOut);
    } catch (error) {
      console.error('Failed to update MDR-M opt out status', error);
    }
  };

  return (
    <Menu
      buttonVariant="secondary"
      sections={[
        {
          actions: [
            isOptedOut
              ? {
                  id: 'start-reporting',
                  title: 'Start Reporting',
                  description:
                    'You are opted out from reporting on this MDR-M. You can start reporting again',
                  onClick: () => handleChange(),
                }
              : {
                  id: 'opt-out',
                  title: 'Mark as irrelevant',
                  onClick: () => handleChange(),
                },
          ],
        },
      ]}
      size={isOptedOut ? 'md' : 'sm'}
    />
  );
};

export const MDRMComments = ({
  mdrm,
  datapointId,
}: {
  mdrm: MdrmAnswerType;
  datapointId: string;
}) => {
  const count = mdrm?.thread?.comments_aggregate.aggregate?.count ?? 0;
  const skeletons = Array(count + 1).fill(0);
  const { notifications, readNotifications } = useNotifications();
  const { addComment, deleteComment, thread, loading } = useMdrmCommentThread({
    id: mdrm?.thread?.id,
    datapointId: datapointId,
    mdrmId: mdrm?.id,
  });
  const handleAddComment = useCallback(
    (comment: string) => {
      if (comment) {
        addComment(comment);
      }
      return Promise.resolve({});
    },
    [addComment]
  );
  const comments = useMemo(
    () => thread?.comments?.slice().sort((a, b) => dayjs(b.createdAt).diff(a.createdAt)) || [],
    [thread]
  );

  useEffect(() => {
    readNotifications(
      notifications
        .filter((notf) => notf.comment.thread.esrsDatapointMdrm?.id === mdrm?.id)
        .map((notf) => notf.id)
    );
  }, [readNotifications]);

  if (loading) {
    return (
      <Stack spacing="20px" p="16px 8px" flexGrow="1">
        {skeletons?.map((_, i) => (
          <HStack
            key={i}
            w="100%"
            padding="8px"
            borderRadius="4px"
            boxShadow="sm"
            spacing="10px"
            h="fit-content"
          >
            <SkeletonCircle size="8" />
            <Box w="80%" h="100%">
              <SkeletonText noOfLines={3} spacing="4" />
            </Box>
          </HStack>
        ))}
      </Stack>
    );
  }
  return (
    <ThreadBody addComment={handleAddComment} deleteComment={deleteComment} comments={comments} />
  );
};

export const MDRMSource = ({
  mdrm,
  datapointId,
}: {
  mdrm: MdrmAnswerType;
  datapointId: string;
}) => {
  return (
    <Box h="100%" display="flex" flexGrow="1" flexDirection="column">
      <VStack alignItems="start" width="100%" spacing="10px" padding="24px 20px">
        <Box w="100%" borderBottom="1px solid" borderColor="border.decorative" pb="4px">
          <Typography variant="h3">Governance</Typography>
        </Box>

        <HStack w="100%" py="4px" alignItems="start">
          <Box>
            <Typography variant="body" width="120px">
              Answer
            </Typography>
          </Box>
          <TruncatableText text={mdrm.value ?? '—'} variant="body" noOfLines={3} />
        </HStack>

        <HStack w="100%" py="4px" alignItems="start">
          <Typography variant="body" width="120px">
            Last edited
          </Typography>
          <MDRMLastEdited mdrm={mdrm} />
        </HStack>

        <HStack w="100%" py="4px" alignItems="start">
          <Typography variant="body" width="120px">
            Owner
          </Typography>
          <MDRMOwnerSelect mdrm={mdrm} datapointId={datapointId} />
        </HStack>
      </VStack>

      <VStack alignItems="start" width="100%" spacing="10px" padding="24px 20px" flexGrow="1">
        <Box w="100%" borderBottom="1px solid" borderColor="border.decorative" pb="4px">
          <Typography variant="h3">Source</Typography>
        </Box>

        <HStack w="100%" alignItems="start" py="4px">
          <Typography width="120px" variant="body">
            Type
          </Typography>
          <Tag variant="undefined" size="xs" leftIcon={<EditIcon />}>
            <HStack spacing="6px">
              <Typography variant="detailStrong">Manual input</Typography>
              <Tooltip label="This data point is manually added by a person">
                <Flex justifyContent="center">
                  <HelpIcon color="inherit" opacity={0.4} />
                </Flex>
              </Tooltip>
            </HStack>
          </Tag>
        </HStack>
      </VStack>
    </Box>
  );
};

export const MDRMOverview = ({
  mdrmQuestion,
  metric,
}: {
  mdrmQuestion: MdrmQuestionType | undefined;
  metric: MetricsTableData | AggregatedMetricsTableData;
}) => {
  return (
    <VStack alignItems="start" width="100%" p="24px 20px" spacing="12px">
      <HStack w="100%" py="4px" alignItems="start">
        <Typography variant="body" width="120px">
          ESRS metric
        </Typography>
        <Typography variant="body" width="120px">
          {metric.metric.title}
        </Typography>
      </HStack>

      <HStack w="100%" py="4px" alignItems="start">
        <Typography variant="body" width="120px">
          MDR-M question
        </Typography>
        <Typography variant="body" width="120px">
          {mdrmQuestion?.question ?? '—'}
        </Typography>
      </HStack>
    </VStack>
  );
};
