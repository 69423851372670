import { SettingsIcon } from 'Tokens/Icons/Function';
import { useDisclosure, VStack } from '@chakra-ui/react';
import { Button, Infobox } from 'Atoms';
import { ContentHeader, ContentLayout, Loader } from 'Molecules';
import { useParams } from 'react-router-dom';
import { CompanyIcon } from 'Tokens/Icons/Data';
import { MoreScopeCompany } from './MoreScope/MoreScopeCompany/MoreScopeCompany';
import { MoreScopeBusinessUnits } from './MoreScope/MoreScopeBusinessUnits/MoreScopeBusinessUnits';
import { MoreScopeIntegrationModal } from './MoreScopeIntegrationModal';
import { useGetIntegrationApiKeyQuery } from 'models';
import { useMemo } from 'react';
import { CompanyType, useCompanyType } from 'utils/hooks';

export const MoreScopeIntegration = () => {
  const { companyId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { companyType } = useCompanyType();
  const { data, loading } = useGetIntegrationApiKeyQuery({
    variables: {
      companyId,
      integrationSystemName: 'moreScope',
    },
  });
  const integration = useMemo(() => data?.esrs_CompanyIntegration?.[0], [data]);

  if (loading) {
    return <Loader />;
  }
  return (
    <ContentLayout header={<></>} variant={'wide'} backButton={true}>
      <VStack
        width={!integration?.isDisaggregated ? '840px' : '880px'}
        alignItems={'baseline'}
        alignSelf={'center'}
      >
        <ContentHeader title="MoreScope" />
        <Infobox
          status="neutral"
          title={
            companyType !== CompanyType.group
              ? !integration?.isDisaggregated
                ? 'Sync MoreScope data on company level'
                : 'Sync MoreScope data per business units'
              : 'Sync MoreScope data on group level'
          }
          description={
            companyType !== CompanyType.group
              ? !integration?.isDisaggregated
                ? 'You have selected to sync data at the company level. To do so, enter the company’s API key and sync data for all available assessment years. If your data is disaggregated by business unit, go to settings to choose a different setup.'
                : 'You have selected to sync data by business unit. To do so, enter the API keys for all business units. If you have only one API key for your company, go to settings to choose a different setup.'
              : 'You have selected to sync data at the group level. To do so, enter the API key and sync data for all available assessment years.'
          }
          icon={<CompanyIcon />}
          closable={false}
          rightElement={
            companyType !== CompanyType.group && (
              <Button
                variant="ghost"
                leftIcon={<SettingsIcon color={'inherit'} />}
                onClick={() => onOpen()}
              >
                Settings
              </Button>
            )
          }
          mt={'10px'}
        />
        {!integration?.isDisaggregated ? <MoreScopeCompany /> : <MoreScopeBusinessUnits />}
      </VStack>
      {isOpen && <MoreScopeIntegrationModal isOpen={isOpen} onClose={onClose} />}
    </ContentLayout>
  );
};
