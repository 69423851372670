import { HStack } from '@chakra-ui/react';
import { Tooltip } from 'Atoms';
import { Typography } from 'Tokens';
import { WarningIcon } from 'Tokens/Icons/Status';

export const MetricsWarningInputPage = ({
  warningIconColor,
  padding,
  toolTipLabel,
  contentColor,
  content,
}: {
  warningIconColor: string;
  padding: string;
  toolTipLabel: string;
  contentColor: string;
  content: string;
}) => {
  return (
    <HStack justifyContent="left" w="100%" spacing={0} pl={padding} pb={'8px'}>
      <WarningIcon boxSize="12px" color={warningIconColor} />
      <Tooltip label={toolTipLabel} placement="bottom-start">
        <Typography fontSize="12px" fontWeight={'400'} ml={1} color={contentColor} noOfLines={1}>
          {content}
        </Typography>
      </Tooltip>
    </HStack>
  );
};
