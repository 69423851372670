import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import {
  AggregatedMetricsTableData,
  filterMetricLevels,
  getNestedRows,
  MetricsTableData,
  MetricType,
  useGetAggregatedMetrics,
  useGetAggregatedMetricsData,
} from '../..';
import {
  GetMetricsDrQuery_,
  useGetRequiredBuMaterialMetricsQuery,
  useReportingUnitsMetricsPerDisclosureQuery,
} from 'models';
import { uniq } from 'lodash';
import { isMetricStateMaterial } from 'containers/Esrs/utils';
import { separateQualitativeMetricsFromQuantitativeParents } from '../../Metrics.utils';

export type GeneratedSummaryStatus =
  | {
      metricRef: string;
      isApproved: boolean;
    }
  | undefined;

export type AssessableMetrics = NonNullable<
  GetMetricsDrQuery_['DisclosureRequirement_by_pk']
>['metrics'];

export const useGetMetrics = (
  withAssociation: boolean,
  companyAssessmentId: string,
  parentAssessmentId: string,
  standardRef: string,
  isBusinessUnit: boolean
) => {
  const {
    metricDR,
    metrics,
    companyLevelReportingUnitId,
    projectLeader,
    isGroup,
    requiredMaterialMetrics,
    companyAnswersData,
    groupAnswersData,
    optedOutMetrics,
    dataLoading,
  } = useGetAggregatedMetricsData(
    withAssociation,
    companyAssessmentId,
    parentAssessmentId,
    isBusinessUnit
  );

  const {
    aggregatedMetrics,
    qualitativeMetrics: aggregatedQualitativeMetrics,
    aggregatedMetricsAnswers,
  } = useGetAggregatedMetrics(
    metrics ?? [],
    standardRef,
    companyAssessmentId,
    companyAnswersData,
    groupAnswersData,
    isGroup
  );
  return {
    aggregatedMetrics,
    qualitativeMetrics: aggregatedQualitativeMetrics,
    aggregatedMetricsAnswers,
    companyLevelReportingUnitId,
    optedOutMetrics,
    dataLoading,
    projectLeader,
    requiredMaterialMetrics,
    drData: metricDR,
  };
};

export const useGetBuMetrics = (
  drRef?: string,
  companyAssessmentId?: string,
  parentAssessmentId?: string,
  isBusinessUnit?: boolean
) => {
  const { data: requiredBUMaterialMetrics, loading: requiredmmLoading } =
    useGetRequiredBuMaterialMetricsQuery({
      variables: {
        disclosureRequirementRef: drRef ?? '',
        materialStandardId: companyAssessmentId,
        parentStandardId: parentAssessmentId || companyAssessmentId,
      },
      skip: !drRef || !companyAssessmentId || !isBusinessUnit,
    });
  const requiredMetrics =
    uniq(requiredBUMaterialMetrics?.esrs_MaterialMetric?.map((mm) => mm.metric.reference)) ?? [];

  const { data: buData, loading } = useReportingUnitsMetricsPerDisclosureQuery({
    variables: {
      disclosureRequirementRef: drRef ?? '',
      companyAssessmentId,
      requiredMetrics: requiredMetrics,
      parentAssessmentId: parentAssessmentId || companyAssessmentId,
    },
    skip: !drRef || !companyAssessmentId || !requiredMetrics.length || !isBusinessUnit,
  });
  return {
    BUmetrics: buData?.assessableMetrics ?? ([] as AssessableMetrics),
    requiredBUMaterialMetrics: requiredBUMaterialMetrics?.esrs_MaterialMetric,
    buDataLoading: requiredmmLoading,
    loading,
  };
};

export const processBuMetrics = (
  BUmetrics: AssessableMetrics,
  companyAssessmentId: string,
  parentAssessmentId: string
) => {
  const filteredMetrics = separateQualitativeMetricsFromQuantitativeParents(
    filterMetricLevels(BUmetrics, companyAssessmentId)
  );

  const nestedMetrics = filteredMetrics.map((metric) => {
    const materialStandardId = isMetricStateMaterial(
      metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)?.isMaterial
    )
      ? companyAssessmentId
      : parentAssessmentId;
    return getNestedRows({ metric, materialStandardId, isGroup: false });
  });

  return nestedMetrics;
};

export const filterDuplicateRows = (
  aggregatedMetrics: AggregatedMetricsTableData[],
  nestedBuMetrics: MetricsTableData[],
  isBusinessUnit: boolean
) => {
  const metricArray: string[] = [];
  const filterMetrics = (metric: MetricsTableData, hidden?: boolean): MetricsTableData => {
    if (metric.subRows) {
      if (metricArray.includes(metric.metric.reference) && metric.parentMetric) {
        return {
          ...metric,
          hidden: hidden,
          subRows: [
            {
              metric: metric.metric,
              referenceToSource: metric.metric?.reference,
              hidden: hidden,
              subRows: metric.subRows.map((subrow) => ({
                ...subrow,
                hidden: true,
                subRows: subrow?.subRows?.map((row) => filterMetrics(row, true)),
              })),
            },
          ],
        };
      }
      metricArray.push(metric.metric.reference);
      const subRows = metric?.subRows?.map((row) => filterMetrics(row, hidden));
      if (subRows) {
        return { ...metric, subRows: subRows, hidden: hidden };
      }
    }
    return { ...metric, hidden: hidden };
  };
  const metrics = !isBusinessUnit ? aggregatedMetrics : nestedBuMetrics;
  return [...metrics.map((metric) => filterMetrics(metric))];
};

export const getRemainingRows = (
  numberOfRows: Record<string, number>,
  metric: MetricsTableData
) => {
  const getTotalRows = (data: MetricsTableData[]): number => {
    let totalRows = data.filter((row) => !row.hidden).length;
    for (const row of data) {
      if (row.subRows && row.subRows.length > 0) {
        totalRows += getTotalRows(row.subRows);
      }
    }
    return totalRows;
  };
  const rowsCount = getTotalRows([metric]);
  const remainingCount =
    rowsCount - (numberOfRows[metric.metric?.reference ?? ''] ?? 5) > 10
      ? 10
      : rowsCount - (numberOfRows[metric.metric?.reference ?? ''] ?? 5);
  return remainingCount;
};

export const validateChildrenMetricsDataCollectionLevel = (
  dataCollectionLevel: string | null,
  isGroup: boolean,
  isBusinessUnit: boolean
) => {
  return isGroup
    ? dataCollectionLevel === DataCollectionLevel.group
    : !isBusinessUnit
      ? dataCollectionLevel === DataCollectionLevel.company
      : dataCollectionLevel === DataCollectionLevel.reportingUnits;
};

export const metricsMissingRequiredTags = (
  metrics: MetricType[],
  companyAssessmentId: string,
  isGroup: boolean,
  isBusinessUnit: boolean
) => {
  let hasMissingTags: boolean = false;
  const metricsMissingTagsList: string[] = [];

  metrics.forEach((metric) => {
    const dataCollectionLevel = metric.materialMetrics[0]?.dataCollection;
    let validateChildrenMetrics;
    const hasChildrenMetrics = metric.childrenMetrics.length > 0;

    const materialMetric = metric.materialMetrics?.find(
      (mm) => mm.materialStandardId === companyAssessmentId
    );
    const requiredTags = metric.adminPanelTags.filter((tag) => !tag.isOptional);

    const isMissingTag = requiredTags.some((requiredTag) => {
      const matchingTag = materialMetric?.materialMetricTags?.find(
        (tag) => tag.tagType === requiredTag.type
      );
      return !matchingTag || matchingTag.materialTagValues.length === 0;
    });

    if (hasChildrenMetrics) {
      validateChildrenMetrics = metric.childrenMetrics?.some((childMetric) => {
        const childDataCollection = childMetric.childMetric?.materialMetrics?.[0]?.dataCollection;
        if (!childDataCollection) return false;

        return validateChildrenMetricsDataCollectionLevel(
          childDataCollection,
          isGroup,
          isBusinessUnit
        );
      });
    } else {
      validateChildrenMetrics = validateChildrenMetricsDataCollectionLevel(
        dataCollectionLevel ?? '',
        isGroup,
        isBusinessUnit
      );
    }

    if (isMissingTag && validateChildrenMetrics) {
      metricsMissingTagsList.push(metric.reference);
      hasMissingTags = true;
    }
  });
  return [metricsMissingTagsList, hasMissingTags] as [string[], boolean];
};

export const flattenMetricsNotAggregatable = (
  metrics: AggregatedMetricsTableData[]
): AggregatedMetricsTableData[] => {
  const result = [];

  for (const row of metrics) {
    result.push({ ...row });
    if (row.subRows && row.subRows.length > 0) {
      result.push(...flattenMetricsNotAggregatable(row.subRows));
    }
  }
  return result;
};

export const flattenMetricsWithTags = (metricsWithTags: AggregatedMetricsTableData[]) => {
  const metricsData: MetricType[] = [];

  const flattenChildrenMetric = (
    metrics: AggregatedMetricsTableData['metric']['childrenMetrics']
  ) => {
    metrics.forEach((metric) => {
      if (metric.childMetric) {
        metricsData.push(metric.childMetric);
        if (metric.childMetric?.childrenMetrics?.length > 0) {
          flattenChildrenMetric(metric.childMetric?.childrenMetrics);
        }
      }
    });
  };

  metricsWithTags.forEach((metric) => {
    metricsData.push(metric.metric);
    if (metric.metric?.childrenMetrics?.length > 0) {
      flattenChildrenMetric(metric.metric?.childrenMetrics);
    }
  });

  return metricsData;
};
