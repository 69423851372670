import {
  RadioGroup,
  CheckboxGroup,
  Textarea,
  HStack,
  VStack,
  Stack,
  FormControl,
} from '@chakra-ui/react';
import clsx from 'clsx';
import { Radio, Checkbox, Button, Input, NumberInput, NumberInputProps } from 'Atoms';
import React, { useMemo, Fragment, useState, useEffect, useCallback, useRef } from 'react';
import { debounce, isArray } from 'lodash';
import { tnoop, useTranslation } from 'utils/translation';
import { QuestionType } from 'utils/scores/questions';
import { Select } from 'Molecules';
import { useCurrentQuestion, useIsReadOnlyContext } from 'context';
import { ResolvedQuestion } from 'models';
import { DeleteIcon } from 'Tokens/Icons/Function';

const NumberInputWithNanFix = React.forwardRef(function NumberInputWithNanFix(
  { value, onChange, unit, ...rest }: NumberInputProps & { unit?: string | null },
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = e.clipboardData.getData('text');
    const parsedValue = parseFloat(pastedValue);
    if (!isNaN(parsedValue)) {
      onChange?.(String(parsedValue), parsedValue);
    }
    e.preventDefault();
  };
  return (
    <NumberInput
      size="lg"
      value={Number.isNaN(value) ? 0 : (value ?? 0)}
      onChange={(v) => onChange?.(String(v), v)}
      {...rest}
      ref={ref}
      unit={unit}
      onPaste={handlePaste}
    />
  );
});

const Type = QuestionType;
const STANDARD_CHOICES = {
  YES: tnoop('question:choices.yes'),
  NO: tnoop('question:choices.no'),
  NA: tnoop('question:choices.na'),
  UNKNOWN: tnoop('question:choices.unknown'),
};
const NONE_OF_ABOVE = 'none';
const getChoices = (choices: (keyof typeof STANDARD_CHOICES)[]) =>
  choices.map((ch, i) => ({ value: ch, displayName: STANDARD_CHOICES[ch], order: String(i) }));

type InputConfig = {
  [type in QuestionType]: {
    choices?: ResolvedQuestion['choices'];
    InputComponent?: typeof Checkbox | typeof Radio | typeof Input | 'option';
    GroupComponent?: typeof RadioGroup | typeof CheckboxGroup;
    Stack?: typeof Stack;
    default?: any;
    delay: number;
  };
};

const getRadioConfig = (type: QuestionType) => ({
  choices: getChoices(type.split('_') as any),
  InputComponent: Radio,
  GroupComponent: RadioGroup,
  delay: 0,
  default: '',
  Stack: HStack,
});

export const QUESTION_INPUT_CONFIG = {
  [Type.YesNo_]: getRadioConfig(Type.YesNo_),
  [Type.YesNoNa_]: getRadioConfig(Type.YesNoNa_),
  [Type.YesNoNaUnknown_]: getRadioConfig(Type.YesNoNaUnknown_),
  [Type.Decimal_]: { delay: 1000, InputComponent: NumberInputWithNanFix, default: 0 },
  [Type.Integer_]: { delay: 1000, InputComponent: NumberInputWithNanFix, default: 0 },
  [Type.LongText_]: { delay: 3000, InputComponent: Textarea },
  [Type.ShortText_]: { delay: 3000, InputComponent: Input },
  [Type.SingleChoice_]: {
    InputComponent: Radio,
    GroupComponent: RadioGroup,
    delay: 0,
    Stack: VStack,
  },
  [Type.SingleChoiceDropdown_]: { delay: 0 },
  [Type.MultipleChoice_]: {
    delay: 1000,
    InputComponent: Checkbox,
    GroupComponent: CheckboxGroup,
    Stack: VStack,
    default: [],
  },
  [Type.Other_]: { delay: 1000, InputComponent: Input },
  [Type.MultipleCriteria_]: { delay: 1000, InputComponent: Input },
} as unknown as InputConfig;

function filterMultipleChoiceNone(value: any, prevValue: string[], type: QuestionType) {
  if (type != Type.MultipleChoice_ || !isArray(value)) return value;
  const isNoneSelected = value.includes(NONE_OF_ABOVE);
  if (isNoneSelected) {
    const hasSelectedNone = !prevValue || !prevValue.includes(NONE_OF_ABOVE);
    if (hasSelectedNone) return [NONE_OF_ABOVE];
    const hasSelectedSomethingElse = value.length > 1;
    if (hasSelectedSomethingElse) return value.filter((v) => v !== NONE_OF_ABOVE);
  }
  if (value.length == 0) return null;
  return value;
}

export function CriteriaInput({
  type,
  readOnly = false,
  qChoices,
  value,
  onChange,
  isEditable = true,
  unit,
}: {
  type: QuestionType;
  readOnly?: boolean;
  qChoices?: ResolvedQuestion['choices'];
  value?: any;
  onChange: (newVal: any) => void;
  isEditable?: boolean | null;
  unit?: string | null;
}) {
  const { t } = useTranslation('question');
  const { delay, GroupComponent, InputComponent, default: def } = QUESTION_INPUT_CONFIG[type];
  let { choices, Stack: ChoiceStack } = QUESTION_INPUT_CONFIG[type];
  if (choices == null) choices = qChoices;
  else choices = choices.map((ch) => ({ ...ch, displayName: t(ch.displayName) }));
  if (ChoiceStack == null) ChoiceStack = Fragment as typeof Stack;

  const answerValue = value ?? def ?? '';
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentValue, setValue] = useState(answerValue);
  useEffect(() => {
    // fix existing answers if needed
    const val = filterMultipleChoiceNone(answerValue, [], type) || def;
    const inputIsFocused = document.activeElement === inputRef.current;
    if (!inputIsFocused && !(type === Type.MultipleChoice_)) setValue(val);
  }, [answerValue, type]);

  // !! REMOVING useMemo from here is causing issues.
  const onAnswerChange = useMemo(
    () => debounce((val) => onChange({ data: val }), delay),
    [onChange, delay]
  );

  const onValueChange = (newValue: any) => {
    setValue((curVal: string[]) => {
      const val = filterMultipleChoiceNone(newValue?.target?.value ?? newValue, curVal, type);
      onAnswerChange(val);
      return val;
    });
  };

  const props = { value: currentValue || def, onChange: onValueChange, isDisabled: !isEditable };
  const ClearButton = (
    <Button
      leftIcon={<DeleteIcon color="inherit" />}
      variant="ghost"
      size="sm"
      onClick={() => {
        onValueChange(null);
        onAnswerChange.flush();
      }}
      isDisabled={readOnly}
    >
      {t('question:clear') as string}
    </Button>
  );
  const getInput = () => {
    if (type === Type.Decimal_ || type === Type.Integer_)
      return (
        <HStack {...props} spacing="0px">
          <NumberInputWithNanFix
            {...props}
            width="280px"
            isDisabled={readOnly || !isEditable}
            ref={inputRef}
            unit={unit ?? undefined}
          />
          {currentValue !== def && !readOnly && ClearButton}
        </HStack>
      );
    if (type === Type.SingleChoiceDropdown_) {
      return (
        <Select
          isDisabled={readOnly}
          value={choices?.find(({ value: curVal }) => curVal === answerValue)}
          onChange={(option: any) => onValueChange(option?.value)}
          getOptionLabel={(c: any) => c.displayName}
          options={choices}
          //   ref={inputRef}
        />
      );
    }
    const Comp = (InputComponent ?? Input) as typeof Input;
    return (
      <Comp
        {...props}
        placeholder={t('question:typeAnswer')}
        isDisabled={readOnly}
        onBlur={() => onAnswerChange.flush()}
        ref={inputRef}
      />
    );
  };

  return (
    <FormControl className={clsx(`q-type-${type}`)} display="flex" flexDirection="column">
      {GroupComponent ? (
        <GroupComponent {...props} isDisabled={readOnly}>
          <ChoiceStack spacing="16px" alignItems={ChoiceStack === VStack ? 'flex-start' : 'center'}>
            {choices?.map(
              (ch: { value: string; displayName: string }) =>
                InputComponent && (
                  <InputComponent key={ch.value} value={ch.value}>
                    {ch.displayName}
                  </InputComponent>
                )
            )}
            {currentValue && currentValue.length > 0 && !readOnly && ClearButton}
          </ChoiceStack>
        </GroupComponent>
      ) : (
        getInput()
      )}
    </FormControl>
  );
}
export default function QuestionInput({ isDisabled }: { isDisabled?: boolean }) {
  const { t, ...question } = useCurrentQuestion();
  const readOnly = useIsReadOnlyContext();
  return (
    <CriteriaInput
      type={question.type}
      qChoices={question.choices}
      readOnly={readOnly || isDisabled}
      isEditable={question.isEditable}
      unit={question.unit}
      value={question.answer?.data}
      onChange={question?.onAnswerChange}
    />
  );
}
