import { VStack, useDisclosure, HStack, Skeleton, Box } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import {
  AggregatedQualitativeAnswers,
  hasQuarterlyFrequency,
  isFullMetricQuarterlyLevel,
} from '../AggregatedMetrics/AggregatedMetrics.hooks';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button } from 'Atoms';
import { LearnMoreDrawer, Loader, MetricLearnMoreHeader } from 'Molecules';
import {
  GetAnswersForDrMetricsOnCompanyLevelDocument_,
  GetAnswersForDrMetricsOnGroupLevelDocument_,
  GetAnswersForMetricsOnCompanyLevelDocument_,
  GetRequiredMaterialMetricsQuery_,
  GetSubAnswersPerDrOnGroupLevelDocument_,
  GetSubMaterialMetricsPerDrOnGroupLevelDocument_,
  QuestionType_Enum_,
  useGetReportingUnitQuery,
} from 'models';
import { TimePeriods, TimePeriodsEnums } from '../../Requirement';
import { useCompanyType, useCurrentCompany } from 'utils/hooks';
import { SelectedMetric, DREnums, MetricsTableData, useMaterialStandardId } from 'containers/Esrs';
import { OptOutModal } from '../OptOutModal';
import { useApolloClient } from '@apollo/client';
import { MetricSidebar } from '../MetricSidebar';
import { IconArrowNarrowDown } from '@tabler/icons-react';
import { MetricsAISection } from '../MetricAI';
import { MetricsExcelUploadModal } from '../MetricsExcel';
import { GroupOrderOptions } from '../AggregatedMetrics/AggregatedMetricsUtils';
import { isEqual } from 'lodash';
import { MetricsTable } from '../MetricsTable/MetricsTable';
import { AnswersApprovalWarningModal } from '../MetricAI/AIUtils';
import { MDRM } from '../MDRM/MDRM';
import { useProcessMetrics } from './ProcessMetrics/ProcessMetrics';
import { GeneratedSummaryStatus, getRemainingRows } from './ProcessMetrics/MetricsHelperFunctions';
import { MetricsHeader } from './MetricsComponents/MetricsHeader';
import { MetricConfigurationWarning } from './MetricsComponents/MetricConfigurationWarning';
import { MetricsQuarterInfo } from './MetricsComponents/MetricsQuarterInfo';
import { MetricTableHeader } from './MetricsComponents/MetricTableHeader';

export const Metrics = ({
  isGeneratingAnswers = false,
  withAssociation = false,
  rowData,
  setRowData,
  setMetricSidebarOpen,
}: {
  isGeneratingAnswers?: boolean;
  withAssociation?: boolean;
  withBorder?: boolean;
  rowData?: MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] };
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  setMetricSidebarOpen: (val: boolean) => void;
}) => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const {
    esrsAssessmentId = '',
    standardRef = '',
    disclosureRequirementRef,
    drType,
    reportingUnitId,
  } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen: isImportOpen, onOpen: onImportOpen, onClose: onImportClose } = useDisclosure();
  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>();
  const [selectedQuarter, setSelectedQuarter] = useState<TimePeriods>(TimePeriodsEnums.year);
  const [selectedRow, setSelectedRow] = useState('');
  const [groupOrOrder, setGroupOrOrder] = useState(GroupOrderOptions.byType);
  const [selectedExcelMetric, setSelectedExcelMetric] = useState<MetricsTableData>();

  const {
    isOpen: isApprovalWarningModalOpen,
    onOpen: onApprovalWarningModalOpen,
    onClose: onApprovalWarningModalClose,
  } = useDisclosure();

  const [generatedSummaryStatus, setGeneratedSummaryStatus] = useState<GeneratedSummaryStatus>();

  const { company } = useCurrentCompany();
  const [searchParams] = useSearchParams();
  const urlMetricRef = useMemo(() => searchParams.get('metricRef'), [searchParams]);
  const isBusinessUnit = useMemo(() => !!reportingUnitId, [reportingUnitId]);

  const { data: ruData } = useGetReportingUnitQuery({
    variables: { reportingUnitId },
    skip: !isBusinessUnit,
  });

  const { companyAssessmentId, parentAssessmentId } = useMaterialStandardId(
    standardRef,
    esrsAssessmentId
  );

  const { companyType } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);

  const {
    allMetrics,
    quantitativeMetrics,
    narrativeMetrics,
    summaryQualitativeMetrics,
    companyLevelReportingUnitId,
    projectLeader,
    requiredMaterialMetrics,
    optedOutMetrics,
    aggregatedMetricsAnswers,
    someTagsNotConfigured,
    notAggregatableMetrics,
    parentNotAggregatableMetrics,
    metricsMissingTagsConfiguration,
    narrativeMetricsMissingTagsConfiguration,
    drData,
    dataLoading,
  } = useProcessMetrics(
    withAssociation,
    groupOrOrder,
    selectedQuarter,
    companyAssessmentId,
    parentAssessmentId,
    isGroup
  );

  const { title = '', reference = '', description = '' } = drData || {};
  const isMetricDisclosure = useMemo(() => drType === DREnums.metric, [drType]);
  const [numberOfRows, setNumberOfRows] = useState<Record<string, number>>({});
  const [isComments, setIsComments] = useState<boolean>(false);
  const isActionOrPolicyOrTarget = useMemo(
    () => drType === DREnums.action || drType === DREnums.policy || drType === DREnums.target,
    [drType]
  );
  const isTarget = useMemo(() => drType === DREnums.target, [drType]);

  useEffect(() => {
    setRowData(undefined);
  }, [disclosureRequirementRef]);

  useEffect(() => {
    if (rowData) setMetricSidebarOpen(true);
    else setMetricSidebarOpen(false);
  }, [rowData]);

  // Quarter selection
  const areMetricsYearly = quantitativeMetrics.every(
    ({ metric }) => !hasQuarterlyFrequency(metric)
  );
  const areMetricsQuarterly = quantitativeMetrics.every(({ metric }) =>
    isFullMetricQuarterlyLevel(metric)
  );

  const isOnlyNarrative = allMetrics.every(
    (met) => met.metric.metricType !== QuestionType_Enum_.Decimal_
  );

  useEffect(() => {
    client.refetchQueries({
      include: [
        GetAnswersForMetricsOnCompanyLevelDocument_,
        ...(isGroup
          ? [
              GetAnswersForDrMetricsOnGroupLevelDocument_,
              GetSubAnswersPerDrOnGroupLevelDocument_,
              GetSubMaterialMetricsPerDrOnGroupLevelDocument_,
              GetAnswersForDrMetricsOnCompanyLevelDocument_,
            ]
          : []),
      ],
    });
  }, [isGroup]);

  useEffect(() => {
    if (areMetricsYearly) setSelectedQuarter(TimePeriodsEnums.year);
    else if (areMetricsQuarterly) setSelectedQuarter(TimePeriodsEnums.q1);
  }, [areMetricsYearly]);

  useEffect(() => {
    if (areMetricsYearly) setSelectedQuarter(TimePeriodsEnums.year);
    else if (areMetricsQuarterly) setSelectedQuarter(TimePeriodsEnums.q1);
  }, [areMetricsYearly]);

  const [isSelectedMetricYearly, isSelectedMetricQuarterly] = useMemo(() => {
    return [
      selectedExcelMetric ? !hasQuarterlyFrequency(selectedExcelMetric?.metric) : false,
      selectedExcelMetric ? isFullMetricQuarterlyLevel(selectedExcelMetric?.metric) : false,
    ];
  }, [selectedExcelMetric]);

  const showNarrativeSection =
    groupOrOrder === GroupOrderOptions.byType &&
    selectedQuarter === TimePeriodsEnums.year &&
    (!!narrativeMetrics.length || !!summaryQualitativeMetrics.length);

  //Scroll
  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(urlMetricRef ?? '');
      if (element && urlMetricRef === element.id) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }, 300);
      }
    }, 500);
  }, [urlMetricRef, quantitativeMetrics, narrativeMetrics]);

  const toggleShowMore = (category: string) => {
    setNumberOfRows((prevStates) => ({
      ...prevStates,
      [category]: (prevStates[category] || 5) + 10,
    }));
  };

  // Reset summary status when rowData changes
  useEffect(() => {
    if (!!generatedSummaryStatus && rowData?.metric.reference !== generatedSummaryStatus.metricRef)
      setGeneratedSummaryStatus(undefined);
  }, [rowData, generatedSummaryStatus]);

  if (dataLoading) return <Loader />;

  return (
    <HStack w="100%" alignItems="start" spacing="16px">
      <VStack spacing={isMetricDisclosure ? '8px' : '12px'} alignItems="start" w="100%">
        {!isActionOrPolicyOrTarget && (
          <MetricsHeader
            areMetricsYearly={areMetricsYearly}
            drDescription={description ?? ''}
            drRef={reference}
            drTitle={title}
            groupOrOrder={groupOrOrder}
            onDrawerOpen={onDrawerOpen}
            selectedQuarter={selectedQuarter}
            setSelectedMetric={setSelectedMetric}
            setGroupOrOrder={setGroupOrOrder}
            setSelectedQuarter={setSelectedQuarter}
          />
        )}

        <VStack
          spacing="40px"
          alignItems="stretch"
          w="100%"
          minH="fit-content"
          mb={isTarget ? '' : '40px'}
        >
          {(!areMetricsYearly || !(drType === DREnums.action || drType == DREnums.target)) && (
            <VStack alignItems="stretch" gap="12px" w="100%">
              {!areMetricsYearly && <MetricsQuarterInfo selectedQuarter={selectedQuarter} />}
              {!(drType === DREnums.action || drType == DREnums.target) && (
                <MetricsAISection
                  reportingUnitId={companyLevelReportingUnitId}
                  materialStandardId={companyAssessmentId}
                  disclosureRequirementRef={disclosureRequirementRef ?? ''}
                  allFilteredMetrics={allMetrics}
                  isGroup={isGroup}
                  rowData={rowData}
                  setRowData={setRowData}
                  onOpen={onOpen}
                  selectedQuarter={selectedQuarter}
                  selectedMetric={selectedMetric}
                  setSelectedMetric={setSelectedMetric}
                  esrsAssessmentProjectLeader={projectLeader}
                  aggregatedMetricsAnswers={aggregatedMetricsAnswers}
                  qualitativeMetrics={narrativeMetrics}
                  summaryQualitativeMetrics={summaryQualitativeMetrics}
                  isPolicy={drType === DREnums.policy}
                  parentStandardId={parentAssessmentId}
                  requiredMaterialMetrics={requiredMaterialMetrics ?? []}
                />
              )}
              {((someTagsNotConfigured && notAggregatableMetrics?.length === 0) ||
                notAggregatableMetrics?.length > 0) && (
                <MetricConfigurationWarning
                  navigate={navigate}
                  companyId={company?.id}
                  drRef={disclosureRequirementRef}
                  standardRef={standardRef}
                  esrsAssessmentId={esrsAssessmentId}
                  someTagsNotConfigured={someTagsNotConfigured}
                  hasNonAggregatableMetrics={notAggregatableMetrics.length > 0}
                />
              )}
            </VStack>
          )}
          {!(groupOrOrder === GroupOrderOptions.byType && isOnlyNarrative) && (
            <VStack alignItems="start" gap="20px" w="100%">
              {groupOrOrder === GroupOrderOptions.byType && (
                <Typography variant="h3">Metrics</Typography>
              )}
              <VStack alignItems="start" gap="40px" w="100%">
                {allMetrics?.map((metric) => {
                  const isNarrative = metric.metric.metricType !== QuestionType_Enum_.Decimal_;
                  const remainingCount = getRemainingRows(numberOfRows, metric);
                  return (
                    <Skeleton isLoaded={isMetricDisclosure ? true : !dataLoading} width="100%">
                      <VStack
                        alignItems="start"
                        id={metric.metric.reference}
                        spacing="6px"
                        w="100%"
                      >
                        <MetricTableHeader
                          companyAssessmentId={companyAssessmentId}
                          companyLevelReportingUnitId={companyLevelReportingUnitId}
                          metric={metric}
                          companyName={
                            isBusinessUnit ? (ruData?.bu?.name ?? '') : (company?.name ?? '')
                          }
                          drRef={disclosureRequirementRef ?? ''}
                          onImportOpen={onImportOpen}
                          setSelectedExcelMetric={setSelectedExcelMetric}
                        />
                        <MetricsTable
                          key={metric.metric.reference}
                          metrics={[metric]}
                          selectedQuarter={selectedQuarter}
                          isGroup={isGroup}
                          currency={company?.currency ?? ''}
                          isNarrative={isNarrative}
                          materialStandardId={companyAssessmentId}
                          companyReportingUnit={companyLevelReportingUnitId}
                          esrsAssessmentProjectLeader={projectLeader}
                          rowData={rowData}
                          setIsComments={setIsComments}
                          answersData={aggregatedMetricsAnswers}
                          setRowData={setRowData}
                          pageSize={numberOfRows[metric.metric.reference]}
                          allMetrics={allMetrics}
                          isBusinessUnit={isBusinessUnit}
                          setNumberOfRows={setNumberOfRows}
                          isMetricDr={isMetricDisclosure}
                          isGeneratingAnswers={isGeneratingAnswers}
                          onApprovalWarningModalOpen={onApprovalWarningModalOpen}
                          parentStandardId={parentAssessmentId}
                          requiredMaterialMetrics={
                            requiredMaterialMetrics as GetRequiredMaterialMetricsQuery_['esrs_MaterialMetric']
                          }
                          metricsMissingTagsConfiguration={metricsMissingTagsConfiguration}
                          notAggregatableMetrics={notAggregatableMetrics}
                          parentNotAggregatableMetrics={parentNotAggregatableMetrics}
                          optedOutMetrics={optedOutMetrics}
                        />
                        {remainingCount > 0 && !isNarrative && (
                          <Button
                            mt="2px"
                            variant="ghost"
                            size="sm"
                            onClick={() => toggleShowMore(metric.metric.reference)}
                          >
                            <HStack spacing="8px">
                              <IconArrowNarrowDown size="16px" />
                              <Typography variant="bodyStrong" size="sm">
                                Load {remainingCount} more
                              </Typography>
                            </HStack>
                          </Button>
                        )}
                        <Box w="100%" mt={remainingCount > 0 ? '-34px' : '0px'}>
                          <MDRM
                            metric={metric}
                            companyReportingUnitId={companyLevelReportingUnitId}
                            selectedQuarter={selectedQuarter}
                            setRowData={setRowData}
                          />
                        </Box>
                      </VStack>
                    </Skeleton>
                  );
                })}
              </VStack>
            </VStack>
          )}
          {showNarrativeSection && (
            <VStack alignItems="stretch" gap="20px" w="100%">
              <Typography variant="h3">Narrative data points</Typography>
              <Skeleton isLoaded={isMetricDisclosure ? true : !dataLoading} w="100%">
                <VStack alignItems="start" spacing="6px" w="100%">
                  <MetricsTable
                    metrics={narrativeMetrics}
                    isNarrative
                    selectedQuarter={selectedQuarter}
                    isGroup={isGroup}
                    currency={company?.currency ?? ''}
                    setSelectedRow={setSelectedRow}
                    materialStandardId={companyAssessmentId}
                    companyReportingUnit={companyLevelReportingUnitId}
                    esrsAssessmentProjectLeader={projectLeader}
                    rowData={rowData}
                    isGeneratingAnswers={isGeneratingAnswers}
                    setIsComments={setIsComments}
                    onApprovalWarningModalOpen={onApprovalWarningModalOpen}
                    answersData={aggregatedMetricsAnswers}
                    setRowData={setRowData}
                    allMetrics={allMetrics}
                    setNumberOfRows={setNumberOfRows}
                    isMetricDr={isMetricDisclosure}
                    parentStandardId={parentAssessmentId}
                    requiredMaterialMetrics={requiredMaterialMetrics ?? []}
                    metricsMissingTagsConfiguration={narrativeMetricsMissingTagsConfiguration}
                    notAggregatableMetrics={notAggregatableMetrics}
                    parentNotAggregatableMetrics={parentNotAggregatableMetrics}
                    optedOutMetrics={optedOutMetrics}
                  />
                </VStack>
              </Skeleton>
            </VStack>
          )}
        </VStack>

        <LearnMoreDrawer
          header={selectedMetric?.isDisclosure ? selectedMetric.title : undefined}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          description={selectedMetric?.description ?? ''}
          customHeader={
            !selectedMetric?.isDisclosure ? (
              <MetricLearnMoreHeader
                metricRef={selectedMetric?.reference ?? ''}
                tags={selectedMetric?.tags ?? []}
              />
            ) : undefined
          }
        />

        <OptOutModal
          key={rowData?.metric.reference}
          isOpen={isOpen}
          onClose={onClose}
          assessmentId={esrsAssessmentId}
          reportingUnitId={companyLevelReportingUnitId}
          metric={selectedMetric}
          isCompany={false}
        />
      </VStack>
      {/* Normal metric sidebar */}
      {!!rowData && (
        <MetricSidebar
          row={rowData}
          metric={rowData?.metric ?? ({} as MetricsTableData['metric'])}
          materialStandardId={companyAssessmentId}
          companyReportingUnit={companyLevelReportingUnitId}
          selectedMetric={selectedMetric}
          esrsAssessmentProjectLeader={projectLeader}
          setSelectedMetric={setSelectedMetric}
          onMenuOpen={onOpen}
          selectedQuarter={selectedQuarter}
          setRowData={setRowData}
          isComments={isComments}
          isGroup={isGroup}
          isMetricDr={isMetricDisclosure}
          companyCurrency={company?.currency ?? ''}
          generatedSummaryStatus={generatedSummaryStatus}
          setGeneratedSummaryStatus={setGeneratedSummaryStatus}
        />
      )}
      {/* MDRM sidebar based on query param */}
      {!rowData && !!searchParams.get('openMdrm') && (
        <Box w="320px" minWidth="320px" overflowY="auto" />
      )}

      <Suspense>
        {selectedExcelMetric && (
          <MetricsExcelUploadModal
            isOpen={isImportOpen}
            onClose={onImportClose}
            metrics={[selectedExcelMetric]}
            disclosureRequirement={disclosureRequirementRef ?? ''}
            businessUnit={company?.name ?? 'Company'}
            areTableMetricsQuarterly={isSelectedMetricQuarterly}
            areTableMetricsYearly={isSelectedMetricYearly}
            companyStandardId={companyAssessmentId}
            companyReportingUnitId={companyLevelReportingUnitId}
          />
        )}
      </Suspense>
      <AnswersApprovalWarningModal
        isOpen={isApprovalWarningModalOpen}
        onClose={onApprovalWarningModalClose}
        onConfirm={() => {
          const metric = allMetrics
            .filter((met) => met.metric.metricType !== QuestionType_Enum_.Decimal_)
            .find((m) => m.metric.reference === selectedRow)?.metric;

          const answerData = aggregatedMetricsAnswers?.find((a) => a.metricRef === selectedRow);

          if (isEqual(rowData?.metric, metric)) {
            setRowData(undefined);
          } else
            setRowData(
              !!answerData?.reportingUnits?.length || !!answerData?.subsidiaries?.length
                ? { metric: metric as MetricsTableData['metric'], sourceData: answerData }
                : { metric: metric as MetricsTableData['metric'] }
            );
        }}
      />
    </HStack>
  );
};
