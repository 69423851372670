import { Infobox } from 'Atoms';
import { TimePeriods, TimePeriodsEnums } from 'containers/Esrs/pieces';

export const MetricsQuarterInfo = ({ selectedQuarter }: { selectedQuarter: TimePeriods }) => {
  return (
    <Infobox
      status="neutral"
      closable={false}
      w="100%"
      title={
        selectedQuarter === TimePeriodsEnums.year
          ? 'Full year view'
          : `Quarter view: ${selectedQuarter}`
      }
      description={
        selectedQuarter === TimePeriodsEnums.year
          ? 'You are looking now at the full year view. Some metrics in this disclosure requirement are collected quarterly — to see data per quarter, switch between views above.'
          : 'You are looking now at metrics collected quarterly. To see the full year overview and narrative data points, switch to the "Full year" view above.'
      }
    />
  );
};
