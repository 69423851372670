import { useParams } from 'react-router-dom';
import { orderBy, uniqBy } from 'lodash';
import { QuestionType_Enum_ } from 'models';
import {
  FrequencyEnums,
  hasChildOnBULevel,
  hasChildOnSubLevel,
  hasQuarterlyFrequency,
  processNarrativeMetrics,
  TimePeriods,
  TimePeriodsEnums,
} from 'containers/Esrs/pieces';
import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import { GroupOrderOptions } from '../../AggregatedMetrics/AggregatedMetricsUtils';
import {
  AssessableMetrics,
  filterDuplicateRows,
  flattenMetricsNotAggregatable,
  flattenMetricsWithTags,
  metricsMissingRequiredTags,
  processBuMetrics,
  useGetBuMetrics,
  useGetMetrics,
} from './MetricsHelperFunctions';
import { useMemo } from 'react';

export const useProcessMetrics = (
  withAssociation: boolean,
  groupOrOrder: GroupOrderOptions,
  selectedQuarter: TimePeriods,
  companyAssessmentId: string,
  parentAssessmentId: string,
  isGroup: boolean
) => {
  const { standardRef = '', disclosureRequirementRef, reportingUnitId } = useParams();
  const isBusinessUnit = !!reportingUnitId;

  const {
    aggregatedMetrics,
    qualitativeMetrics: aggregatedQualitativeMetrics,
    aggregatedMetricsAnswers,
    requiredMaterialMetrics,
    companyLevelReportingUnitId,
    dataLoading,
    optedOutMetrics,
    projectLeader,
    drData,
  } = useGetMetrics(
    withAssociation,
    companyAssessmentId,
    parentAssessmentId,
    standardRef,
    isBusinessUnit
  );

  const { BUmetrics, requiredBUMaterialMetrics, buDataLoading } = useGetBuMetrics(
    disclosureRequirementRef,
    companyAssessmentId,
    parentAssessmentId,
    isBusinessUnit
  );

  const nestedBuMetrics = processBuMetrics(
    BUmetrics as AssessableMetrics,
    companyAssessmentId,
    parentAssessmentId
  );

  const allFilteredMetrics = filterDuplicateRows(
    aggregatedMetrics,
    nestedBuMetrics,
    isBusinessUnit
  );

  const allNarrativeMetrics = allFilteredMetrics.filter(
    (met) => met.metric.metricType !== QuestionType_Enum_.Decimal_
  );

  const narrativeMetrics = allNarrativeMetrics.map((m) =>
    processNarrativeMetrics(
      m,
      aggregatedMetricsAnswers?.map((ans) => ({
        metricRef: ans.answer?.metricRef ?? '',
        datapoints: ans.answer?.datapoints ?? [],
      }))
    )
  );

  const quantitativeMetrics = allFilteredMetrics.filter(
    (m) => m.metric.metricType === QuestionType_Enum_.Decimal_
  );

  const orderedMetrics = useMemo(() => {
    if (groupOrOrder !== GroupOrderOptions.byEsrsOrder) {
      return quantitativeMetrics.filter((m) =>
        selectedQuarter === TimePeriodsEnums.year ? true : hasQuarterlyFrequency(m.metric)
      );
    } else {
      const mixedMetrics = [...quantitativeMetrics, ...narrativeMetrics];
      return uniqBy(
        orderBy(
          mixedMetrics,
          [
            (metric) => metric.metric.globalOrder === null,
            (metric) => metric.metric.globalOrder,
            (metric) => metric.metric.order,
          ],
          ['asc', 'asc', 'asc']
        ).filter((met) =>
          selectedQuarter === TimePeriodsEnums.year
            ? true
            : met.metric.materialMetrics?.[0]?.frequency === FrequencyEnums.quarterly
        ),
        'metric.reference'
      );
    }
  }, [groupOrOrder, quantitativeMetrics, narrativeMetrics, selectedQuarter]);

  const summaryQualitativeMetrics = isGroup
    ? (aggregatedQualitativeMetrics.filter((metric) => hasChildOnSubLevel(metric)) ?? [])
    : (aggregatedQualitativeMetrics.filter((metric) => hasChildOnBULevel(metric)) ?? []);

  const flattenedNarrativeMetrics = flattenMetricsWithTags(narrativeMetrics);

  const [narrativeMetricsMissingTagsConfiguration, someNarrativeTagsNotConfigured] =
    metricsMissingRequiredTags(
      flattenedNarrativeMetrics,
      companyAssessmentId,
      isGroup,
      isBusinessUnit
    );

  const flattenAggregatedMetrics = flattenMetricsNotAggregatable(aggregatedMetrics);

  const filteredNotAggregatableMetrics = flattenAggregatedMetrics?.filter((mm) => {
    return (
      mm.metric?.notAggregatable === true &&
      (mm.metric?.materialMetrics[0]?.dataCollection === DataCollectionLevel.subsidiaries ||
        mm.metric?.materialMetrics[0]?.dataCollection === DataCollectionLevel.reportingUnits) &&
      !isBusinessUnit
    );
  });

  const notAggregatableMetrics = filteredNotAggregatableMetrics?.map(
    (metric) => metric.metric?.reference
  );

  const parentNotAggregatableMetrics: Set<string> = new Set(
    filteredNotAggregatableMetrics
      ?.map((metric) => metric.metric?.parentMetrics.map((pm) => pm.parentMetricRef))
      .flat()
  );

  const flattenOrderedMetrics = flattenMetricsWithTags(orderedMetrics);

  const [metricsMissingTagsConfiguration, someQuantitativeTagsNotConfigured] =
    metricsMissingRequiredTags(flattenOrderedMetrics, companyAssessmentId, isGroup, isBusinessUnit);

  return {
    allMetrics: orderedMetrics,
    quantitativeMetrics: quantitativeMetrics,
    narrativeMetrics: narrativeMetrics,
    summaryQualitativeMetrics,
    companyLevelReportingUnitId,
    projectLeader,
    requiredMaterialMetrics: !isBusinessUnit ? requiredMaterialMetrics : requiredBUMaterialMetrics,
    dataLoading: !isBusinessUnit ? dataLoading : buDataLoading,
    optedOutMetrics: optedOutMetrics,
    notAggregatableMetrics,
    parentNotAggregatableMetrics,
    metricsMissingTagsConfiguration,
    someTagsNotConfigured: someQuantitativeTagsNotConfigured || someNarrativeTagsNotConfigured,
    narrativeMetricsMissingTagsConfiguration,
    aggregatedMetricsAnswers,
    drData,
  };
};
