import { VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Loader } from 'Molecules';
import {
  EsrsAssessmentsYearsQuery_,
  GetIntegrationApiKeyQuery_,
  useEsrsAssessmentsYearsQuery,
  useGetIntegrationApiKeyQuery,
} from 'models';
import { useParams } from 'react-router-dom';
import { IntegrationTable } from './IntegrationTable';
import { IntegrationInput } from './IntegrationInput';

export type MoreScopeCompanyRow = EsrsAssessmentsYearsQuery_['esrsAssessments'][0];
export type CompanyIntegration = GetIntegrationApiKeyQuery_['esrs_CompanyIntegration'][0];

export const calculateTime = (syncedAt: string) => {
  if (!!!syncedAt) {
    return 'NA';
  }
  const sec = Math.floor((new Date().getTime() - new Date(syncedAt).getTime()) / 1000);

  if (sec >= 60) {
    const min = Math.floor(sec / 60);
    if (min >= 60) {
      const hour = Math.floor(min / 60);
      if (hour >= 24) {
        const day = Math.floor(hour / 24);
        if (day >= 30) {
          const month = Math.floor(day / 30);
          if (month >= 12) {
            const year = Math.floor(month / 12);
            return `${year} y`;
          } else {
            return `${month} mon`;
          }
        } else {
          return `${day} d`;
        }
      } else {
        return `${hour} h`;
      }
    } else {
      return `${min} m`;
    }
  } else {
    return `${sec} s`;
  }
};

export const MoreScopeCompany = () => {
  const { companyId = '' } = useParams();

  const { data, loading: dataLoading } = useGetIntegrationApiKeyQuery({
    variables: {
      companyId,
      integrationSystemName: 'moreScope',
    },
    skip: !companyId,
  });
  const integration = useMemo(() => data?.esrs_CompanyIntegration?.[0], [data]);

  const { data: esrsAssessmentsData, loading: esrsAssessmentsLoading } =
    useEsrsAssessmentsYearsQuery({
      variables: {
        companyId,
        companyIntegrationId: integration?.id,
      },
      skip: !companyId,
    });
  const assessments = useMemo(() => esrsAssessmentsData?.esrsAssessments, [esrsAssessmentsData]);

  if (dataLoading || esrsAssessmentsLoading) {
    return <Loader height={'100px'} />;
  }
  return (
    <VStack mt="15px" alignItems={'flex-start'} width={'100%'} gap={0}>
      <IntegrationInput integration={integration} />
      <IntegrationTable integration={integration} assessments={assessments} />
    </VStack>
  );
};
