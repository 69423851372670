import { HStack, Td, Tr } from '@chakra-ui/react';
import { useToast } from 'utils/hooks';
import { Input, Button, Tag, IconButton, TruncatableText, Tooltip } from 'Atoms';
import { useEffect, useMemo, useState } from 'react';
import { Typography } from 'Tokens';
import { CompanyIcon } from 'Tokens/Icons/Data';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { RefreshIcon } from 'Tokens/Icons/Function';
import { nhost } from 'utils/nhost';
import {
  Esrs_EsrsAssessmentCompanyIntegrationRu_Constraint_,
  Esrs_EsrsAssessmentCompanyIntegrationRu_Update_Column_,
  SingleAssessmentCompanyIntegrationRuDocument_,
  SingleAssessmentCompanyIntegrationRuQuery_,
  SingleAssessmentCompanyIntegrationRuQueryVariables_,
  useUpsertAssessmentCompanyIntegrationMutation,
  useUpsertAssessmentCompanyIntegrationRuMutation,
} from 'models';
import { MoreScopeBusinessUnitRow } from '../MoreScopeBusinessUnits/MoreScopeBusinessUnits';
import { calculateTime } from '../MoreScopeCompany/MoreScopeCompany';
import { Link, useParams } from 'react-router-dom';

type ReportingUnitDetails =
  | {
      esrsReportingUnitId: any;
      syncedAt?: any | null;
      apiKey?: string | null;
      esrsAssessmentCompanyIntegrationId: any;
    }
  | undefined;

export const MoreScopeBusinessUnitsRow = ({
  subRow,
  assessmentYears,
  display,
  userId,
}: {
  subRow: MoreScopeBusinessUnitRow;
  assessmentYears?: number[];
  display: string;
  userId?: string;
}) => {
  const [reportingUnitDetails, setReportingUnitDetails] = useState<ReportingUnitDetails>();
  const [moreScopeYears, setMoreScopeYears] = useState<number[]>([]);
  const [api, setApi] = useState('');
  const toast = useToast();
  const [savingState, setSavingState] = useState(0);
  const [isSyncing, setIsSyncing] = useState(false);
  const [upsertAssessmentCompanyIntegration] = useUpsertAssessmentCompanyIntegrationMutation();
  const [upsertAssessmentCompanyIntegrationRU] = useUpsertAssessmentCompanyIntegrationRuMutation();
  const { companyId } = useParams();
  const [syncedTime, setSyncedTime] = useState('');
  const [shouldfetchMoreScopeYears, setShouldFetchMoreScopeYears] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const fetchMoreScopeYears = async () => {
    if (!assessmentYears) return;

    setLoading(true);
    const res = await nhost.functions.call('esrs/get-morescope-years', {
      apiKey: api,
      years: assessmentYears,
    });

    if (res.error) {
      setMoreScopeYears([]);
    } else {
      setMoreScopeYears((res.res?.data as { result: number[] })?.result);
    }
    setLoading(false);
    setShouldFetchMoreScopeYears(false);
  };

  const isSyncDisabled = useMemo(() => {
    return !moreScopeYears.includes(subRow.reportingYear);
  }, [moreScopeYears]);

  const onSave = async () => {
    if (savingState === 0) {
      try {
        setSavingState(1);

        await upsertAssessmentCompanyIntegration({
          variables: {
            object: {
              esrsAssessmentId: subRow?.assessmentId,
              companyIntegrationId: subRow?.companyIntegrationId,
              esrsAssessmentCompanyIntegrationRUs: {
                data: [
                  {
                    esrsReportingUnitId: subRow?.id,
                    apiKey: api,
                  },
                ],
                on_conflict: {
                  constraint:
                    Esrs_EsrsAssessmentCompanyIntegrationRu_Constraint_.EsrsAssessmentCompanyIntegrationRuEsrsReportingUnitIdEsrsAsse_,
                  update_columns: [Esrs_EsrsAssessmentCompanyIntegrationRu_Update_Column_.ApiKey_],
                },
              },
            },
          },
          refetchQueries: [],
          onCompleted: async (result) => {
            const { data } = await nhost.graphql.request<
              SingleAssessmentCompanyIntegrationRuQuery_,
              SingleAssessmentCompanyIntegrationRuQueryVariables_
            >(SingleAssessmentCompanyIntegrationRuDocument_, {
              reportingUnitId: subRow?.id,
              assessmentCompanyIntegrationId:
                result.insert_esrs_EsrsAssessmentCompanyIntegration_one?.id,
            });
            const reportingUnitData = data?.esrs_EsrsAssessmentCompanyIntegrationRU?.[0];
            setReportingUnitDetails({
              esrsReportingUnitId: subRow?.id,
              apiKey: reportingUnitData?.apiKey,
              syncedAt: reportingUnitData?.syncedAt,
              esrsAssessmentCompanyIntegrationId:
                result.insert_esrs_EsrsAssessmentCompanyIntegration_one?.id,
            });
            toast({ text: 'API key saved' });
            setSavingState(2);
            setShouldFetchMoreScopeYears(true);
          },
        });
      } catch {
        setSavingState(0);
        toast({ text: 'Failed to save Api Key', variant: 'danger' });
      }
    }
  };

  const onSync = async (values: {
    assessmentId: string;
    year: number;
    projectLeaderId: string;
    companyReportingUnitId: string;
    apiKey: string;
    assessmentCompanyIntegrationId: string;
  }) => {
    if (!isSyncing) {
      setIsSyncing(true);
      const {
        assessmentId,
        year,
        projectLeaderId,
        companyReportingUnitId,
        apiKey,
        assessmentCompanyIntegrationId,
      } = values;
      try {
        const res = await nhost.functions.call('esrs/integrate-morescope-data', {
          assessmentId: assessmentId,
          assessmentYear: year,
          companyReportingUnitId: companyReportingUnitId,
          userId: userId ?? '',
          assessmentProjectLeaderId: projectLeaderId,
          apiKey,
        });
        if (res.error) {
          if (res.error.message === 'E1-6 not assessed') {
            toast({
              text: 'Please assess the disclosure requirement E1-6 in Climate Change first.',
              variant: 'danger',
              closable: true,
              duration: null,
            });
            return;
          } else {
            console.error(res.error);
            throw new Error('Error integrating data: ' + res.error.message);
          }
        }

        await upsertAssessmentCompanyIntegrationRU({
          variables: {
            object: {
              esrsReportingUnitId: companyReportingUnitId,
              esrsAssessmentCompanyIntegrationId: assessmentCompanyIntegrationId,
              syncedAt: new Date(),
            },
          },
          refetchQueries: [],
          onError: () => {
            toast({
              text: 'Error saving synced time',
              variant: 'danger',
            });
          },
          onCompleted: async () => {
            const { data } = await nhost.graphql.request<
              SingleAssessmentCompanyIntegrationRuQuery_,
              SingleAssessmentCompanyIntegrationRuQueryVariables_
            >(SingleAssessmentCompanyIntegrationRuDocument_, {
              reportingUnitId: subRow?.id,
              assessmentCompanyIntegrationId,
            });
            const reportingUnitData = data?.esrs_EsrsAssessmentCompanyIntegrationRU?.[0];
            setReportingUnitDetails({
              esrsReportingUnitId: subRow?.id,
              apiKey: reportingUnitData?.apiKey,
              syncedAt: reportingUnitData?.syncedAt,
              esrsAssessmentCompanyIntegrationId: assessmentCompanyIntegrationId,
            });
            setSyncedTime(calculateTime(reportingUnitData?.syncedAt));
          },
        });

        toast({
          text: 'Data integrated successfully',
        });
      } catch (error) {
        console.error(error);
        toast({
          variant: 'danger',
          text: 'Unable to create integrate data. Please review API Key',
        });
      }
      setIsSyncing(false);
    }
  };

  useEffect(() => {
    const company = subRow?.companyIntegrationRU?.find(
      (company) => company?.esrsReportingUnitId === subRow?.id
    );
    if (company) {
      setReportingUnitDetails(company);
      setApi(company?.apiKey ?? '');
      setSyncedTime(calculateTime(company?.syncedAt));
      if (company?.apiKey) {
        setSavingState(2);
        setShouldFetchMoreScopeYears(true);
      }
    }
  }, []);

  useEffect(() => {
    if (shouldfetchMoreScopeYears) {
      fetchMoreScopeYears();
    }
  }, [shouldfetchMoreScopeYears]);

  return (
    <Tr
      display={display}
      background={'bg.unknown.muted'}
      key={'header'}
      borderTop={'1px solid'}
      borderColor={'border.decorative'}
      alignItems={'center'}
      height={'50px'}
    >
      <Td pt={'12px'} pb={'12px'} pl={'44px'} pr={'0px'} borderBottom={'none'} width={'225px'}>
        <HStack>
          <CompanyIcon />
          <TruncatableText text={subRow?.name} fontSize={'14px'} maxWidth={'157px'} pr={'40px'} />
        </HStack>
      </Td>
      <Td padding={'0'} borderBottom={'none'} width={'287px'}>
        <HStack alignSelf={'flex-start'}>
          <Input
            value={api}
            type="password"
            variant="ghost"
            onChange={(e) => {
              setApi(e.target.value);
              if (savingState != 0) {
                setSavingState(0);
              }
              if (savingState === 0) {
                if (e.target.value === reportingUnitDetails?.apiKey) {
                  setSavingState(2);
                  setShouldFetchMoreScopeYears(false);
                }
              }
            }}
            placeholder="Enter API key"
            isDisabled={isSyncing || savingState === 1}
            paddingRight={'70px'}
            width={'287px'}
          />
          {!!api && (
            <Button
              variant={'ghost'}
              isLoading={savingState === 1}
              onClick={() => onSave()}
              minW={'fit-content'}
              height={'28px'}
              isDisabled={isSyncing}
              marginLeft={savingState === 2 ? '-68px' : savingState === 1 ? '-80px' : '-60px'}
            >
              <Typography color={'text.selected'} fontWeight={'500'} lineHeight={'21px'}>
                {savingState === 0 || savingState === 1 ? 'Save' : 'Saved'}
              </Typography>
            </Button>
          )}
        </HStack>
      </Td>
      <Td padding={'0'} borderBottom={'none'} width={'144px'} pl={'24px'}>
        <HStack>
          <Tooltip
            label={'No matching data found in MoreScope'}
            isDisabled={!isSyncDisabled || savingState !== 2}
          >
            <Button
              height={'28px'}
              leftIcon={
                <RefreshIcon
                  color={
                    savingState === 2 && !loading && !isSyncDisabled
                      ? 'text.action'
                      : 'text.disabled'
                  }
                />
              }
              justifyContent="flex-start"
              variant={'secondary'}
              onClick={() =>
                onSync({
                  assessmentId: subRow?.assessmentId,
                  apiKey: reportingUnitDetails?.apiKey ?? '',
                  projectLeaderId: subRow?.projectLeaderId,
                  year: subRow?.reportingYear,
                  companyReportingUnitId: subRow?.id,
                  assessmentCompanyIntegrationId:
                    reportingUnitDetails?.esrsAssessmentCompanyIntegrationId ?? '',
                })
              }
              padding={'6px 8px'}
              isDisabled={savingState !== 2 || isSyncDisabled || loading}
              isLoading={isSyncing || loading}
            >
              <Typography
                color={
                  savingState === 2 && !loading && !isSyncDisabled ? 'text.action' : 'text.disabled'
                }
              >
                Sync data
              </Typography>
            </Button>
          </Tooltip>
        </HStack>
      </Td>
      <Td padding={'0'} borderBottom={'none'} width={'150px'} pl={'24px'}>
        <HStack>
          <Tag variant={!!syncedTime && syncedTime != 'NA' ? 'info' : 'undefined'}>
            {!!syncedTime && syncedTime != 'NA' ? (
              <HStack>
                <Typography variant="bodyStrong" color={'text.info'}>
                  Synced
                </Typography>
                <TruncatableText color="text.info" fontSize={'12px'} text={`${syncedTime} ago`} />
              </HStack>
            ) : (
              <Typography>Manual Input</Typography>
            )}
          </Tag>
        </HStack>
      </Td>
      <Td padding={'0'} borderBottom={'none'} width={'48px'} pl={'20px'}>
        <HStack>
          <IconButton
            icon={
              <Link to={`/${companyId}/esrs/${subRow?.assessmentId}`} target="_blank">
                <ArrowUpRightIcon mt={'5px'} />
              </Link>
            }
            aria-label="chevron icon"
            variant={'ghost'}
          />
        </HStack>
      </Td>
    </Tr>
  );
};
