import { VStack, HStack } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import { TimePeriods, TimePeriodsEnums } from 'containers/Esrs/pieces';
import { Typography } from 'Tokens';
import { HelpIcon } from 'Tokens/Icons/Status';
import {
  GroupOrderOptions,
  GroupOrderSelector,
  QuarterSelector,
} from '../../AggregatedMetrics/AggregatedMetricsUtils';
import { SelectedMetric } from 'containers/Esrs';

export const MetricsHeader = ({
  groupOrOrder,
  setGroupOrOrder,
  areMetricsYearly,
  selectedQuarter,
  setSelectedQuarter,
  setSelectedMetric,
  onDrawerOpen,
  drTitle,
  drRef,
  drDescription,
}: {
  groupOrOrder: GroupOrderOptions;
  setGroupOrOrder: (val: GroupOrderOptions) => void;
  areMetricsYearly: boolean;
  selectedQuarter: TimePeriods;
  setSelectedQuarter: (val: TimePeriodsEnums) => void;
  onDrawerOpen: () => void;
  setSelectedMetric: (value: React.SetStateAction<SelectedMetric | undefined>) => void;
  drTitle: string;
  drRef: string;
  drDescription: string;
}) => {
  return (
    <VStack spacing="8px" alignItems="start" w="100%">
      <HStack w="100%" justifyContent="space-between">
        <HStack spacing="6px" alignItems="center" justifyContent="space-between" w="100%">
          <Typography variant="h3" as="span">
            Data points
          </Typography>
          <HStack w="40%" justifyContent="flex-end">
            <GroupOrderSelector groupOrOrder={groupOrOrder} setGroupOrOrder={setGroupOrOrder} />
            {!areMetricsYearly && (
              <QuarterSelector
                defaultQuarter={selectedQuarter as TimePeriodsEnums}
                setSelectedQuarter={setSelectedQuarter}
              />
            )}
            <IconButton
              aria-label="learn more"
              variant="ghost"
              icon={<HelpIcon />}
              size="sm"
              onClick={() => {
                setSelectedMetric({
                  reference: drRef,
                  title: drTitle,
                  description: drDescription ?? '',
                  isDisclosure: true,
                });
                onDrawerOpen();
              }}
            />
          </HStack>
        </HStack>
      </HStack>
    </VStack>
  );
};
