import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import { Typography } from 'Tokens';
import { EsrsDRReportMetric } from '../../Report.types';
import { Table } from 'Molecules';

type TableType = {
  metric: {
    metric: {
      title: string | null | undefined;
      shortTitle?: string | null | undefined;
    };
  };
  textAnswer: EsrsDRReportMetric['textAnswer'];
};

export const GOV_4Table = ({ metrics }: { metrics: TableType[] | undefined }) => {
  const columns: ColumnDef<TableType>[] = [
    {
      header: 'Core elements of due diligence',
      accessorKey: 'title',
      cell: ({ row }) => (
        <Typography variant="body">
          {row.original.metric.metric.shortTitle ?? row.original.metric.metric.title}
        </Typography>
      ),
    },
    {
      header: 'Paragraphs in the sustainability statement',
      accessorKey: 'value',
      cell: ({ row }) => (
        <Typography variant="body">
          {!row.original.textAnswer ? '--' : row.original.textAnswer}
        </Typography>
      ),
    },
  ];

  return (
    <Table<TableType>
      columns={columns}
      data={metrics ?? []}
      withBorder={true}
      borderRadius="0px"
      rowHeight="36px"
    />
  );
};
