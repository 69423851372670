import { EsrsReportMetric } from '../../Report.types';
import { addEmptySpace, createCustomMetricTable, createMdrm } from '../report-helper-functions';
import { createS16GenderTable, createS16CountryTable } from './employees-by-contract';

const customS1_6GenderMetrics = [
  'employeesGender',
  'totalEmployeesByContractGender',
  'employeesContract2',
];

const customS1_6CountryMetrics = [
  'employeesCountry',
  'totalEmployeesContractGenderAndCountry',
  'employeesContract2',
];

export const createS16Tables = (metrics: EsrsReportMetric[], companyCurrency: string) => {
  const employeesGenderMetric = metrics?.find((m) => m.metric.metricRef === 'employeesGender');
  const employeesGender = metrics?.find((m) => m.metric.metricRef === 'employeesGender')?.tableData;
  const employeesCountryMetric = metrics?.find((m) => m.metric.metricRef === 'employeesCountry');
  const employeesCountry = metrics?.find(
    (m) => m.metric.metricRef === 'employeesCountry'
  )?.tableData;
  const hasMetric51 = metrics.some(
    (metric) => metric.metric.metricRef === 'totalEmployeesContractGenderAndCountry'
  );
  const genderMetrics = hasMetric51
    ? ['employeesGender', 'totalEmployeesContractGenderAndCountry', 'employeesContract2']
    : customS1_6GenderMetrics;
  const s16GenderTableMetrics = metrics
    .filter((m) => genderMetrics.includes(m.metric.metricRef))
    .map((m) => m.tableData);
  const s16CountryTableMetrics = metrics
    .filter((m) => customS1_6CountryMetrics.includes(m.metric.metricRef))
    .map((m) => m.tableData);

  const totalEmployeeByContactGender = metrics
    .filter((m) => m.metric.metricRef === 'totalEmployeesByContractGender')
    .map((m) => m.tableData);

  return [
    employeesGender
      ? [
          createCustomMetricTable(
            employeesGender,
            [
              { header: 'Gender', key: 'title' },
              { header: 'Number of employees (head count)', key: 'result' },
            ],
            companyCurrency
          ),
          addEmptySpace(300),
          ...(employeesGenderMetric ? createMdrm(employeesGenderMetric) : []),
        ]
      : [],
    employeesCountry
      ? [
          createCustomMetricTable(
            employeesCountry,
            [
              { header: 'Country', key: 'title' },
              { header: 'Number of employees (head count)', key: 'result' },
            ],
            companyCurrency,
            true
          ),
          addEmptySpace(300),
          ...(employeesCountryMetric ? createMdrm(employeesCountryMetric) : []),
        ]
      : [],
    createS16GenderTable(s16GenderTableMetrics, genderMetrics, totalEmployeeByContactGender),
    addEmptySpace(300),
    createS16CountryTable(s16CountryTableMetrics, customS1_6CountryMetrics),
  ].flat();
};
