import { useUserData } from '@nhost/react';
import { groupBy, uniqBy } from 'lodash';
import { Activity, useAllActivitiesQuery } from 'models';
import { useState, useEffect, useCallback } from 'react';
import { smartSearchActivities } from 'utils/search';
import { hasSupportRole } from 'utils/users.js';

const EMPTY = [] as Array<Activity>;
export const useActivitySearch = (searchQuery: string, selectedCodes: string[]) => {
  console.log('searchQuery', searchQuery);
  const [searchResults, setSearchResults] = useState<Array<Activity>>([]);
  const user = useUserData();

  const {
    data: activitiesRes,
    // error: activitiesError,
  } = useAllActivitiesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      showHidden: hasSupportRole(user?.roles),
    },
  });

  const activities = activitiesRes?.allActivities || EMPTY;

  const allActivityNaceCodes = activities.flatMap((activity) => activity.naceCodes);
  const naceGroups = groupBy(allActivityNaceCodes, 'code');
  const naceCodeList = Object.keys(naceGroups).sort();

  const getActivityByRef = (r: { ref: string | number }) => {
    const activity = activities.find((a) => a.reference === r.ref);
    return activity;
  };

  const cleanUpResults = (
    activitiesResults: {
      ref: string | number;
      score: string | number;
    }[]
  ) => {
    const uniqResults = uniqBy(activitiesResults, 'ref');

    const sortedActivities = uniqResults.map(getActivityByRef).filter(Boolean) as Array<Activity>;
    return sortedActivities;
  };

  const doFilter = useCallback(
    (values: Array<Activity>) => {
      if (selectedCodes?.length) {
        const filteredResults = values.filter((activity) =>
          activity.naceCodes.some((code) => selectedCodes.includes(code.code))
        );
        return filteredResults;
      }
      return values;
    },
    [selectedCodes]
  );

  const doSearch = useCallback(
    async (filteredResults: Array<Activity>) => {
      const results = await smartSearchActivities(searchQuery, filteredResults);
      setSearchResults(doFilter(cleanUpResults(results)));
    },
    [searchQuery, selectedCodes, activities]
  );

  useEffect(() => {
    if (searchQuery !== '') {
      doSearch(activities);
    } else {
      setSearchResults(doFilter(activities));
    }
  }, [selectedCodes, searchQuery, activities]);

  return { searchResults, naceCodeList };
};
