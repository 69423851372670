import { Box, HStack, Table, Tbody, Td, Tr, VStack } from '@chakra-ui/react';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { EmptyState, IconButton } from 'Atoms';
import { Fragment, useMemo } from 'react';
import { Typography } from 'Tokens';
import { MoreScopeBusinessUnitsRow } from './MoreScopeBusinessUnitsRow';
import { ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';
import { Loader } from 'Molecules';
import { useAssessmentCompanyIntegrationRuQuery, useGetIntegrationApiKeyQuery } from 'models';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserData } from '@nhost/react';

export type MoreScopeBusinessUnitRow = {
  id: string;
  companyIntegrationId: string;
  assessmentId: string;
  name: string;
  projectLeaderId: string;
  reportingYear: number;
  companyIntegrationRU: {
    esrsReportingUnitId: any;
    syncedAt?: any | null;
    apiKey?: string | null;
    esrsAssessmentCompanyIntegrationId: any;
  }[];
};

export const MoreScopeBusinessUnits = () => {
  const { companyId } = useParams();
  const user = useUserData();
  const navigate = useNavigate();

  const { data: integrationData, loading: integrationDataLoading } = useGetIntegrationApiKeyQuery({
    variables: {
      companyId,
      integrationSystemName: 'moreScope',
    },
    skip: !companyId,
  });
  const integration = useMemo(
    () => integrationData?.esrs_CompanyIntegration?.[0],
    [integrationData]
  );

  const { data: companyIntegrationRUdata, loading: companyIntegrationRUloading } =
    useAssessmentCompanyIntegrationRuQuery({
      variables: {
        companyId,
        companyIntegrationId: integration?.id,
      },
    });

  const assessments = useMemo(
    () => companyIntegrationRUdata?.esrsAssessments,
    [companyIntegrationRUdata]
  );

  const assessmentYears = useMemo(() => assessments?.map((a) => a.reportingYear), [assessments]);

  const data = useMemo(
    () =>
      assessments?.map((assessment) => ({
        year: assessment?.reportingYear,
        subRows: assessment?.reportingUnits.map((reportingUnit) => ({
          id: reportingUnit?.id,
          reportingYear: assessment?.reportingYear,
          projectLeaderId: assessment?.projectLeaderId,
          companyIntegrationId: integration?.id,
          assessmentId: assessment?.id,
          name: reportingUnit?.name,
          companyIntegrationRU:
            reportingUnit?.esrsAssessment?.esrsAssessmentCompanyIntegrations?.[0]
              ?.esrsAssessmentCompanyIntegrationRUs,
        })),
      })) ?? [],
    [companyIntegrationRUdata]
  );

  const table = useReactTable({
    columns: [
      {
        header: '',
        accessorKey: 'year',
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <HStack padding={'6px 0px'}>
              <IconButton
                aria-label="chevron-icon"
                icon={
                  row.getIsExpanded() ? (
                    <ChevronDownIcon boxSize={'16px'} />
                  ) : (
                    <ChevronRightIcon boxSize={'16px'} />
                  )
                }
                variant={'ghost'}
                onClick={row.getToggleExpandedHandler()}
              />
              <Typography fontSize={'14px'}>{row.original.year} assessment</Typography>
            </HStack>
          ) : (
            ''
          );
        },
      },
    ],
    data,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: () => true,
  });

  if (integrationDataLoading || companyIntegrationRUloading) {
    return <Loader height={'300px'} />;
  }

  return (
    <VStack mt="15px" alignItems={'flex-start'} width={'100%'}>
      <Typography variant="h3">Sync ESRS data per assessment</Typography>
      {!data.length ? (
        <Box h={'100%'} w={'100%'}>
          <EmptyState
            component
            title="Create your first ESRS assessment"
            description={'You have no ESRS assessments yet, get started by creating your first one'}
            callToAction={{
              text: 'Go to the ESRS module',
              variant: 'secondary',
              onClick: () => navigate(`/${companyId}/esrs`),
            }}
          />
        </Box>
      ) : (
        <Table width={'100%'}>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Fragment key={row.id}>
                  <Tr>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          padding={0}
                          borderTop={'1px solid'}
                          borderColor={'border.decorative'}
                          key={cell.id}
                        >
                          <Box>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Box>
                        </Td>
                      );
                    })}
                  </Tr>

                  <>
                    <Tr
                      background={'bg.unknown.muted'}
                      key={'header'}
                      display={row.getIsExpanded() ? 'flex' : 'none'}
                    >
                      <Td
                        pt={'12px'}
                        pb={'12px'}
                        pr={'0px'}
                        pl={'44px'}
                        width={'225px'}
                        borderBottom={'none'}
                      >
                        <HStack width={'100%'} fontSize={'14px'} justifyContent={'space-between'}>
                          <Typography fontWeight={'500'}>Business units</Typography>
                        </HStack>
                      </Td>
                      <Td pt={'12px'} pb={'12px'} pl={'0px'} width={'287px'} borderBottom={'none'}>
                        <HStack width={'100%'} fontSize={'14px'} justifyContent={'space-between'}>
                          <Typography fontWeight={'500'}>API key</Typography>
                        </HStack>
                      </Td>
                      <Td pt={'12px'} pb={'12px'} width={'144px'} borderBottom={'none'}>
                        <HStack width={'100%'} fontSize={'14px'} justifyContent={'space-between'}>
                          <Typography fontWeight={'500'}>Data</Typography>
                        </HStack>
                      </Td>
                      <Td pt={'12px'} pb={'12px'} width={'144px'} borderBottom={'none'}>
                        <HStack width={'100%'} fontSize={'14px'} justifyContent={'space-between'}>
                          <Typography fontWeight={'500'}>Status</Typography>
                        </HStack>
                      </Td>
                      <Td pt={'12px'} pb={'12px'} borderBottom={'none'} width={'48px'}>
                        <Typography fontWeight={'500'}></Typography>
                      </Td>
                    </Tr>
                    {row.original.subRows.map((subRow) => (
                      <MoreScopeBusinessUnitsRow
                        subRow={subRow}
                        key={subRow?.id}
                        assessmentYears={assessmentYears}
                        display={row.getIsExpanded() ? 'flex' : 'none'}
                        userId={user?.id}
                      />
                    ))}
                  </>
                </Fragment>
              );
            })}
          </Tbody>
        </Table>
      )}
    </VStack>
  );
};
