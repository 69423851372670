import { HStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { LinkAnchor } from 'Molecules/LinkAnchor';
import { DownloadIcon, UploadIcon } from 'Tokens/Icons/Function';
import { getMetricRefNumber } from '../../Metrics.hooks';
import { MetricsTableData } from '../..';
import { useToast } from 'utils/hooks';
import { useExportEsrsMetricTable } from '../../MetricsExcel';
import { Menu } from 'Molecules/Menu';
import { FrequencyEnums } from 'containers/Esrs/pieces';

export const MetricTableHeader = ({
  metric,
  setSelectedExcelMetric,
  onImportOpen,
  drRef,
  companyName,
  companyAssessmentId,
  companyLevelReportingUnitId,
}: {
  metric: MetricsTableData;
  setSelectedExcelMetric: (value: React.SetStateAction<MetricsTableData | undefined>) => void;
  onImportOpen: () => void;
  drRef: string;
  companyName: string;
  companyAssessmentId: string;
  companyLevelReportingUnitId: string;
}) => {
  const toast = useToast();
  const exportMetrics = useExportEsrsMetricTable();

  const isTableMetricYearly = metric?.metric.materialMetrics.every(
    (m) => m?.frequency === FrequencyEnums.yearly
  );
  const isTableMetricQuarterly = metric?.metric.materialMetrics.every(
    (m) => m?.frequency === FrequencyEnums.quarterly
  );

  return (
    <HStack justifyContent="space-between" w="100%">
      <LinkAnchor
        hideIcon
        id={metric.metric.reference}
        text={`${metric.metric.title}`}
        reference={getMetricRefNumber(metric.metric)}
      />

      <HStack spacing="4px">
        <Button
          variant="ghost"
          leftIcon={<UploadIcon />}
          onClick={() => {
            onImportOpen();
            setSelectedExcelMetric(metric);
          }}
        >
          Import
        </Button>
        <Menu
          size="md"
          sections={[
            {
              actions: [
                {
                  id: 'download',
                  title: 'Download Excel file',
                  variant: 'ghost',
                  onClick: () => {
                    toast({
                      text: 'Downloading file...',
                      closable: true,
                      duration: null,
                    });
                    if (metric) {
                      exportMetrics(
                        [metric],
                        drRef ?? '',
                        companyName ?? 'Company',
                        isTableMetricQuarterly,
                        isTableMetricYearly,
                        companyAssessmentId,
                        companyLevelReportingUnitId
                      );
                    }
                  },
                  leftElement: <DownloadIcon />,
                },
              ],
            },
          ]}
        />
      </HStack>
    </HStack>
  );
};
