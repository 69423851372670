import { portfolioOwnerContext } from 'containers/Portfolios';
import {
  AddPaiPortfolioReportMutationVariables_,
  GetPaiPortfolioReportDocument_,
  PaiCompanyResultsQuery_,
  PaiPortfolioReport,
  PaiReportIndicator_Constraint_,
  PaiReportIndicator_Update_Column_,
  QuarterEnum_Enum_,
  useAddPaiPortfolioReportMutation,
  useDeletePaiReportIndicatorMutation,
  useGetPaiPortfolioReportQuery,
  usePaiCompanyResultsQuery,
} from 'models';
import { useParams } from 'react-router-dom';
import { IndicatorConfiguration } from './PaiIndicatorsModal';
import usePortfolios from 'containers/Portfolios/Portfolios.hooks';
import { getInvestmentValue } from 'utils/scores/portfolio';
import { formatQuarterYear } from 'utils/date';

export enum ReportPeriods {
  'q1' = 'q1',
  'q2' = 'q2',
  'q3' = 'q3',
  'q4' = 'q4',
  'year' = 'year',
}

export const useUpsertPortfolioPaiReport = (year: number, quarter: QuarterEnum_Enum_) => {
  const { portfolioId } = useParams();
  const { data } = useGetPaiPortfolioReportQuery({
    variables: {
      portfolioId,
      year,
      quarter: quarter,
    },
    skip: !portfolioId,
    context: portfolioOwnerContext,
  });

  const portfolioReport = data?.report?.[0];

  const [addPaiPortfolioReport] = useAddPaiPortfolioReportMutation();
  const [deletePaiReportIndicator] = useDeletePaiReportIndicatorMutation();

  const upsertPortfolioPaiReport = async (
    report: AddPaiPortfolioReportMutationVariables_['input'],
    indicators: (IndicatorConfiguration & { isNew?: boolean })[]
  ) => {
    const toDelete: string[] =
      portfolioReport?.selectedIndicators
        .filter(
          (indicator) =>
            !indicators.find(
              (ind) => ind.paiTableIndicatorReference === indicator.indicator.reference
            )
        )
        .map((i) => i.indicator.reference) ?? [];

    return deletePaiReportIndicator({
      variables: {
        paiPortfolioReportId: report?.id ? [report.id] : [],
        paiTableIndicatorReference: toDelete,
      },
      context: portfolioOwnerContext,
    }).then(() =>
      addPaiPortfolioReport({
        variables: {
          input: {
            id: report?.id,
            portfolioId: report.portfolioId,
            dueDate: report?.dueDate,
            contactPersonId: report?.contactPersonId,
            reportingPeriod: report?.reportingPeriod ?? new Date(),
            selectedIndicators: {
              data: indicators.map((indicator) => ({
                paiTableIndicatorReference: indicator.paiTableIndicatorReference,
                isNew: indicator?.isNew,
                periods: indicator.periods,
                isForAllCompanies: indicator.isForAllCompanies,
              })),
              on_conflict: {
                constraint:
                  PaiReportIndicator_Constraint_.PaiReportIndicatorPaiPortfolioReportIdPaiTableIndicatorRefere_,
                update_columns: [PaiReportIndicator_Update_Column_.PaiTableIndicatorReference_],
              },
            },
          },
        },
        context: portfolioOwnerContext,
        refetchQueries: [GetPaiPortfolioReportDocument_],
      })
    );
  };

  return upsertPortfolioPaiReport;
};

export type PaiResults = Array<{
  company: {
    id: any;
    name: string;
  };
  allocation: number;
  marketValue?: number;
  answers: {
    [key: string]: string | number | null;
  };
  notes: {
    [key: string]: string | null;
  };
}>;

const REPORT_PERIOD_TO_CORRECT_REPORT_KEY = {
  [ReportPeriods.year]: 'paiYearReport',
  [ReportPeriods.q1]: 'paiQ1Report',
  [ReportPeriods.q2]: 'paiQ2Report',
  [ReportPeriods.q3]: 'paiQ3Report',
  [ReportPeriods.q4]: 'paiQ4Report',
};
export const usePaiReportAnswers = (
  portfolioId: string,
  reportingYear: number,
  reportPeriod: ReportPeriods
) => {
  const { data, ...rest } = usePaiCompanyResultsQuery({
    variables: {
      portfolioId,
      year: reportingYear,
    },
    skip: !portfolioId,
    context: portfolioOwnerContext,
    fetchPolicy: 'cache-and-network',
  });

  const companies = data?.companies.map((c) => {
    const valueOfInvestment = getInvestmentValue(c.valueOfInvestments, reportPeriod);
    const correctReport = REPORT_PERIOD_TO_CORRECT_REPORT_KEY?.[
      reportPeriod
    ] as keyof PaiCompanyResultsQuery_['companies'][0];

    const selectedIndicators = data?.indicators.filter(
      (indicator) =>
        indicator.isForAllCompanies ||
        indicator.paiReportIndicatorPortfolioCompanies.find(
          (selectedCompany) => selectedCompany.portfolioCompany?.companyId === c.company?.id
        )
    );

    const requiredInvesteeMetrics = selectedIndicators
      .flatMap((indicator) => indicator.indicator.investorMetrics)
      .flatMap((investorMetric) => investorMetric.investorMetric.investeeMetrics)
      .map((investeeMetric) => investeeMetric.investeeMetricReference);

    const report = c?.[
      correctReport
    ] as unknown as PaiCompanyResultsQuery_['companies'][0]['paiYearReport'];

    return {
      company: c.company ?? { id: '', name: '' },
      allocation: valueOfInvestment ?? 0,
      marketValue: c.marketValue ?? 0,
      answers:
        report?.answers
          .filter((answer) =>
            requiredInvesteeMetrics.includes(
              answer?.metric?.investorMetrics?.[0]?.investeeMetric?.reference
            )
          )
          .reduce(
            (acc, answer) => {
              acc[answer?.metric?.investorMetrics?.[0]?.investeeMetric?.reference ?? ''] =
                answer.data ?? null;
              return acc;
            },
            {} as PaiResults[0]['answers']
          ) ?? {},
      notes:
        report?.answers
          .filter((answer) =>
            requiredInvesteeMetrics.includes(
              answer?.metric?.investorMetrics?.[0]?.investeeMetric?.reference
            )
          )
          .reduce(
            (acc, answer) => {
              acc[answer?.metric?.investorMetrics?.[0]?.investeeMetric?.reference ?? ''] =
                answer?.noteHistory?.notes?.[0]?.body ?? null;
              return acc;
            },
            {} as PaiResults[0]['notes']
          ) ?? {},
    };
  });

  const results = companies ?? undefined;

  return {
    results,
    ...rest,
  };
};

export const NO_FILTER = () => true;
export const ALL_FRAMEWORKS = 'All frameworks';

export function getReportFilter(
  searchTerm: string,
  selectedFramework: string,
  reportPeriod: ReportPeriods,
  selectedCompanies: { value: string; label: string }[]
) {
  const isYear = reportPeriod === ReportPeriods.year;
  const lowerCaseSearchTerm = searchTerm.toLocaleLowerCase().trim();

  return (selectedIndicator: PaiPortfolioReport['selectedIndicators'][number]) => {
    const hasValidPeriod = isYear
      ? !!selectedIndicator.periods?.year
      : !!selectedIndicator.periods?.q1;
    const hasMatchingSearchTerm = selectedIndicator.indicator.title
      .toLocaleLowerCase()
      .includes(lowerCaseSearchTerm);
    const hasMatchingFramework =
      selectedFramework === ALL_FRAMEWORKS ||
      selectedIndicator.indicator.category.table.title === selectedFramework;

    // Check for companies
    const indicatorCompaniesIds = selectedIndicator.paiReportIndicatorPortfolioCompanies.map(
      (c) => c.portfolioCompany?.company?.id
    );
    const hasSelectedCompanies =
      selectedIndicator.isForAllCompanies ||
      selectedCompanies.some((selectedCompany) =>
        indicatorCompaniesIds.includes(selectedCompany.value)
      );

    // "All companies" selected
    if (selectedCompanies.length === 0) {
      if (selectedFramework === ALL_FRAMEWORKS && lowerCaseSearchTerm.length === 0) {
        // No framework or search filter, just return period filter
        return hasValidPeriod;
      } else {
        // Apply framework and search filters alongside period filter
        return hasValidPeriod && hasMatchingSearchTerm && hasMatchingFramework;
      }
    } else {
      // Specific companies selected, apply all filters
      return (
        hasValidPeriod && hasMatchingSearchTerm && hasMatchingFramework && hasSelectedCompanies
      );
    }
  };
}

export function getResultsFilter(selectedCompany: { value: string; label: string }[]) {
  if (!selectedCompany.length) return NO_FILTER;
  return (result: PaiResults[number]) => selectedCompany.find((c) => c.value === result.company.id);
}

export const usePaiPortfolioDetails = ({
  portfolioId,
  reportingYear,
  reportPeriod,
}: {
  reportingYear: string;
  portfolioId: string;
  reportPeriod: ReportPeriods;
}) => {
  const { getPortfolio, loading } = usePortfolios();
  const [quarter, year] = reportingYear?.split('-') ?? ['', new Date().getFullYear()];

  const portfolio = getPortfolio(portfolioId ?? '', reportingYear);

  const portfolioCompaniesInYear = portfolio?.portfolioCompanies?.filter(
    (pc) => formatQuarterYear(pc.quarter, pc.year) === reportingYear
  );

  console.log({ portfolioCompaniesInYear });

  const { data: reportPai } = useGetPaiPortfolioReportQuery({
    variables: {
      portfolioId,
      year: Number(year),
      quarter: quarter as QuarterEnum_Enum_,
    },
    skip: !portfolioId,
    context: portfolioOwnerContext,
  });

  const { results } = usePaiReportAnswers(portfolioId ?? '', Number(year), reportPeriod);
  const paiReport = reportPai?.report?.[0];

  const indicatorsForPeriod = paiReport?.selectedIndicators.filter((ind) => {
    if (reportPeriod === ReportPeriods.year) {
      return ind.periods?.year;
    }
    return ind.periods?.q1;
  });

  const perIndicator =
    indicatorsForPeriod?.map((ind) => {
      const companiesThatNeedToReport = ind.isForAllCompanies
        ? (portfolioCompaniesInYear?.map((x) => x.company?.id ?? '') ?? [])
        : (ind.paiReportIndicatorPortfolioCompanies.map(
            (x) => x?.portfolioCompany?.company?.id ?? ''
          ) ?? []);
      console.log({
        all: portfolioCompaniesInYear,
        notAll: ind.paiReportIndicatorPortfolioCompanies,
      });
      const investeeMetricsToAnswer =
        ind.indicator?.investorMetrics
          ?.map((investorMetric) =>
            investorMetric.investorMetric.investeeMetrics.map((x) => x.metric.reference)
          )
          .flat() ?? [];

      const answeredCompanies = companiesThatNeedToReport?.filter((compId) => {
        const hasAnsweredAll =
          investeeMetricsToAnswer.every((metricRef) => {
            const answer = results?.find((result) => result.company.id === compId)?.answers[
              metricRef
            ];
            return answer !== null && answer !== undefined;
          }) ?? [];
        return hasAnsweredAll;
      });
      return (answeredCompanies?.length ?? 0) / (companiesThatNeedToReport?.length ?? 1);
    }) ?? [];
  const progress = perIndicator?.reduce((acc, curr) => acc + curr, 0) / (perIndicator?.length ?? 1);

  console.log({ progress });

  const responsiblePerson = paiReport?.contactPerson;

  const dueDate = (() => {
    switch (reportPeriod) {
      case ReportPeriods.year:
        return paiReport?.dueDates?.year;
      case ReportPeriods.q1:
        return paiReport?.dueDates?.Q1;
      case ReportPeriods.q2:
        return paiReport?.dueDates?.Q2;
      case ReportPeriods.q3:
        return paiReport?.dueDates?.Q3;
      case ReportPeriods.q4:
        return paiReport?.dueDates?.Q4;
    }
  })();

  const numberOfIndicators = indicatorsForPeriod?.length ?? 0;

  const perCompany: { [companyId: string]: number } = {};
  portfolioCompaniesInYear?.forEach((pc) => {
    const hasToReportOn = indicatorsForPeriod?.filter((ind) => {
      if (ind.isForAllCompanies) return true;
      return ind.paiReportIndicatorPortfolioCompanies.some(
        (x) => x?.portfolioCompany?.company?.id === pc.company?.id
      );
    });
    const hasReportedOn = hasToReportOn?.filter((ind) => {
      const investeeMetricsToAnswer =
        ind.indicator?.investorMetrics
          ?.map((investorMetric) =>
            investorMetric.investorMetric.investeeMetrics.map((x) => x.metric.reference)
          )
          .flat() ?? [];
      return investeeMetricsToAnswer.every((metricRef) => {
        const answer = results?.find((result) => result.company.id === pc.company?.id)?.answers[
          metricRef
        ];
        return answer !== null && answer !== undefined && answer !== '';
      });
    });
    perCompany[pc.company?.id] = (hasReportedOn?.length ?? 0) / (hasToReportOn?.length ?? 1);
  });

  const progressPerCompany = perCompany;

  return {
    progress,
    responsiblePerson,
    dueDate,
    numberOfIndicators,
    loading,
    progressPerCompany,
  };
};

const PAI_CURRENCY_PLACEHOLDER = 'currency';

export const mapUnitToCompanyCurrencyPAI = (
  unit: string | null | undefined = '',
  companyCurrency = ''
) => {
  if (unit?.includes(PAI_CURRENCY_PLACEHOLDER)) {
    return unit.replace(PAI_CURRENCY_PLACEHOLDER, companyCurrency);
  } else return unit;
};
