import { WidthType, TableRow, TableCell, VerticalAlign, Paragraph, Table, TextRun } from 'docx';
import { QuestionType_Enum_ } from 'models';
import {
  AggregatedMetricsExtraData,
  AggregatedMetricsTableData,
  MDRM_QUESTIONS,
  MdrmTypeEnum,
} from '../..';
import { EsrsReportMetric, MetricInfoKey } from '../Report.types';
import { tableBorders, TextStyle } from './reportThemes';
import { TableData } from 'Molecules/NestedTable';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';
import { ReportMdrmMetricAnswers } from '../MetricAnswerCalculation';

export const addEmptySpace = (spacing: number): Paragraph => {
  return new Paragraph({
    text: '',
    spacing: {
      after: spacing,
    },
  });
};

export const getCustomRowTitle = (row: AggregatedMetricsExtraData) => {
  return row.tagName ? row.tagName : (row.metric?.shortTitle ?? row.metric?.title);
};

export const getRowTitle = (
  row: AggregatedMetricsExtraData,
  parentRow: AggregatedMetricsTableData,
  companyCurrency: string
) => {
  const metricTitle = row.metric?.shortTitle ?? row.metric?.title;
  const isChildOfParentWithTags =
    parentRow.metric?.materialMetrics[0]?.materialMetricTags?.length !== 0 &&
    parentRow.metric?.childrenMetrics.find(
      (metric) => metric?.childMetric?.reference === row.metric?.reference
    );
  const unitOfMeasurement = row.metric.unitOfMeasurement
    ? ` (${mapUnitToCompanyCurrency(row.metric.unitOfMeasurement, companyCurrency)})`
    : '';

  return isChildOfParentWithTags
    ? `${metricTitle} (${row.tagName})${unitOfMeasurement}`
    : row.tagName
      ? `${row.tagName}${unitOfMeasurement}`
      : `${metricTitle}${unitOfMeasurement}`;
};

export const getTableRowText = (row: TableData<AggregatedMetricsExtraData>) => {
  const percentage = row.percentage;
  const result = row.result?.Year ?? 0;
  const isResultNaN = isNaN(result);
  const parsedResult = isResultNaN ? 0 : parseFloat(result.toFixed(2));
  const stringifiedResult = String(parsedResult) ?? '--';

  return percentage ? stringifiedResult + ` (${percentage}%)` : stringifiedResult;
};

export const findRowByCountryAndGender = (row: EsrsReportMetric['tableData'], country: string) => {
  const filterRowByCountry = row.subRows?.filter((tag) =>
    tag?.tagName?.split('-').some((row) => String(row).trim() === country)
  );
  return filterRowByCountry?.reduce(
    (accumulator, val) =>
      accumulator + (val && val?.result && val.result.Year ? val.result.Year : 0),
    0
  );
};

export const findRowByTagName = (row: AggregatedMetricsTableData | undefined, tagValue: string) => {
  if (!row) return '';

  const rowIterator = row.subRows ?? [];

  return rowIterator
    .filter((subRow) => subRow.subRows)
    .flatMap((subRow) => subRow.subRows)
    .filter((data) => data?.tagName === tagValue)
    .reduce((acc, val) => acc + (val && val?.result && val.result.Year ? val.result.Year : 0), 0);
};

const createCustomNestedDataPointsRows = (
  metricResults: AggregatedMetricsTableData,
  columns: { header: string; key: MetricInfoKey }[],
  companyCurrency: string
) => {
  const subRows = metricResults.subRows;

  if (!subRows?.length) return [];

  const nestedRows: TableRow[] =
    subRows?.flatMap((row) => {
      return [
        new TableRow({
          children: columns.map(
            (column) =>
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  column.key === 'title'
                    ? new Paragraph({
                        text: getCustomRowTitle(row),
                        style: TextStyle.body,
                      })
                    : new Paragraph({
                        text: getTableRowText(row),
                        style: TextStyle.body,
                      }),
                ],
              })
          ),
        }),
        ...(createCustomNestedDataPointsRows(row, columns, companyCurrency) ?? []),
      ];
    }) ?? [];

  return nestedRows.flat();
};

const createCustomDataPointRows = (
  metric: EsrsReportMetric['tableData'],
  columns: { header: string; key: MetricInfoKey }[],
  companyCurrency: string,
  hideTotal?: boolean
) => {
  const metricInfo = metric.metric;

  const displayedRows = [
    ...createCustomNestedDataPointsRows(metric, columns, companyCurrency),
    ...(hideTotal
      ? []
      : [
          new TableRow({
            children: columns.map(
              (column) =>
                new TableCell({
                  verticalAlign: VerticalAlign.TOP,
                  children: [
                    new Paragraph({
                      text:
                        column.key === 'title'
                          ? (metricInfo.shortTitle ?? metricInfo.title)
                          : column.key === 'result'
                            ? getTableRowText(metric)
                            : (String(metricInfo?.[column.key]) ?? ''),
                      style: TextStyle.body,
                    }),
                  ],
                })
            ),
          }),
        ]),
  ];
  return displayedRows.flat();
};

export const createCustomMetricTable = (
  metric: EsrsReportMetric['tableData'],
  columns: { header: string; key: MetricInfoKey }[],
  companyCurrency: string,
  hideTotal?: boolean
) => {
  const quantitativeMetricTable = new Table({
    columnWidths: [5000, 5000],
    margins: {
      top: 60,
      bottom: 60,
      right: 60,
      left: 60,
    },
    width: {
      size: '100%',
      type: WidthType.PERCENTAGE,
    },
    borders: tableBorders,
    rows: [
      new TableRow({
        tableHeader: true,
        children: columns.map(
          (column) =>
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: `${100 / (columns?.length ?? 1)}%`,
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  text: column.header,
                  style: TextStyle.tableTitle,
                }),
              ],
            })
        ),
      }),
      ...createCustomDataPointRows(metric, columns, companyCurrency, hideTotal),
    ].flat(),
  });

  if (metric.metric.metricType !== QuestionType_Enum_.Decimal_) return;

  return quantitativeMetricTable;
};

export const createMdrm = (metric: EsrsReportMetric) => {
  const isNarrativeMetric = metric.metric.metric.metricType !== QuestionType_Enum_.Decimal_;

  if (isNarrativeMetric) return [];

  const metricMdrmDataPoints = metric.mdrmAnswers;

  const mdrmAnswersArray = Object.values(metricMdrmDataPoints.answers);

  const isNotDisclosed = !metric.isMdrmDisclosed;
  const noMdrmAnswered = mdrmAnswersArray.every((answer) => !answer || answer.value === '');
  if (noMdrmAnswered || isNotDisclosed) return [];

  return [createMetricMdrmAnswers(metricMdrmDataPoints), addEmptySpace(100)].flat();
};

export const createMetricMdrmAnswers = (mdrmMetricAnswers: ReportMdrmMetricAnswers) => {
  const isEntitySpecific = mdrmMetricAnswers.metric.additionalTypes.some((type) => {
    return type.additionalType.reference === 'Entity-specific';
  });

  const mappedMdrms = MDRM_QUESTIONS.filter((q) => {
    if (q.id === MdrmTypeEnum.entitySpecific) {
      return isEntitySpecific;
    }
    return true;
  })
    .map((q) => {
      return {
        id: q.id,
        question: q.question,
        answer: mdrmMetricAnswers.answers[q.id],
      };
    })
    // Remove opted out MDR-Ms
    .filter((q) => !q.answer?.hasOptedOut);

  const displayedAnswers = mappedMdrms.flatMap((q) => {
    const answer = mdrmMetricAnswers.answers?.[q.id];

    if (!!answer?.value) {
      return [
        new Paragraph({
          text: answer?.value ?? '',
          style: TextStyle.body,
          spacing: {
            after: 100,
          },
        }),
      ];
    }

    return [];
  });

  return displayedAnswers.flat();
};

export const findMetricRow = (
  metric: {
    reference: string;
    title: string;
  },
  rows: EsrsReportMetric['tableData'][]
): EsrsReportMetric['tableData'] | undefined => {
  for (const row of rows) {
    if (row.metric.reference === metric.reference) return row;
    if (row.subRows?.length) {
      const foundSubRow = findMetricRow(metric, row.subRows);
      if (foundSubRow) return foundSubRow;
    }
  }
  return undefined;
};

export const createGuidancePage = () => {
  return [
    new Paragraph({
      text: 'ESRS Report Guidance',
      style: TextStyle.title,
      spacing: {
        after: 400,
      },
    }),
    new Paragraph({
      text: 'Before submitting the ESRS report, please ensure the following points are addressed:',
      style: TextStyle.body,
      spacing: {
        after: 100,
      },
    }),
    new Paragraph({
      text: 'The following tables are mandatory under ESRS and must remain unchanged as long as the data points are considered material:',
      style: TextStyle.body,
      bullet: {
        level: 0,
      },
    }),
    new Paragraph({
      text: 'Gross Scopes 1, 2 and 3 (E1-6), but note that Scope 3 categories not considered significant can be removed from the table',
      style: TextStyle.body,
      bullet: {
        level: 1,
      },
    }),
    new Paragraph({
      text: 'Number of employees broken down by gender (S1-6)',
      style: TextStyle.body,
      bullet: {
        level: 1,
      },
    }),
    new Paragraph({
      text: 'Number of employees broken down by country (S1-6),',
      style: TextStyle.body,
      bullet: {
        level: 1,
      },
    }),
    new Paragraph({
      text: 'Total number of employees by head count or FTEs, broken down by contract types and gender (S1-6),',
      style: TextStyle.body,
      bullet: {
        level: 1,
      },
    }),
    new Paragraph({
      text: 'Total number of employees by head count or FTEs, broken down by contract types and country (S1-6) and ',
      style: TextStyle.body,
      bullet: {
        level: 1,
      },
    }),
    new Paragraph({
      text: 'Collective bargaining coverage and social dialogue (S1-8).',
      style: TextStyle.body,
      bullet: {
        level: 1,
      },
    }),
    new Paragraph({
      text: 'The table mapping with data points derived from other legislations as required by IRO-2 paragraph 56 is found within the Appendix. The table already indicates which data points are Not material based on your materiality assessment, but for the remaining data points you must indicate their location in the sustainability statement.',
      style: TextStyle.body,
      bullet: {
        level: 0,
      },
    }),
    new Paragraph({
      children: [
        new TextRun({ text: 'Ensure that ' }),
        new TextRun({ text: 'page numbers are included in the table of contents ', bold: true }),
        new TextRun({ text: 'under ' }),
        new TextRun({ text: 'IRO-2, ', bold: true }),
        new TextRun({ text: 'as required by IRO-2 paragraph 56.' }),
      ],
      style: TextStyle.body,
      bullet: {
        level: 0,
      },
    }),
    new Paragraph({
      children: [
        new TextRun({ text: 'A reminder that the ' }),
        new TextRun({ text: 'Statement of Due Diligence (GOV-4) ', bold: true }),
        new TextRun({
          text: 'should include references to the sustainability statement, with a page/location reference for each process step.',
        }),
      ],
      style: TextStyle.body,
      bullet: {
        level: 0,
      },
    }),
    new Paragraph({
      children: [
        new TextRun({ text: 'The ' }),
        new TextRun({ text: 'EU Taxonomy statement ', bold: true }),
        new TextRun({
          text: 'should be placed at the ',
        }),
        new TextRun({
          text: 'beginning of the Environmental Information section.',
          bold: true,
        }),
      ],
      style: TextStyle.body,
      bullet: {
        level: 0,
      },
      spacing: {
        after: 400,
      },
    }),
    new Paragraph({
      text: 'Order and structure of the sustainability statement',
      style: TextStyle.body,
      spacing: {
        after: 100,
      },
    }),
    new Paragraph({
      text: 'The only requirement related to structure is that the statement should be structured into General information (ESRS 2), Environmental information (EU Taxonomy + E1-E5), Social information (S1-S4) and Governance information (G1), as given by ESRS 1 Appendix D. Otherwise, undertakings are allowed to re-order the information as they see fit. ',
      style: TextStyle.body,
      spacing: {
        after: 100,
      },
    }),
    new Paragraph({
      text: 'Formatting of titles and tables:',
      style: TextStyle.body,
      spacing: {
        after: 100,
      },
    }),
    new Paragraph({
      text: 'Some titles/headers are outputted within the different DR to help create an overview. Please note that these can be changed/removed as you see fit. ',
      style: TextStyle.body,
      bullet: {
        level: 0,
      },
    }),
    new Paragraph({
      text: 'Tables: Except for the mandatory tables mentioned above, the tables can be formatted and restructured as you may please as long as no important information and context is lost for the reader. ',
      style: TextStyle.body,
      bullet: {
        level: 0,
      },
    }),
  ];
};
