import { GetTargetsByStandardAndAssessmentQuery_ } from 'models';
import { getCustomTargetsData } from '../../DocGenerator/CustomTables/E1-6-targets';
import { EsrsDRReportMetric } from '../../Report.types';
import { ColumnDefResolved } from '@tanstack/react-table';
import { Typography } from 'Tokens';
import { NestedTable } from 'Molecules/NestedTable';
import { Td, Tfoot, Tr } from '@chakra-ui/react';

type TableData = {
  annual: string;
  title: string;
  answer: string;
  [key: number | string]: string | [] | undefined;
  subRows?: [];
};

export const E1_6TargetsTable = ({
  targets,
  metrics,
  year,
}: {
  targets: GetTargetsByStandardAndAssessmentQuery_['targets'] | undefined;
  metrics: EsrsDRReportMetric['tableData'][];
  year: number;
}) => {
  const {
    dataRows,
    milestoneYears,
    baselineYears,
    availableMilestoneYears,
    finalTargetYears,
    allColumnsCount,
  } = getCustomTargetsData(targets, metrics);

  const columns: ColumnDefResolved<TableData>[] = [
    {
      header: '',
      columns: [
        {
          header: '',
          accessorKey: 'title',
          cell: ({ row }) => <Typography variant="body">{row.original.title}</Typography>,
        },
      ],
    },
    {
      header: 'Retrospective',
      columns: [
        ...baselineYears.map((year) => ({
          header: `${year} (base year)`,
          accessorKey: `baseYear_${year}`,
          cell: ({ row }) => (
            <Typography variant="body">{row.original?.[`baseYear_${year}`]}</Typography>
          ),
        })),
        {
          header: `${year - 1}`,
          accessorKey: 'previousYear',
          cell: () => <></>,
        },
        {
          header: `${year}`,
          accessorKey: 'year',
          cell: ({ row }) => <Typography variant="body">{row.original.answer}</Typography>,
        },
        {
          header: `% ${year}/${year - 1}`,
          accessorKey: 'yearRatio',
          cell: () => <></>,
        },
      ],
    },
    {
      header: 'Milestones and target years',
      columns: [
        ...milestoneYears.map((year) => ({
          header: !availableMilestoneYears.length && year === 2050 ? `(${year})` : `${year}`,
          accessorKey: 'milestoneYear',
          cell: ({ row }) => <Typography variant="body">{row.original?.[year]}</Typography>,
        })),
        {
          header: `Annual% target / Base year`,
          accessorKey: 'annual',
          cell: ({ row }) => <Typography variant="body">{row.original.annual}</Typography>,
        },
      ],
    },
  ];

  const FootNote = () => (
    <Tfoot>
      <Tr>
        <Td colSpan={allColumnsCount}>
          <Typography
            variant="body"
            fontStyle="italic"
          >{`Annual % target / Base year is calculated from the final target year ${Math.max(...finalTargetYears)}`}</Typography>
        </Td>
      </Tr>
    </Tfoot>
  );

  return (
    <NestedTable<TableData>
      columns={columns}
      data={dataRows}
      withOuterBorder={true}
      borderRadius="0px"
      rowHeight="36px"
      pageSize={25}
      width="100%"
      footNote={FootNote()}
    />
  );
};
